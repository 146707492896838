import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TextFieldGroup from '../common/TextFieldGroup';
import { createRecipe } from '../../actions/recipeActions';
import { uploadRecipeImage } from '../../actions/s3Actions';
import {Helmet} from 'react-helmet';
import imageCompression from 'browser-image-compression';
import ReactPixel from 'react-facebook-pixel';

class RecipeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      description: '',
      procedure: [{ step: '' },{ step: '' },{ step: '' }],
      ingredients: [{ ingredient: ''},{ ingredient: ''},{ ingredient: ''}],
      // picture:
      //   'https://potluq-recipe-images.s3-us-west-2.amazonaws.com/images/icons8-birthday-96.png',
      pictureHeight: '',
      pictureWidth: '',
      prepTime: '',
      cookTime: '',
      yield: '',
      source: '',
      chefNotes: ' ',
      commentsEnabled: true,
      tags: [],
      tagInput: {},
      privateFlag: false,
      errors: '',
      recipeSaving: false,
      //avatar editor plugin variables
      width: 200,
      height: 200,
      border: 50,
      color: [255, 255, 255, 0.6],
      scale: 1,
      rotate: 0,
      borderRadius: 0,
      imageFileName: '',
      photoLoading: false
    };
    var randomnumber=Math.floor(Math.random()*8)
    if (randomnumber === 0) {
      this.state.picture = 'https://potluq-recipe-images.s3-us-west-2.amazonaws.com/bread-2389247_1280.png'
      this.state.placeholderName = 'sourdough bread'
      this.state.placeholderDescription = 'special bread with a flavor loved by Alaskan gold miners'
      this.state.placeholderShoutout = 'my pioneer uncle'
    } else if (randomnumber === 1 ) {
      this.state.picture = 'https://potluq-recipe-images.s3-us-west-2.amazonaws.com/chili-2389153_1280.png'
      this.state.placeholderName = `imitation frank's hot sauce`
      this.state.placeholderDescription = `hot sauce so darn good you'll think you bought it at the supermarket`
      this.state.placeholderShoutout = `frank`
    } else if (randomnumber === 2 ) {
      this.state.picture = 'https://potluq-recipe-images.s3-us-west-2.amazonaws.com/burger.png'
      this.state.placeholderName = 'gourmet burger'
      this.state.placeholderDescription = 'hamburger with some greens and a special special sauce'
      this.state.placeholderShoutout = 'thousand island'
    } else if (randomnumber === 3 ) {
      this.state.picture = 'https://potluq-recipe-images.s3-us-west-2.amazonaws.com/cheese-2389218_1280.png'
      this.state.placeholderName = 'baked brie'
      this.state.placeholderDescription = 'imagine if you made a pastry or pie but it was just cheese'
      this.state.placeholderShoutout = 'auntie Em'
    } else if (randomnumber === 4 ) {
      this.state.picture = 'https://potluq-recipe-images.s3-us-west-2.amazonaws.com/fried-chicken-2389214_1280.png'
      this.state.placeholderName = 'kentucky chicken'
      this.state.placeholderDescription = `fried chicken so good you'll think you're in kentucky`
      this.state.placeholderShoutout = 'the colonel'
    } else if (randomnumber === 5 ) {
      this.state.picture = 'https://potluq-recipe-images.s3-us-west-2.amazonaws.com/noodle-2389221_1280.png'
      this.state.placeholderName = 'beef ramen'
      this.state.placeholderDescription = 'ramen with beef and vegetables and egg and some flavorings'
      this.state.placeholderShoutout = 'the back of the noodle package'
    } else if (randomnumber === 6 ) {
      this.state.picture = 'https://potluq-recipe-images.s3-us-west-2.amazonaws.com/pizza-2389233_1280.png'
      this.state.placeholderName = 'woodfired pizza'
      this.state.placeholderDescription = 'a recipe for woodfired-style pizza that you can prepare at home'
      this.state.placeholderShoutout = 'cousin Todd'
    } else if (randomnumber === 7 ) {
      this.state.picture = 'https://potluq-recipe-images.s3-us-west-2.amazonaws.com/steak-2389254_960_720.png'
      this.state.placeholderName = 'argentina steak'
      this.state.placeholderDescription = 'how to prepare a cut of meat like they do in argentina!'
      this.state.placeholderShoutout = 'señor carlos'
    } 
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.triggerUpload = this.triggerUpload.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleCommentCheck = this.handleCommentCheck.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({recipeSaving: true});
    const { user } = this.props.auth;

    const newRecipe = {
      chef: user.name,
      avatar: user.avatar,
      name: this.state.name,
      handle: this.props.profile.myProfile.handle,
      description: this.state.description,
      procedure: this.state.procedure,
      ingredients: this.state.ingredients,
      picture: this.state.picture,
      pictureHeight: this.state.pictureHeight,
      pictureWidth: this.state.pictureWidth,
      prepTime: this.state.prepTime,
      cookTime: this.state.cookTime,
      yield: this.state.yield,
      source: this.state.source,
      chefNotes: this.state.chefNotes,
      commentsEnabled: this.state.commentsEnabled,
      privateFlag: this.state.privateFlag,
      tags: this.state.tags
    };
    ReactPixel.trackCustom('NewRecipe');

    // console.log('recipe height and width should be ' + newRecipe.pictureHeight + ' ' + newRecipe.pictureWidth);
    this.props.createRecipe(newRecipe, this.props.history);
    // console.log('new recipe commentsEnabled is ' + newRecipe.commentsEnabled)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.errors) {
      this.setState({ errors: newProps.errors, recipeSaving: false });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  handleCheck(e) {
    this.setState({
      privateFlag: e.target.checked, commentsEnabled: !e.target.checked
    });
  }
  handleCommentCheck(e) {
    this.setState({
      commentsEnabled: e.target.checked
    });
  }
  //set of functions to handle ingredients array
  handleIngredientArrayChange = idx => e => {
    const newIngredientArray = this.state.ingredients.map(
      (ingredient, sidx) => {
        if (idx !== sidx) return ingredient;
        return { ...ingredient, ingredient: e.target.value };
      }
    );
    this.setState({ ingredients: newIngredientArray });
  };
  addIngredientField = () => {
    this.setState({
      ingredients: this.state.ingredients.concat([' '])
    });
  };
  removeIngredient = idx => () => {
    this.setState({
      ingredients: this.state.ingredients.filter((s, sidx) => idx !== sidx)
    });
  };

  //copied set of functions to handle procedure array
  handleProcedureArrayChange = idx => e => {
    const newProcedureArray = this.state.procedure.map((step, sidx) => {
      if (idx !== sidx) return step;
      return { ...step, step: e.target.value };
    });
    this.setState({ procedure: newProcedureArray });
  };
  addProcedureField = () => {
    this.setState({
      procedure: this.state.procedure.concat([' '])
    });
  };
  removeStep = idx => () => {
    this.setState({
      procedure: this.state.procedure.filter((s, sidx) => idx !== sidx)
    });
  };

  //copied set of functions to handle tag array
  handleTagInputChange = idx => e => {
    const newInput = { name: e.target.value };
    this.setState({ tagInput: newInput });
  };
  addTag = () => {
    if (this.state.tagInput.name !== '') {
      this.setState({
        tags: this.state.tags.concat(this.state.tagInput)
      });
      const blankTag = { name: '' };
      this.setState({ tagInput: blankTag });
    }
  };
  removeTag = idx => () => {
    this.setState({
      tags: this.state.tags.filter((s, sidx) => idx !== sidx)
    });
  };
  addPreTag = preTag => {
    this.setState({
      tags: this.state.tags.concat({ name: preTag })
    });
  };

  getOrientation(file, callback) {
    var reader = new FileReader();
    reader.onload = function(e) {

        var view = new DataView(e.target.result);
        if (view.getUint16(0, false) !== 0xFFD8)
        {
            return callback(-2);
        }
        var length = view.byteLength, offset = 2;
        while (offset < length) 
        {
            if (view.getUint16(offset+2, false) <= 8) return callback(-1);
            var marker = view.getUint16(offset, false);
            offset += 2;
            if (marker === 0xFFE1) 
            {
                if (view.getUint32(offset += 2, false) !== 0x45786966) 
                {
                    return callback(-1);
                }
                var little = view.getUint16(offset += 6, false) === 0x4949;
                offset += view.getUint32(offset + 4, little);
                var tags = view.getUint16(offset, little);
                offset += 2;
                for (var i = 0; i < tags; i++){
                    if (view.getUint16(offset + (i * 12), little) === 0x0112)
                    {
                        return callback(view.getUint16(offset + (i * 12) + 8, little));
                    }
                }
            }
            else if ((marker & 0xFF00) !== 0xFF00){
                break;
            } else { 
                offset += view.getUint16(offset, false);
            }
        }
        return callback(-1);
    };
    reader.readAsArrayBuffer(file);
  }

  async promiseParent(e) {
    // console.log('component mounted with ' + e);
    let promise = new Promise((resolve, reject) => {
      // let smallImage = null;
      let width = 1080;
      let height = 1080;
      let scaleFactor;
      let orientationData
      let file = e.target.files[0]
      this.getOrientation(file, function(orientation) {
        // console.log('got orientation, is ' + orientation);
        orientationData = orientation;
        if (orientationData < 1 ) {
          const fileName = file.name;
          const reader = new FileReader();
          reader.readAsDataURL(file);
          let resizePromise = new Promise((resolve, reject) => {
            // if (e.target.files[0].type != gif)
            if (file.type === 'image/gif') {
              resolve(file);
            } else {
              // console.log(e.target.files[0].type);
                reader.onload = event => {
                  const img = new Image();
                  img.src = event.target.result;
                  img.onload = () => {
                    scaleFactor = width / img.width;
                    height = img.height * scaleFactor;

                    const elem = document.createElement('canvas');
                    elem.width = width;
                    elem.height = height;
                    this.setState({
                      pictureWidth: width,
                      pictureHeight: height
                    })
                    const ctx = elem.getContext('2d');
                    // img.width and img.height will contain the original dimensions
                    ctx.drawImage(img, 0, 0, width, height);

                    let innerPromise = new Promise((resolve, reject) => {
                      ctx.canvas.toBlob(function(blob) {
                        let semaphore = new File([blob], fileName, {
                          type: 'image/jpeg',
                          lastModified: Date.now()
                        });
                        resolve(semaphore);
                      });
                    });

                    innerPromise.then(semaphore => {
                      resolve(semaphore);
                    });
                  };
                };
              }
            });
            reader.onerror = error => console.log(error);
            resizePromise.then(semaphore => {
              let finalPromise = new Promise((resolve, reject) => {
                // console.log('final promise, upload called with ' + semaphore);
                const { user } = this.props.auth;
                const pictureStateTarget = this;
                this.props.uploadRecipeImage(user, semaphore, pictureStateTarget);
                this.setState({
                  picture:
                    'https://potluq-recipe-images.s3-us-west-2.amazonaws.com/images/831-2.gif',
                    photoLoading: false
                });
                // console.log('should have uploaded');
              });
              finalPromise.then(() => resolve());
            });
        } else {
          // const fileName = file.name;
          const reader = new FileReader();
          reader.readAsDataURL(file);
          let resizePromise = new Promise((resolve, reject) => {
            // if (e.target.files[0].type != gif)
            if (file.type === 'image/gif') {
              resolve(file);
            } else {
              var options = {
                maxSizeMB: 1,
                useWebWorker: true
              }
              imageCompression(file, options).then(compressedImage => {
                // console.log('image should be 1MB now')
                resolve(compressedImage)
              })

              // console.log(e.target.files[0].type);
              // reader.onload = event => {
              //   const img = new Image();
              //   img.src = event.target.result;
              //   img.onload = () => {
              //     scaleFactor = width / img.width;
              //     height = img.height * scaleFactor;
              //     const elem = document.createElement('canvas');
              //     // flip canvas dimensions if necessary based on orientation
              //     if (4 < orientationData && orientationData < 9) {
              //       elem.width = height;
              //       elem.height = width;
              //     } else {
              //       elem.width = width;
              //       elem.height = height;
              //     }
              //     // elem.width = width;
              //     // elem.height = height;
              //     this.setState({
              //       pictureWidth: elem.width,
              //       pictureHeight: elem.height
              //     })
              //     const ctx = elem.getContext('2d');
              //     // img.width and img.height will contain the original dimensions
              //     switch (orientationData) {
              //       case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
              //       case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
              //       case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
              //       case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
              //       case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
              //       case 7: ctx.transform(0, -1, -1, 0, height, width); break;
              //       case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
              //       default: break;
              //     }
              //     ctx.drawImage(img, 0, 0, elem.width, elem.height);

              //     let innerPromise = new Promise((resolve, reject) => {
              //       ctx.canvas.toBlob(function(blob) {
              //         let semaphore = new File([blob], fileName, {
              //           type: 'image/jpeg',
              //           lastModified: Date.now()
              //         });
              //         resolve(semaphore);
              //       });
              //     });

              //     innerPromise.then(semaphore => {
              //       // console.log('innerPromise resolved');
              //       smallImage = semaphore;
              //       // console.log(smallImage);
              //       resolve(smallImage);
              //     });
              //   };
              // };
            }
          });
          reader.onerror = error => console.log(error);
          resizePromise.then(semaphore => {
            let finalPromise = new Promise((resolve, reject) => {
              // console.log('final promise, upload called with ' + semaphore);
              const { user } = this.props.auth;
              const pictureStateTarget = this;
              this.props.uploadRecipeImage(user, semaphore, pictureStateTarget);
              this.setState({
                picture:
                  'https://potluq-recipe-images.s3-us-west-2.amazonaws.com/images/831-2.gif',
                  photoLoading: false
              });
              // console.log('should have uploaded');
            });
            finalPromise.then(() => resolve());
          });
        }
      }.bind(this))
    });
    promise.then(() => console.log('this one resolves last'));
  }

  triggerUpload(e) {
    this.setState({photoLoading: true})
    this.promiseParent(e);
  }

  render() {
    const { errors } = this.state;
    let helmet = (
      <Helmet>
        <title>new recipe</title>
      </Helmet>
    )
    let ingredientsInput = (
      <div>
        {this.state.ingredients.map((ingredient, idx) => (
          <div className="ingredient" key={`Ingredient ${idx + 1}`}>
            <input
              type="text"
              className="col-10"
              placeholder={`ingredient #${idx + 1}`}
              value={this.state.ingredient}
              onChange={this.handleIngredientArrayChange(idx)}
            />
            <button
              type="button"
              onClick={this.removeIngredient(idx)}
              className="btn-sm border-0 tertiary-color-bg text-light"
            >
              <i className="fas fa-times" />
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={this.addIngredientField}
          className="btn-sm border-0 tertiary-color-bg text-light rounded-circle mt-1"
        >
          <i className="fas fa-plus" />
        </button>
      </div>
    );

    let procedureInput = (
      <div>
        {this.state.procedure.map((step, idx) => (
          <div className="step" key={`Step ${idx + 1}`}>
            <input
              type="text"
              className="col-10"
              placeholder={`step #${idx + 1}`}
              value={this.state.step}
              onChange={this.handleProcedureArrayChange(idx)}
              errors={errors.procedure}
            />
            <button
              type="button"
              onClick={this.removeStep(idx)}
              className="btn-sm border-0 tertiary-color-bg text-light"
            >
              <i className="fas fa-times" />
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={this.addProcedureField}
          className="btn-sm border-0 tertiary-color-bg text-light rounded-circle mt-1"
        >
          <i className="fas fa-plus" />
        </button>
      </div>
    );
    let mainCourseTag
    if (this.state.tags.filter(e=> e.name === `main course`).length > 0) {
      mainCourseTag = (<div></div>)
    } else {
      mainCourseTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`main course`)}>
          {`main course `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let appetizerTag
    if (this.state.tags.filter(e=> e.name === `appetizer`).length > 0) {
      appetizerTag = (<div></div>)
    } else {
      appetizerTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`appetizer`)}>
          {`appetizer `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    
    let sideTag
    if (this.state.tags.filter(e=> e.name === `side dish`).length > 0) {
      sideTag = (<div></div>)
    } else {
      sideTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`side dish`)}>
          {`side dish`} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let dessertTag
    if (this.state.tags.filter(e=> e.name === `dessert`).length > 0) {
      dessertTag = (<div></div>)
    } else {
      dessertTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`dessert`)}>
          {`dessert `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let fingerTag
    if (this.state.tags.filter(e=> e.name === `finger food`).length > 0) {
      fingerTag = (<div></div>)
    } else {
      fingerTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`finger food`)}>
          {`finger food `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let breakfastTag
    if (this.state.tags.filter(e=> e.name === `breakfast`).length > 0) {
      breakfastTag = (<div></div>)
    } else {
      breakfastTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`breakfast`)}>
          {`breakfast `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let snackTag
    if (this.state.tags.filter(e=> e.name === `snack`).length > 0) {
      snackTag = (<div></div>)
    } else {
      snackTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`snack`)}>
          {`snack `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let drinkTag
    if (this.state.tags.filter(e=> e.name === `drink`).length > 0) {
      drinkTag = (<div></div>)
    } else {
      drinkTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`drink`)}>
          {`drink `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let mexicanTag
    if (this.state.tags.filter(e=> e.name === `Mexican`).length > 0) {
      mexicanTag = (<div></div>)
    } else {
      mexicanTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`Mexican`)}>
          {`Mexican `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let healthyTag
    if (this.state.tags.filter(e=> e.name === `healthy`).length > 0) {
      healthyTag = (<div></div>)
    } else {
      healthyTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`healthy`)}>
          {`healthy `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let comfortTag
    if (this.state.tags.filter(e=> e.name === `comfort food`).length > 0) {
      comfortTag = (<div></div>)
    } else {
      comfortTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`comfort food`)}>
          {`comfort food`} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let indianTag
    if (this.state.tags.filter(e=> e.name === `Indian`).length > 0) {
      indianTag = (<div></div>)
    } else {
      indianTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`Indian`)}>
          {`Indian `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let cajunTag
    if (this.state.tags.filter(e=> e.name === `Cajun`).length > 0) {
      cajunTag = (<div></div>)
    } else {
      cajunTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`Cajun`)}>
          {`Cajun `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let chineseTag
    if (this.state.tags.filter(e=> e.name === `Chinese`).length > 0) {
      chineseTag = (<div></div>)
    } else {
      chineseTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`Chinese`)}>
          {`Chinese `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let americanTag
    if (this.state.tags.filter(e=> e.name === `American`).length > 0) {
      americanTag = (<div></div>)
    } else {
      americanTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`American`)}>
          {`American `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let frenchTag
    if (this.state.tags.filter(e=> e.name === `French`).length > 0) {
      frenchTag = (<div></div>)
    } else {
      frenchTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`French`)}>
          {`French `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let japaneseTag
    if (this.state.tags.filter(e=> e.name === `Japanese`).length > 0) {
      japaneseTag = (<div></div>)
    } else {
      japaneseTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`Japanese`)}>
          {`Japanese `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let greekTag
    if (this.state.tags.filter(e=> e.name === `Greek`).length > 0) {
      greekTag = (<div></div>)
    } else {
      greekTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`Greek`)}>
          {`Greek `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let soulTag
    if (this.state.tags.filter(e=> e.name === `soul food`).length > 0) {
      soulTag = (<div></div>)
    } else {
      soulTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`soul food`)}>
          {`soul food `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let seafoodTag
    if (this.state.tags.filter(e=> e.name === `seafood`).length > 0) {
      seafoodTag = (<div></div>)
    } else {
      seafoodTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`seafood`)}>
          {`seafood `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let italianTag
    if (this.state.tags.filter(e=> e.name === `Italian`).length > 0) {
      italianTag = (<div></div>)
    } else {
      italianTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`Italian`)}>
          {`Italian `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let thaiTag
    if (this.state.tags.filter(e=> e.name === `Thai`).length > 0) {
      thaiTag = (<div></div>)
    } else {
      thaiTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`Thai`)}>
          {`Thai `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let mideastTag
    if (this.state.tags.filter(e=> e.name === `middle eastern`).length > 0) {
      mideastTag = (<div></div>)
    } else {
      mideastTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`middle eastern`)}>
          {`middle eastern `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let suggestedTags = (
      <div className="scrolling-wrapper mt-0 mb-0">
        {mainCourseTag}
        {appetizerTag}
        {dessertTag}
        {sideTag}
        {fingerTag}
        {snackTag}
        {drinkTag}
        {breakfastTag}
      </div>
    )
    let suggestedCuisineTags = (
      <div className="scrolling-wrapper mt-0 mb-0">
        {mexicanTag}
        {comfortTag}
        {healthyTag}
        {indianTag}
        {cajunTag}
        {chineseTag}
        {frenchTag}
        {americanTag}
        {japaneseTag}
        {greekTag}
        {soulTag}
        {seafoodTag}
        {italianTag}
        {thaiTag}
        {mideastTag}
      </div>
    )

    let tagsInput = (
      <div className="mt-2">
        <div className="tag" key={`Tag`}>
          <input
            type="text"
            placeholder={'add new tag'}
            value={this.state.tagInput.name}
            onChange={this.handleTagInputChange()}
            errors={errors.tagInput}
          />
          <button type="button" onClick={this.addTag} className="btn-sm border-0 tertiary-color-bg text-light mt-1">
            <i className="fas fa-plus" />
          </button>
        </div>
      </div>
    );

    let currentTags = (
      <div className="row px-3 pb-1">
        {this.state.tags.map((objectMapped, idx) => (
          <div className="tag ml-1" key={idx}>
            <span className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3">
              {`${objectMapped.name}`}
              {/* <button
                type="button"
                onClick={this.removeTag(idx)}
                className="btn-sm border-0 tertiary-color-bg text-light ml-1"
              >
                <i className="fas fa-times" />
              </button> */}
              <i 
              className="far fa-times-circle fa-2x cursorPointer tagText hoverRed removeTagButton hoverSpin" 
              onClick={this.removeTag(idx)}
              />
            </span>
          </div>
        ))}
      </div>
    );

    let photoPreview;
    if ((this.state.photoLoading)|(this.state.picture === 'https://potluq-recipe-images.s3-us-west-2.amazonaws.com/images/831-2.gif')) {
      photoPreview = (
        <div className="ml-6"> 
          <br />
          <br />
          <br />
          <i class="fas fa-sync fa-spin fa-lg ml-3"/>
          <br />
          <br />
          <br />
        </div>
      )
    } else {
      photoPreview = (
          <img src={this.state.picture} alt="Recipe" className=" recipeImage2 mb-1"/>
        )
    }
    let photoUpload = (
      // <div className="recipeImage">
        <div className="">
          <div>show us what it should look like?</div>
          {photoPreview}
          <br />
          <input type="file" accept="image/*" onChange={this.triggerUpload} />
        </div>
      )
    
    
    let privateExplaination
    if (this.state.privateFlag) {
      privateExplaination = (
        <div className="small ml-2 mt-0 mb-0">
          <p>you cannot share private recipes with others</p>
        </div>
      )
    } else {
      privateExplaination = (
        <div>
        </div>
      )
    }
    let saveButton
    if (!this.state.recipeSaving) {
      saveButton = (
        <button type="submit"  className="btn secondary-color-bg text-light mt-0">
          save
        </button>
      )
    } else {
      saveButton = (
        <i className="fas fa-sync fa-spin fa-lg"/>
      )
    }
    let commentsCheckbox
    if (!this.state.privateFlag) {
      commentsCheckbox = (
        <div className="row px-3">
          <input
            type="checkbox"
            name="commentsEnabled"
            checked={this.state.commentsEnabled}
            onChange={this.handleCommentCheck}
            
          />
          <p className="ml-2 mb-0">allow chefs to comment on this recipe?</p>

        </div>
      )
    } else {
      commentsCheckbox = (
        <div></div>
      )
    }
    let commentExplanation
    if (!this.state.commentsEnabled) {
      commentExplanation = (
        <div className="small ml-2 mt-0 mb-0">
          <p>comments will be disabled on this recipe</p>
        </div>
      )
    } else {
      commentExplanation = (
        <div></div>
      )
    }
    return (
      <div className="post-form mb-3">
        {helmet}
        <div className="card card-info">
          {/* <div className="card-header bg-info text-white">add your recipe</div> */}
          <h3 className="text-center mt-3">add your recipe</h3>
          <div className="card-body">
            <form onSubmit={this.onSubmit}>
              <div className="form-group">
                
                
                <p className="mb-1">what do you call it?</p>
                <TextFieldGroup
                  placeholder={this.state.placeholderName}
                  name="name"
                  value={this.state.name}
                  onChange={this.onChange}
                  error={errors.name}
                />
                <p className="my-1">what is it?</p>
                <TextFieldGroup
                  placeholder={this.state.placeholderDescription}
                  name="description"
                  value={this.state.description}
                  onChange={this.onChange}
                  error={errors.description}
                />
                <p className="mb-1">want to give anyone a shoutout?</p>
                <TextFieldGroup
                  placeholder={this.state.placeholderShoutout}
                  name="source"
                  value={this.state.source}
                  onChange={this.onChange}
                  error={errors.source}
                />
                {photoUpload}
                <p className="mb-1 mt-2">what is in it?</p>
                {ingredientsInput}
                <p className="mb-1">how do you make it?</p>
                {procedureInput}
                <p className="mb-1">how long to prep this recipe?</p>
                <div className="row ml-1">
                  <TextFieldGroup
                    placeholder="15"
                    name="prepTime"
                    
                    value={this.state.prepTime}
                    onChange={this.onChange}
                    error={errors.prepTime}
                  />
                  <div className="ml-2 mt-1">minutes</div>
                </div>
                <p className="mt-1 mb-1">how long to fix it once it's prepped?</p>
                <div className="row ml-1">
                  <TextFieldGroup
                    placeholder="15"
                    name="cookTime"
                    value={this.state.cookTime}
                    onChange={this.onChange}
                    error={errors.cookTime}
                  />
                  <div className="ml-2 mt-1">minutes</div>
                </div>
                <p className="mb-1">how much does it make?</p>
                <div className="row ml-1">
                  <TextFieldGroup
                    placeholder="4"
                    name="yield"
                    value={this.state.yield}
                    onChange={this.onChange}
                    error={errors.yield}
                  />
                  {/* <div className="ml-2 mt-1">servings</div> */}
                </div>
                <p className="mb-1">any notes or tips about this recipe?</p>
                <TextFieldGroup
                  placeholder="Mix some red pepper flakes in with the frosting for a surprise KICK"
                  name="chefNotes"
                  value={this.state.chefNotes}
                  onChange={this.onChange}
                  error={errors.chefNotes}
                />
                <p className="mb-0">tag your recipe so people can find it!</p>
                {currentTags}
                {suggestedTags}
                {suggestedCuisineTags}
                {tagsInput}
                <div className="row px-3 pb-0 pt-2 mt-4">
                  <input
                    type="checkbox"
                    name="privateFlag"
                    checked={this.state.privateFlag}
                    onChange={this.handleCheck}
                  />
                  <p className="ml-2 mb-0">make this recipe private?</p>
                  <br />
                  {privateExplaination}
                </div>
                <div className="row px-3 pb-0 pt-1 mt-1">
                {commentsCheckbox}
                {commentExplanation}
                </div>
                
              </div>
              <div className="d-flex justify-content-center">
              {saveButton}
              </div>
              
            </form>
          </div>
        </div>
      </div>
    );
  }
}

RecipeForm.propTypes = {
  createRecipe: PropTypes.func.isRequired,
  uploadRecipeImage: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile,
  errors: state.errors,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { createRecipe, uploadRecipeImage }
)(withRouter(RecipeForm));
