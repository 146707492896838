import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser, loginFB, authFB, loginG } from '../../actions/authActions';
import TextFieldGroup from '../common/TextFieldGroup';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import {Helmet} from 'react-helmet';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      thirdPartyCookieError: false,
      errors: {}
    };
    // // this.authFB = this.authFB.bind(this);
    // this.setAuthToken = this.setAuthToken.bind(this);
    // this.facebookResponse = this.facebookResponse.bind(this);
    this.googleResponse = this.googleResponse.bind(this);
    this.googleFailure = this.googleFailure.bind(this);
  }

  componentDidMount() {
    // console.log('window href is ' + window.location.href)
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/yourrecipes');
    }
    window.gapi.load('auth2', () => {
      this.auth2 = window.gapi.auth2.init({
        client_id: '641523247436-b7r52kdjjor49teclaiq2bm44aavucib.apps.googleusercontent.com',
        scope: 'profile email'
      })
      this.attachSignin(document.getElementById('customBtn'));
    })
  }
  
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push('/yourrecipes');
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const userData = {
      email: this.state.email,
      password: this.state.password
    };

    this.props.loginUser(userData);
  };

  facebookResponse = response =>  {
    let token = response.accessToken;
    this.props.authFB(token);
    this.props.loginFB(response);
  };
  attachSignin(element) {
    this.auth2.attachClickHandler(element, {}, this.googleResponse, this.googleFailure);
  }
  googleResponse = response => {
    // console.log('success googleResponse')
    // console.log(response)
    let token = response.uc.access_token;
    // console.log('token is ' + token)
    this.props.authFB(token);
    this.props.loginG(response);
  }
  googleFailure = response => {
    console.log(`google authentication failed`)
    console.log(response.error)
    console.log(response.details)
    if (response.error === 'idpiframe_initialization_failed') {
      console.log('cookies are disabled in this browser')
      this.setState({thirdPartyCookieError: true})
    }
  }
  
  render() {
    const { errors } = this.state;
    let helmet = (
      <Helmet>
        <title>sign in to potluq</title>
      </Helmet>
  )
    
    let facebookLoginButton = (
        <FacebookLogin
          appId={'626800344762698'}
          autoLoad={false}
          // isMobile={false}
          // disableMobileRedirect={true}
          redirectUri={window.location.href}
          fields="name,email,picture.width(720).height(720).redirect(0)"
          callback={this.facebookResponse} 
          onclick={this.componentClicked}
          render={renderProps => (
            <button onClick={renderProps.onClick} className="btn facebook-blue-bg text-light mt-4 ml-0 mr-2 col-auto">sign in with <i className="fab fa-facebook-square" /></button>
        )}/>
      )
    let googleLoginButton = (
      
        <button id="customBtn" className="btn google-green-bg text-light mt-4 mr-0 col-auto">sign in with <i className="fab fa-google" /></button>
    )
    let thirdPartyCookieWarning
    if (this.state.thirdPartyCookieError) {
      thirdPartyCookieWarning = (
        <p className="text-center small">to sign in with Google, <a href="https://developers.google.com/identity/sign-in/web/troubleshooting">enable third party cookies</a> in your browser </p>
      )
    }
   
    return (
      <div className="login">
        {helmet}
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              {/* <h1 className="display-4 text-center">sign in</h1> */}
              <p className="lead text-center">come to the potluq</p>
              <form onSubmit={this.onSubmit}>
                <TextFieldGroup
                  placeholder="Email Address"
                  name="email"
                  type="email"
                  value={this.state.email}
                  onChange={this.onChange}
                  error={errors.email}
                />
                <TextFieldGroup
                  placeholder="Password"
                  name="password"
                  type="password"
                  value={this.state.password}
                  onChange={this.onChange}
                  error={errors.password}
                />
                <input type="submit" value="sign in" className="btn secondary-color-bg text-light btn-block mt-4" />
              </form>
              <a href={`/requestpasswordreset`} className="btn tertiary-color-bg text-light btn-block mt-4" >forgot password</a>
              <div className="d-flex btn-group btn-group-justified">
                {facebookLoginButton}
                {googleLoginButton}
              </div>
              {thirdPartyCookieWarning}
              {/* <button className="btn primary-color-bg text-light btn-block mt-4" onClick={this.props.loginFB}>sign in with <i className="fab fa-facebook-square" /></button> */}
              {/* <a href={`/api/users/facebooklogin`} className="btn primary-color-bg text-light btn-block mt-4" >sign in with <i className="fab fa-facebook-square" /></a> */}
              
              <br/>
              <br/>
              <a href={`/register`} className="btn primary-color-bg text-light btn-block mt-4" >create an account</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  loginG: PropTypes.func.isRequired,
  loginFB: PropTypes.func.isRequired,
  authFB: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginUser, loginFB, authFB, loginG }
)(Login);
