import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/authActions';
import {
  getCurrentProfile,
  clearCurrentProfile,
  getProfileByHandle
} from '../../actions/profileActions';
import isEmpty from '../../validation/is-empty';
import ReactPixel from 'react-facebook-pixel';

class Navbar extends Component {
  componentDidMount() {
    this.props.getCurrentProfile();
    ReactPixel.init('145617583546629');
  }

  onLogoutClick(e) {
    // e.preventDefault();
    this.props.clearCurrentProfile();
    this.props.logoutUser();
  }

  render() {
    const { isAuthenticated, user } = this.props.auth;
    let myProfileLink = <Link className="nav-link" to={`/allRecipes`}>
    {`profile`}{' '}
      <img
        className="rounded-circle"
        src='https://potluq-profile-images.s3-us-west-2.amazonaws.com/chefAvatar.png'
        alt='profile'
        style={{ width: '25px', marginRight: '5px' }}
      />
</Link>;
    if (
      this.props.profile.myProfile &&
      !isEmpty(this.props.profile.myProfile)
    ) {
      if (this.props.profile.myProfile.handle) {
        myProfileLink = (
          <Link className="nav-link" to={`/allRecipes`}>
              {`profile`}{' '}
                <img
                  className="rounded-circle"
                  src='https://potluq-profile-images.s3-us-west-2.amazonaws.com/chefAvatar.png'
                  alt='profile'
                  style={{ width: '25px', marginRight: '5px' }}
                />
        </Link>
        );
      }
    }
    if (this.props.profile.myProfile) {
      if (this.props.profile.myProfile.handle) {
        myProfileLink = (
          <Link className="nav-link text-white" to={`/chef/${this.props.profile.myProfile.handle}`} onClick={()=>this.props.getProfileByHandle(this.props.profile.myProfile.handle)} >
              {user.name}{' '}
                <img
                  className="rounded-circle"
                  src={user.avatar}
                  alt={user.name}
                  style={{ width: '25px', marginRight: '5px' }}
                />
          </Link>
        )
      }
    } else {
      myProfileLink = (
        <Link className="nav-link text-white" to={`/allRecipes`}>
              {user.name}{' '}
                <img
                  className="rounded-circle"
                  src={user.avatar}
                  alt={user.name}
                  style={{ width: '25px', marginRight: '5px' }}
                />
        </Link>
      )
    }
    const authLinks = (
      <ul className="nav navbar-nav">
        {/* <li className="nav-item"> */}
        {myProfileLink}
        <Link
          className="nav-link"
          to="/"
          onClick={this.onLogoutClick.bind(this)}
        >
          logout
        </Link>
      </ul>
      
    );

    const guestLinks = (

      <ul className="nav navbar-nav">
          {/* <li className="nav-item"> */}
          <Link className="nav-link" to="/register" style={{color:"white"}}>
            join
          </Link>
          <Link className="nav-link" to="/login"style={{color:"white"}}>
            sign in
          </Link>

      </ul>
    );
    let booksLink = (
      <Link className="nav-link text-white" to="/mybooks">
        books
      </Link>
    )
    return (
      <nav className="navbar navbar-expand-sm navbar-dark primary-color-bg p-2 pl-3 pr-3">
        <div className="container-fluid">
          <div className="navbar-header justify-content-between text-white">
            <Link className="navbar-brand" to="/" >
              <img
                  className=""
                  src='https://potluq-profile-images.s3-us-west-2.amazonaws.com/potluq_logo_white_sm.png'
                  alt="potluq"
                  style={{ height: '25px', width: '75px', marginRight: '1px' }}
                  data-toggle="collapse" data-target="#mobile-nav.show"
                />
            </Link>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#mobile-nav"
          >
            <span className="navbar-toggler-icon" />
          </button>

          <div className="collapse navbar-collapse" 
          id="mobile-nav" 
          data-toggle="collapse"
            data-target="#mobile-nav"
          >
            <ul className="nav navbar-nav mr-auto" >
              <Link className="nav-link text-white" to="/allRecipes">
                recipes
              </Link>
              <Link className="nav-link text-white" to="/profiles" >
                chefs
              </Link>
              {isAuthenticated ? booksLink : null}
              {/* </li> */}
            </ul>
            {isAuthenticated ? authLinks : guestLinks}
          </div>
        </div>
      </nav>
    );
  }
}

Navbar.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  getProfileByHandle: PropTypes.func.isRequired,
  clearCurrentProfile: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  profile: PropTypes.object,
  myProfile: PropTypes.object,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile,
  myProfile: state.myProfile,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getCurrentProfile, logoutUser, clearCurrentProfile, getProfileByHandle }
)(Navbar);
