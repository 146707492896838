import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import setFBAuthToken from '../utils/setFBAuthToken';
import jwt_decode from 'jwt-decode';

import { GET_ERRORS, CLEAR_ERRORS, SET_CURRENT_USER } from './types';

//Register User
export const registerUser = (userData, history) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(clearErrors());
    axios
      .post('/api/users/register', userData)
      .then(res => {
        console.log('user registered as id ' + res.data._id);
        console.log('user registered as res ' + res);
        console.log('user registered as resdata ' + res.data);
        resolve(res);
      })
      .catch(err =>{
          dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
        reject(err.response.data);}
      );
  });
  // finalPromise.then(res => return(res));
};

export const updateUserInfo = (userData, history) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(clearErrors());
    axios
      .post('/api/users/update', userData)
      .then(res => {
        console.log('user ' + res.data._id);
        console.log('user name is ' + res.data.name);
        console.log('user avatar is ' + res.data.avatar);
        dispatch(setCurrentUser(res.data));
        resolve();
      })
      .catch(err =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
      );
  });
  // finalPromise.then(res => return(res));
};

//Request token be emailed to reset password
export const requestPasswordReset = (userData, history) => dispatch => {
  return new Promise((resolve, reject) => {
    axios
      .post('/api/users/requestpasswordreset', userData)
      .then(res => {
        console.log('request resolved ' + res)
        // console.log('user registered as' + res.data._id);
        resolve(res);
      })
      .catch(err =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
      );
  });
};

//Reset password for existing user with reset token
export const resetPassword = (userData, history) => dispatch => {
  return new Promise((resolve, reject) => {
    axios
      .post('/api/users/resetpassword', userData)
      .then(res => {
        // console.log('user registered as' + res.data._id);
        resolve(res);
      })
      .catch(err =>
        {dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
        resolve(err.response.data)}
      );
  });
};

//login get user token
export const loginUser = userData => dispatch => {
  axios
    .post('/api/users/login', userData)
    .then(res => {
      //save to local storage
      const { token } = res.data;
      //set token to local storage
      localStorage.setItem('jwtToken', token);
      //set token to auth header
      setAuthToken(token);
      //decode token to get user data
      const decoded = jwt_decode(token);
      //set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

//set temporary facebook token for FB login
export const authFB = accessToken => dispatch => {
  //remove the token from local storage
  // localStorage.setItem('jwtToken', 'Bearer ' + accessToken);
  //remove the auth header for future requests
  setFBAuthToken(accessToken)
  //set current user to {} which will set isAuthenticated to false
  // dispatch(setCurrentUser({}))
  
};

//login with facebook
export const loginFB = userData => dispatch => {
  // console.log('loginFB action received userData')
  axios.post('/api/users/facebooklogin', userData)
  .then(res => {
    // console.log('FBlogin action got back response')
    // console.log(res)
    // save to local storage
    const { token } = res.data;
    //set token to local storage
    localStorage.setItem('jwtToken', token);
    //set token to auth header
    setAuthToken(token);
    //decode token to get user data
    const decoded = jwt_decode(token);
    //set current user
    dispatch(setCurrentUser(decoded));
  })
  .catch(err =>
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    })
  );
}

//login with google
export const loginG = userData => dispatch => {
  // console.log('loginG action received userData')
  axios.post('/api/users/googlelogin', userData)
  .then(res => {
    // console.log('Glogin action got back response')
    // console.log(res)
    // save to local storage
    const { token } = res.data;
    //set token to local storage
    localStorage.setItem('jwtToken', token);
    //set token to auth header
    setAuthToken(token);
    //decode token to get user data
    const decoded = jwt_decode(token);
    //set current user
    dispatch(setCurrentUser(decoded));
  })
  .catch(err =>
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    })
  );
}

//set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

//log user out
export const logoutUser = () => dispatch => {
  //remove the token from local storage
  localStorage.removeItem('jwtToken');
  //remove the auth header for future requests
  setAuthToken(false);
  //set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};

//clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};
