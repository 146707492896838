import {
  GET_PROFILE,
  GET_PROFILE_BY_HANDLE,
  GET_PROFILES,
  PROFILE_LOADING,
  CLEAR_CURRENT_PROFILE,
  CLEAR_PROFILES
} from '../actions/types';

const initialState = {
  myProfile: null,
  profile: null,
  profiles: null,
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case PROFILE_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_PROFILE:
      return {
        ...state,
        myProfile: action.payload,
        loading: false
      };
    case GET_PROFILE_BY_HANDLE:
      return {
        ...state,
        profile: action.payload,
        loading: false
      };
    case GET_PROFILES:
      if (state.profiles !== null) {
        return {
          ...state,
          profiles: state.profiles.concat(action.payload),
          loading: false
        };
      } else {
        return {
          ...state,
          profiles: action.payload,
          loading: false
        };
      }
    case CLEAR_CURRENT_PROFILE:
      return {
        ...state,
        profile: null
      };
    case CLEAR_PROFILES:
      return {
        ...state,
        profiles: null
      };

    default:
      return state;
  }
}
