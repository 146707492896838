import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Helmet} from 'react-helmet';

class TOS extends Component {
//if I need to define any actions, or call anything onload, do it here

  render() {

    //if I decide to make anything on the About page dynamic - put the stuff here

    return (
        <div className="m-4 pl-2 pr-2">
          <Helmet>
            <title>Terms of service</title>
          </Helmet>
          <h3>{`Terms of service`}</h3>
          <br/>
          <h4>{`Welcome to potluq!`}</h4>
          <h5>{`Introduction`}</h5>
            <p>{`Thank you for using potluq, including the services and features we make available to you as part of the platform (collectively, the “Service”).`}</p>
            <h5>{`Our Service`}</h5>
            <p>{`The Service allows you to store, organize, share, and discover recipes. Your use of the Service is subject to these terms, which may be updated from time to time (together, this "Agreement"). Please read this Agreement carefully and make sure you understand it. If you do not understand the Agreement, or do not accept any part of it, then you may not use the Service.`}</p>
            <br/>
            <h4>{`Who may use the Service?`}</h4>
            <h5>{`Age Requirements`}</h5>
            <p>{`You must be at least 13 years old to use the Service.`}</p>
            <h5>{`Businesses`}</h5>
            <p>{`If you are using the Service on behalf of a company or organization, you represent that you have authority to act on behalf of that entity, and that such entity accepts this Agreement.`}</p>
            <br/>
            <h4>{`Your Use of the Service`}</h4>
            <h5>{`Content on the Service`}</h5>
            <p>{`The content on the Service includes recipes, graphics, photos, text (such as comments and scripts), branding (including trade names, trademarks, service marks, or logos), and other materials whether provided by you, potluq, or a third-party (collectively, "Content”).`}</p> 
            <p>{`Content is the responsibility of the person or entity that provides it to the Service. Potluq is under no obligation to host or serve Content. If you see any Content you believe does not comply with this Agreement, including by violating the law, you can report it via the report icon ( `}<i className="fas fa-exclamation-triangle fa-sm"/>{` ).`}</p>
            <h5>{`Potluq account`}</h5>
            <p>{`You can use parts of the Service, such as browsing and searching for Content, without having a potluq account. However, you do need a potluq account to use some features. With a potluq account, you may be able to like Content, follow other users, create or upload your own Content, and more. You can create a potluq account `}<Link to="/register">here.</Link></p>
            <p>{`To protect your potluq account, keep your password confidential. You should not reuse your potluq account password on other applications or websites. `}</p>
            <h5>{`Your Information`}</h5>
            <p>{`Our `}<Link to="/privacy">Privacy Policy</Link>{` explains how we treat your personal data and protect your privacy when you use the Service. `}</p>
            <h5>{`Permissions and Restrictions`}</h5>
            <p>{`You may access and use the Service as made available to you, as long as you comply with this Agreement and applicable law. You may view Content for your personal, non-commercial use. `}</p>
            <p>{`The following restrictions apply to your use of the Service. You are not allowed to:`}</p>
            <ol>
                <li>{`Access, reproduce, download, distribute, transmit, broadcast, display, sell, license, alter, modify or otherwise use any part of the Service or any Content except: (a) as expressly authorized by the Service; or (b) with prior written permission from potluq and, if applicable, the respective rights holders;`}</li>
                <li>{`Circumvent, disable, fraudulently engage with, or otherwise interfere with any part of the Service (or attempt to do any of these things), including security-related features or features that (a) prevent or restrict the copying or other use of Content or (b) limit the use of the Service or Content;`}</li>
                <li>{`Access the Service using any automated means (such as robots, botnets or scrapers) except (a) in the case of public search engines, in accordance with potluq’s robots.txt file; or (b) with potluq’s prior written permission; `}</li>
                <li>{`Collect or harvest any information that might identify a person (for example, usernames), unless permitted by that person or allowed under section (3) above;`}</li>
                <li>{`Use the Service to distribute unsolicited promotional or commercial content or other unwanted or mass solicitations;`}</li>
                <li>{`Cause or encourage any inaccurate measurements of genuine user engagement with the Service, including by paying people or providing them with incentives to increase Content's views, likes, or shares, or to increase a user’s followers, or otherwise manipulate metrics in any manner;`}</li>
                <li>{`Misuse any reporting or complaint process, including by making groundless or frivolous submissions; or`}</li>
                <li>{`Use the Service to sell advertising, sponsorships, or promotions on any page of any website or application that only contains Content from the Service or where Content from the Service is the primary basis for such sales (for example, selling ads on a webpage where potluq recipes are the main draw for users visiting the webpage).`}</li>
            </ol>
            <h5>{`Reservation`}</h5>
            <p>{`Using the Service does not give you ownership of or rights to any aspect of the Service, including user names or any other Content posted by others or potluq.`}</p>
            <h5>{`Changes to the Service`}</h5>
            <p>{`Potluq is constantly changing and improving the Service. We may also need to alter or discontinue the Service, or any part of it, in order to make performance or security improvements, change functionality and features, make changes to comply with law, or prevent illegal activities on or abuse of our systems. These changes may affect all users, some users or even an individual user. Whenever reasonably possible, we will provide notice when we discontinue or make material changes to our Service that will have an adverse impact on the use of our Service. However, you understand and agree that there will be times when we make such changes without notice, such as where we feel we need to take action to improve the security and operability of our Service, prevent abuse, or comply with legal requirements. `}</p>
            <br/>
            <h4>{`Your Content and Conduct`}</h4>
            <h5>{`Uploading Content`}</h5>
            <p>{`If you have a potluq account, you may be able to upload Content to the Service. You may use your Content to promote your business or artistic enterprise. If you choose to upload Content, you must not submit to the Service any Content that does not comply with this Agreement or the law. For example, the Content you submit must not include third-party intellectual property (such as copyrighted material) unless you have permission from that party or are otherwise legally entitled to do so.`}</p>
            <p>{`You are legally responsible for the Content you submit to the Service. `}</p>
            <h5>{`Rights you Grant`}</h5>
            <p>{`You retain ownership rights in your Content. However, we do require you to grant certain rights to potluq and other users of the Service, as described below.`}</p>
            <h5>{`License to potluq`}</h5>
            <p>{`By providing Content to the Service, you grant to potluq a worldwide, non-exclusive, royalty-free, sublicensable, and transferable license to use that Content (including to reproduce, distribute, prepare derivative works, display, and perform it) in connection with the Service and potluq’s (and its successors' and Affiliates') business, including for the purpose of promoting and redistributing part or all of the Service.`}</p>
            <h5>{`License to Other Users`}</h5>
            <p>{`You also grant each other user of the Service a worldwide, non-exclusive, royalty-free license to access your Content through the Service, and to use that Content, including to reproduce, distribute, prepare derivative works, display, and perform it, only as enabled by a feature of the Service. For clarity, this license does not grant any rights or permissions for a user to make use of your Content independent of the Service.`}</p>
            <h5>{`Duration of License`}</h5>
            <p>{`The licenses granted by you continue for a commercially reasonable period of time after you remove or delete your Content from the Service. You understand and agree, however, that potluq may retain, but not display, distribute, or perform, server copies of your Content that have been removed or deleted. `}</p>
            <h5>{`Removing Your Content`}</h5>
            <p>{`You may remove your Content from the Service at any time. You must remove your Content if you no longer have the rights required by these terms.`}</p>
            <h5>{`Removal of Content By Potluq`}</h5>
            <p>{`If we reasonably believe that any Content is in breach of this Agreement or may cause harm to potluq, our users, or third parties, we may remove or take down that Content in our discretion. `}</p>
            <p>{`If you believe your copyright has been infringed on the Service, please `}<Link className="" to="/help">notify us.</Link></p>
            <br/>
            <h4>{`Account Suspension & Termination`}</h4>
            <h5>{`Terminations by You`}</h5>
            <p>{`You may stop using the Service at any time. Delete your account from the `}<Link className="" to="/editprofileandaccount">edit profile page.</Link>{`. Private Content should be saved offline before deleting your account, as it will become inaccessible without an account.`}</p>
            <h5>{`Terminations and Suspensions by Potluq for Cause`}</h5>
            <p>{`Potluq may suspend or terminate your access, your account, or your account’s access to all or part of the Service if (a) you materially or repeatedly breach this Agreement; (b) we are required to do so to comply with a legal requirement or a court order; or (c) we believe there has been conduct that creates (or could create) liability or harm to any user, other third party, potluq or our Affiliates.`}</p>
            <h5>{`Terminations by Potluq for Service Changes`}</h5>
            <p>{`Potluq may terminate your access, or your account’s access to all or part of the Service if potluq believes, in its sole discretion, that provision of the Service to you is no longer commercially viable.`}</p>
            <h5>{`Notice for Termination or Suspension`}</h5>
            <p>{`We may notify you with the reason for termination or suspension by Potluq unless we reasonably believe that to do so: (a) would violate the law or the direction of a legal enforcement authority, or would otherwise risk legal liability for potluq or our Affiliates; (b) would compromise an investigation or the integrity or operation of the Service; or (c) would cause harm to any user, other third party, potluq, or our Affiliates. Where potluq is terminating your access for Service changes, where reasonably possible, you will be provided with sufficient time to export your Content from the Service.`}</p>
            <h5>{`Effect of Account Suspension or Termination`}</h5>
            <p>{`If your account is terminated or your account’s access to the Service is restricted, you may continue using certain aspects of the Service (such as viewing only) without an account, and this Agreement will continue to apply to such use. If you believe your account has been terminated in error, you can `}<Link className="" to="/help">contact help.</Link></p>
            <br/>
            <h4>{`Other Legal Terms`}</h4>
            <h5>{`Warranty Disclaimer`}</h5>
            <p>{`OTHER THAN AS EXPRESSLY STATED IN THIS AGREEMENT OR AS REQUIRED BY LAW, THE SERVICE IS PROVIDED “AS IS” AND POTLUQ DOES NOT MAKE ANY SPECIFIC COMMITMENTS OR WARRANTIES ABOUT THE SERVICE. FOR EXAMPLE, WE DON’T MAKE ANY WARRANTIES ABOUT: (A) THE CONTENT PROVIDED THROUGH THE SERVICE; (B) THE SPECIFIC FEATURES OF THE SERVICE, OR ITS ACCURACY, RELIABILITY, AVAILABILITY, OR ABILITY TO MEET YOUR NEEDS; OR (C) THAT ANY CONTENT YOU SUBMIT WILL BE ACCESSIBLE ON THE SERVICE.`}</p>
            <h5>{`Limitation of Liability`}</h5>
            <p>{`EXCEPT AS REQUIRED BY APPLICABLE LAW, POTLUQ, ITS AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS WILL NOT BE RESPONSIBLE FOR ANY LOSS OF PROFITS, REVENUES, BUSINESS OPPORTUNITIES, GOODWILL, OR ANTICIPATED SAVINGS; LOSS OR CORRUPTION OF DATA; INDIRECT OR CONSEQUENTIAL LOSS; PUNITIVE DAMAGES CAUSED BY:`}</p>
            <ol>
                <li>{`ERRORS, MISTAKES, OR INACCURACIES ON THE SERVICE;`}</li>
                <li>{`PERSONAL INJURY OR PROPERTY DAMAGE RESULTING FROM YOUR USE OF THE SERVICE;`}</li>
                <li>{`ANY UNAUTHORIZED ACCESS TO OR USE OF THE SERVICE;`}</li>
                <li>{`ANY INTERRUPTION OR CESSATION OF THE SERVICE;`}</li>
                <li>{`ANY VIRUSES OR MALICIOUS CODE TRANSMITTED TO OR THROUGH THE SERVICE BY ANY THIRD PARTY;`}</li>
                <li>{`ANY CONTENT WHETHER SUBMITTED BY A USER OR POTLUQ, INCLUDING YOUR USE OF CONTENT; AND/OR`}</li>
                <li>{`THE REMOVAL OR UNAVAILABILITY OF ANY CONTENT.`}</li>
            </ol>
            <p>{`THIS PROVISION APPLIES TO ANY CLAIM, REGARDLESS OF WHETHER THE CLAIM ASSERTED IS BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY.`}</p>
            <p>{`POTLUQ AND ITS AFFILIATES’ TOTAL LIABILITY FOR ANY CLAIMS ARISING FROM OR RELATING TO THE SERVICE IS LIMITED TO THE AMOUNT YOU HAVE PAID TO POTLUQ IN THE 12 MONTHS BEFORE THE DATE OF YOUR NOTICE IN WRITING, TO POTLUQ, OF THE CLAIM. `}</p>
            <h5>{`Indemnity`}</h5>
            <p>{`To the extent permitted by applicable law, you agree to defend, indemnify and hold harmless Potluq, its Affiliates, officers, directors, employees and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees) arising from: (i) your use of and access to the Service; (ii) your violation of any term of this Agreement; (iii) your violation of any third party right, including without limitation any copyright, property, or privacy right; or (iv) any claim that your Content caused damage to a third party. This defense and indemnification obligation will survive this Agreement and your use of the Service.`}</p>
            <h5>{`Third-Party Links`}</h5>
            <p>{`The Service may contain links to third-party websites and online services that are not owned or controlled by Potluq. Potluq has no control over, and assumes no responsibility for, such websites and online services. Be aware when you leave the Service; we suggest you read the terms and privacy policy of each third-party website and online service that you visit.`}</p>
            <br/>
            <h4>{`About this Agreement`}</h4>
            <h5>{`Modifying this Agreement`}</h5>
            <p>{`We may modify this Agreement, for example, to reflect changes to our Service or for legal, regulatory, or security reasons. Potluq will provide reasonable advance notice of any material modifications to this Agreement and the opportunity to review them, except that modifications addressing newly available features of the Service or modifications made for legal reasons may be effective immediately without notice. Modifications to this Agreement will only apply going forward. If you do not agree to the modified terms, you should remove any Content you have uploaded and discontinue your use of the Service.`}</p>
            <h5>{`Continuation of this Agreement`}</h5>
            <p>{`If your use of the Service ends, the following terms of this Agreement will continue to apply to you: “Other Legal Terms”, “About This Agreement”, and the licenses granted by you will continue as described under “Duration of License”.`}</p>
            <h5>{`Severance`}</h5>
            <p>{`If it turns out that a particular term of this Agreement is not enforceable for any reason, this will not affect any other terms.`}</p>
            <h5>{`No Waiver`}</h5>
            <p>{`If you fail to comply with this Agreement and we do not take immediate action, this does not mean that we are giving up any rights that we may have (such as the right to take action in the future).`}</p>
            <h5>{`Interpretation`}</h5>
            <p>{`In these terms, “include” or “including” means “including but not limited to,” and any examples we give are for illustrative purposes.`}</p>
            <h5>{`Governing Law`}</h5>
            <p>{`All claims arising out of or relating to these terms or the Service will be governed by Washington law, and will be litigated exclusively in the federal or state courts of King County, Washington, USA. You and Potluq consent to personal jurisdiction in those courts.`}</p>
            <h5>{`Limitation on Legal Action`}</h5>
            <p>{`YOU AND POTLUQ AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE SERVICES MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.`}</p>
            
        </div>
    );
  }
}

TOS.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {  }
)(TOS);
