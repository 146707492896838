import { combineReducers } from 'redux';
import errorReducer from './errorReducer';
import authReducer from './authReducer';
import profileReducer from './profileReducer';
import recipeReducer from './recipeReducer';
import bookReducer from './bookReducer';

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  profile: profileReducer,
  recipe: recipeReducer,
  book: bookReducer
});
