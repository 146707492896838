import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Spinner from '../common/Spinner';
import TextFieldGroup from '../common/TextFieldGroup';
import {editProfile} from '../../actions/profileActions';
import { getRecipe, reportRecipe, addLike, removeLike, addComment, deleteComment, likeUnlikeComment } from '../../actions/recipeActions';
import { createBook, addRecipeToBook } from '../../actions/bookActions';
import { Helmet } from 'react-helmet';
import {
  // EmailShareButton,
  FacebookShareButton,
  PinterestShareButton,
  RedditShareButton,
  // TumblrShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
import {  toast } from 'react-toastify';
import ReactPixel from 'react-facebook-pixel';

class RecipeView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commentText: '',
      commentSaving: false,
      errors: {}
    }
    this.saveToBook = this.saveToBook.bind(this);
    this.saveToFavorites = this.saveToFavorites.bind(this);
    this.createNewBook = this.createNewBook.bind(this);
    this.reportAction = this.reportAction.bind(this);
    this.bookSuccess = this.bookSuccess.bind(this);
    this.onChange = this.onChange.bind(this);
    // this.onSubmit = this.onSubmit.bind(this);
    this.like = this.like.bind(this);
    this.unlike = this.unlike.bind(this);
    this.state = {
      helmetTitle: 'recipe'
    }
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.getRecipe(this.props.match.params.id);
      // ReactPixel.pageView();
      ReactPixel.trackCustom('ViewRecipe', {recipe: `${this.props.match.params.id}`})
    }
    toast.configure({
      autoClose: 2000,
      draggable: true,
      pauseOnFocusLoss: true,
      draggablePercent: 60,
      className: 'toastStyle',
      bodyClassName: "toastStyle",
      progressClassName: 'toastProgress'
            //etc you get the idea
    });
  } 
  
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.recipe) {
      if (nextProps.recipe.recipe) {
        // console.log(nextProps.recipe)
          this.setState({
            helmetTitle: nextProps.recipe.recipe.name
          })
          if (this.props.recipe.recipe) {
            if (nextProps.recipe.recipe.comments !== this.props.recipe.recipe.comments) {
              this.setState({commentText: ''})
            }
          }
          
          // ReactPixel.track('ViewContent', {recipe:`${nextProps.recipe.recipe.name}`})
          // ReactPixel.trackCustom('ViewRecipe', {recipe:`${nextProps.recipe.recipe.name}`})
      }
    }
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors, commentSaving: false });
    }
  }
  bookSuccess() {
    toast('added to book!');
    console.log('success adding to book, calling bookSuccess');
  }
  saveToFavorites() {
    if (!this.props.auth.isAuthenticated) {
      alert('log into your potluq account to save recipes to favorites')
    } else {
    if (this.props.profile.myProfile.favoriteBook) {
      //call saveToBook(favoriteBook)
      // console.log('found you already have a favorites book')
      if(this.props.match.params.id) {
        this.props.addRecipeToBook(this.props.profile.myProfile.favoriteBook,this.props.match.params.id)
        .then(this.bookSuccess())}
    } else {
      console.log('looks like no favorites book yet')
      // Promise((resolve, reject) => {
        const bookDetails = {
          title: 'favorites',
          description: 'my favorite recipes',
          recipes: []
        }
        if (this.props.recipe) {
          bookDetails.recipes = bookDetails.recipes.concat(`${this.props.recipe.recipe._id}`);
          // console.log('book should contain ' + this.props.recipe.recipe._id)
        }
      console.log('calling createBook');
      this.props
        .createBook(bookDetails)
        .then(res => {
          this.setState({ errors: {} });
          const profileData = this.props.profile.myProfile;
          // profileData.bookNames = [res.data.title];
          // profileData.bookIds = [res.data._id];
          profileData.favoriteBook = res.data._id;
          // console.log('profile data to send is ' + profileData.books['favorites'])
          this.props.editProfile(profileData)
          // resolve();
        })
        .catch(err => {
          this.setState({ errors: err });
        });
      // }
      // );
    }
    }
  }  

  reportAction() {
    if (!this.props.auth.isAuthenticated) {
      alert('log into your potluq account to report content')
    } else {
      if (window.confirm('do you want to flag this recipe as inappropriate content?')) {
        this.props.reportRecipe(this.props.match.params.id, this.props.history)
        .then(res => {
          console.log('response was ' + res)}
        ).catch( err => {
          console.log('error was' + err)
        });
      }
    }
  }

  like() {
    this.props.addLike(this.props.match.params.id);
  }
  unlike() {
    this.props.removeLike(this.props.match.params.id);
  }

  // onSubmit(e) {
  //   e.preventDefault();
  //   this.setState({commentSaving: true});
  //   const { user } = this.props.auth;
  //   if (this.props.profile) {
  //     if (this.props.profile.myProfile) {
  //       if (this.props.profile.myProfile.handle) {
  //         const newComment = {
  //           text: this.state.commentText,
  //           handle: this.props.profile.myProfile.handle
  //         };
  //         this.props.addComment(newComment);
  //         ReactPixel.trackCustom('NewComment');
  //       }
  //     }
  //   }
    
  // }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  createNewBook() {
    if (!this.props.auth.isAuthenticated) {
      alert('log into your potluq account to create a recipe book')
    } else {
    //open up a dialog popup and ask for book deails
    //call createBook()
    let bookPromise = new Promise((resolve, reject) => {
      // pop up goes here to collect bookDetails
      
      const bookDetails = {
        title: 'placeholder',
        description: 'my new recipe book',
        recipes: []
      }
      bookDetails.title = prompt('name your recipe book',`grandma's classics`)
      bookDetails.description = prompt('provide a description for your new recipe book',`my new collection of saved recipes`)
      if (this.props.recipe) {
        bookDetails.recipes = bookDetails.recipes.concat(`${this.props.recipe.recipe._id}`);
        // console.log('book should contain ' + this.props.recipe.recipe._id)
      }
      // console.log('calling createBook');
      this.props
        .createBook(bookDetails)
        .then(res => {
          console.log('createBook triggered, returned _id: ' + res.data._id);
          this.setState({ errors: {} });
          const profileData = this.props.profile.myProfile;
          profileData.bookNames = this.props.profile.myProfile.bookNames.concat(res.data.title);
          profileData.bookIds = this.props.profile.myProfile.bookIds.concat(res.data._id);
          // console.log('profile data to send is ' + profileData.books['favorites'])
          this.props.editProfile(profileData)
          resolve();
        })
        .catch(err => {
          console.log('favorite creation did not work');
          this.setState({ errors: err });
        });
      });
      bookPromise.then().catch(err => {
        console.log('favorite creation did not work');
        this.setState({ errors: err });
      })
    }
  }

  saveToBook(arrayNumber) {
    //match arrayNumber to retrieve bookId & addRecipeToBook
    if (this.props.profile.myProfile.bookIds) {
      console.log('aiming for array number ' + arrayNumber)
      console.log('adding ' + this.props.match.params.id + ' to ' + this.props.profile.myProfile.bookIds[arrayNumber])
      this.props.addRecipeToBook(this.props.profile.myProfile.bookIds[arrayNumber],this.props.match.params.id)
      .then(this.bookSuccess())
    }
    //pop up dialogue saying success??
  }

  copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    el.contentEditable = true;
    el.readOnly = false;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    alert("copied " + str + " to clipboard")
  };

  render() {
    const { recipe, loading } = this.props.recipe;
    const { errors } = this.state;
    const { user } = this.props.auth;
    let helmet = (
        <Helmet>
          <title>{this.state.helmetTitle}</title>
          <meta property="og:title" content={this.state.helmetTitle} />
        </Helmet>
    )
    let recipeContent;
    let recipeTitleBar;
    let recipeIngredients;
    let recipeSteps;
    let recipeChefNotes;
    let recipeYield;
    let recipePrep;
    let recipeTotalTime;
    let editButton;
    let privateRecipeIcon;
    let chefName;
    let saveButton;
    let socialButtons;
    let shareURL;
    let reportContent;
    let likeButton;
    let likeNumber;
    let bookNumber;
    let tags;
    let isMine = false;
    let existingComments;
    let addCommentField;
    let commentButton;

    if (recipe) {
      if (user.id === recipe.user) {
        isMine = true;
      } else {
        isMine = false;
      }
      if (recipe.privateFlag) {
        privateRecipeIcon = <p>Private</p>;
      } else {
        privateRecipeIcon = <div className=""></div>;
      }
    }

    if (recipe === null || loading) {
      recipeContent = <Spinner />;
    } else if (recipe.privateFlag && !isMine) {
      recipeContent = <Spinner />;
    } else {
      if (isMine) {
        editButton = (
          <div className="row d-flex flex-row-reverse justify-content-center">
            <Link
              to={`/editRecipe/${recipe._id}`}
              // className="btn btn-sm btn-light m-2 mt-1"
              className= "text-dark"
            >
              {`edit this recipe `}<i className="far fa-lg fa-edit  my-3 text-dark" />  
            </Link>
          </div>
          
        );
      } else {
        reportContent = (
          <div>
            <i className="fas fa-exclamation-triangle fa-lg m-2" onClick={this.reportAction}/>
          </div>
        )
      }
      if (recipe.saves > 0) {
        bookNumber = (
          <p>{recipe.saves}</p>
        )
      }
      //save recipe dropdown
      if (recipe) {
        saveButton = (
          <div className="dropdown row d-flex flex-row-reverse m-1">
            {bookNumber}<i className="fas fa-bookmark fa-lg m-2" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"/>
                    {/* <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Save
            </button> */}
            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
              <button className="dropdown-item" onClick={this.saveToFavorites}>Favorites</button>
              <button className="dropdown-item" onClick={this.createNewBook}>+ new recipebook</button>
            </div>
          </div>
          );
        // console.log(this.props.profile.myProfile)
        if (recipe.likeTotal > 0) {
          likeNumber = (
            <p>{recipe.likeTotal}</p>
          )
        }
        if (this.props.profile.myProfile != null) {
          if (this.props.profile.myProfile.user) {
            // if (this.props.profile.myProfile.bookNames[0] === 'favorites') {
              //TO DO - length check in next line is set to 1 for testing purposes, needs to be reset to 0
              if (this.props.profile.myProfile.bookNames) {
                if (this.props.profile.myProfile.bookNames.length > 0) {
                  saveButton = (
                    <div className="dropdown row d-flex flex-row-reverse m-1">
                      {bookNumber}<i className="fas fa-bookmark fa-lg m-2" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"/>
                      {/* <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Save
                      </button> */}
                      <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                      <button className="dropdown-item" onClick={this.saveToFavorites}>Favorites</button>
                        {this.props.profile.myProfile.bookNames.map((objectMapped, idx) => (
                          <button 
                          className="dropdown-item" 
                          onClick={() => this.saveToBook(idx)} 
                          key={idx}>
                          {objectMapped}</button>
                      
                    ))}
                    <button className="dropdown-item" onClick={this.createNewBook}>+ new recipe book</button>
                      </div>
                    </div>
                    );
            // }
                  }
                }
                if (recipe.likes.includes(this.props.profile.myProfile.user._id)) {
                  likeButton = (
                    <div className="row d-flex flex-row-reverse m-1">
                      {likeNumber}<i className="fas fa-heart fa-lg m-2" onClick={this.unlike}/>
                    </div>
                  )
                } else {
                  likeButton = (
                    <div className="row d-flex flex-row-reverse m-1">
                      {likeNumber}<i className="far fa-heart fa-lg m-2" onClick={this.like}/>
                    </div>
                  )
                }
              } else {
                likeButton = (
                  <div className="row d-flex flex-row-reverse m-1">
                    {likeNumber}<Link to={`/login`} className="text-dark"><i className="far fa-heart fa-lg m-2"/></Link>
                    
                  </div>
                )
              }
        }
        
          shareURL = `https://www.potluq.recipes/recipe/${recipe._id}`;
          socialButtons = (
            <div className="justify-content-around align-items-center font-italic">
              <div> share this recipe </div>
              <a href={`mailto:?subject=Check%20out%20this%20recipe%20for%20${recipe.name}&body=Check%20out%20this%20recipe%20for%20${recipe.name}%20on%20potluq:%20${shareURL}`} className="text-dark">
                <i className="far fa-envelope fa-2x m-1"/></a>
                <i className="far fa-copy fa-2x m-1"
                onClick={()=>{
                  this.copyToClipboard(shareURL)
                }}
                />
              {/* <EmailShareButton url={shareURL}><i className="far fa-envelope fa-2x m-1"/></EmailShareButton> */}
              <FacebookShareButton url={shareURL}> <i className="fab fa-facebook fa-2x m-1"/></FacebookShareButton>
              <PinterestShareButton url={shareURL} media={recipe.picture}> <i className="fab fa-pinterest fa-2x m-1"/></PinterestShareButton>
              <RedditShareButton url={shareURL}> <i className="fab fa-reddit fa-2x m-1"/></RedditShareButton>
              {/* <TumblrShareButton url={shareURL}> <i className="fab fa-tumblr fa-2x m-1"/></TumblrShareButton> */}
              <TwitterShareButton url={shareURL}> <i className="fab fa-twitter fa-2x m-1"/></TwitterShareButton>
              <WhatsappShareButton url={shareURL}> <i className="fab fa-whatsapp fa-2x m-1"/></WhatsappShareButton>
            </div>
          )
      }
     let shoutOut
     if (recipe.source) {
       shoutOut = (
         <p>
           with shoutout to {recipe.source}
         </p>
       )
     }
     if (recipe.tags.length > 0) {
       tags = (
        <div className="row">
          <p className="col-4 col-md-3 text-right">tags:</p>
          <div className="d-flex flex-wrap col-8 pb-1">
            {recipe.tags.map((objectMapped, idx) => (
              <div className="tag ml-1 mb-1" key={idx}>
                <span className="btn tertiary-color-bg text-light recipeCardContainer mt-1 mr-1 px-1 py-0">
                  {`${objectMapped.name}`}
                </span>
              </div>
            ))}
          </div>
        </div>
        
       )
     }


      //show chef name - using IF logic because many test recipes lack a chef name - eventually IF logic can be removed
      if (recipe.chef) {
        chefName = (
          <div>
            <p className="mb-0">by <Link to={`/chef/${recipe.handle}`}>{recipe.chef}</Link>
              <img 
                className="rounded-circle ml-1"
                src={recipe.avatar}
                link={`/chef/${recipe.handle}`}
                alt={"chef"}
                style={{ width: 30, height: 30 }}
                /></p>
                {shoutOut}
          </div>
        );
      } else {
        chefName = <div className=""></div>;
      }


      //recipe title bar
      if (recipe.name) {
        recipeTitleBar = (
          <div className="container-fluid">
            <div className="row">
              <div className="col-8">
                <h4 className="my-2 mb-1 text-break">{recipe.name}</h4>
              </div>
              <div className="col-4 col-md-4 p-0 d-flex justify-content-around">
                {privateRecipeIcon}
                {saveButton}
                {likeButton}
                
              </div>
              
            </div>
            {chefName}
          </div>
        );
      } else {
        recipeTitleBar = (
          <div className="container-fluid">
            <div className="row">
              <div className="col-8">
                <h1 className="m-2 mb-1">{recipe.name}</h1>
              </div>
            </div>
          </div>
        );
      }

      //yield subcomponent
      if (recipe.yield) {
        recipeYield = <div className="">serves: {recipe.yield} </div>;
      }
      if (recipe.prepTime > 0) {
        recipePrep = <div className="">prep: {recipe.prepTime}m</div>;
      }
      if (recipe.cookTime) {
        recipeTotalTime = <div className="">cook: {recipe.cookTime}m</div>;
      }
      //ingredients subcomponent
      if (recipe.ingredients) {
        if (recipe.ingredients.length > 0) {
          recipeIngredients = recipe.ingredients.map((ingredient, i) => (
            <div className="" key={i}>
              {ingredient.ingredient}
            </div>
          ));
        }
      } else {
        recipeIngredients = <p>no ingredients</p>;
      }
      //steps subcomponent
      if (recipe.procedure) {
        if (recipe.procedure.length > 0) {
          recipeSteps = recipe.procedure.map((step, i) => (
            <div className="" key={i}>
              {step.step}
            </div>
          ));
        }
      } else {
        recipeSteps = <p>no ingredients</p>;
      }
      if (recipe.chefNotes !== ' ') {
        recipeChefNotes = (
          <div className="row">
                  <p className="col-4 col-md-3 text-right">notes:</p>
                  <div className="col-8 text-left">{recipe.chefNotes}</div>
                </div>
          
        )
      }
      if (!this.state.commentSaving) {
        commentButton = (
          <button
            type="button"
            onClick={()=>{
              console.log(`commenting on ${this.props.match.params.id}`);
              this.setState({commentSaving: true})
              this.props.addComment(this.props.match.params.id,{text:this.state.commentText,handle:this.props.profile.myProfile.handle})
            }}
            className="btn-sm border-0 secondary-color-bg text-light ml-2 mt-0"
          >comment <i className="far fa-comment"/></button>
        )
      } else {
        commentButton = (
          <i class="fas fa-sync fa-spin fa-lg ml-3"/>
        )
      }
      //feed of comments - "existingComments"
      if (recipe.comments.length < 1) {
        existingComments = (
          <div></div>
        )
      } else {
        existingComments = (
          <div className="container mt-3 col-md-8">
            <div className="font-italic">  comments  </div>
            {recipe.comments.map((commentObject, idx) => {
              if (commentObject.user === user.id || isMine) { return (
              <div className="" key={idx}> 
                <div className="d-flex recipeCardContainer text-left">
                <i class="fas fa-comment mr-2"/>
                {commentObject.text}
                <i 
                  className="far fa-times-circle fa-2x cursorPointer tagText removeCommentButton hoverSpin" 
                  onClick={()=> {
                    this.props.deleteComment(this.props.match.params.id,commentObject._id)
                    // console.log('deleting comment ' + commentObject._id)
                  }}
                  />
                </div>
                  <div className="d-flex justify-content-end align-items-center">
                    <i class="fas fa-heart fa-lg mr-1" 
                    onClick={()=>this.props.likeUnlikeComment(this.props.match.params.id,commentObject._id)}/>
                    {commentObject.likes.length}
                    <img 
                    className="rounded-circle ml-2 mt-0 mr-1 mb-2"
                    src={commentObject.avatar}
                    link={`/chef/${commentObject.handle}`}
                    alt={"chef"}
                    style={{ width: 30, height: 30 }}
                    />
                    <Link to={`/chef/${commentObject.handle}`}>{commentObject.chef}</Link>
                  </div>
              </div>
            )} else if (this.props.auth.isAuthenticated) {return (
              <div className="" key={idx}> 
                <div className="d-flex ">
                <i class="fas fa-comment mr-2"/>{commentObject.text}
                </div>
                  <div className="d-flex justify-content-end align-items-center">
                  <i class="fas fa-heart fa-lg mr-1" 
                    onClick={()=>this.props.likeUnlikeComment(this.props.match.params.id,commentObject._id)}/>
                    {commentObject.likes.length}
                    <img 
                    className="rounded-circle ml-2 mt-0 mr-1"
                    src={commentObject.avatar}
                    link={`/chef/${commentObject.handle}`}
                    alt={"chef"}
                    style={{ width: 30, height: 30 }}
                    />
                    <Link to={`/chef/${commentObject.handle}`}>{commentObject.chef}</Link>
                  </div>
                </div>
            )} else { return (
              <div className="" key={idx}> 
                <div className="d-flex ">
                <i class="fas fa-comment mr-2"/>{commentObject.text}
                </div>
                  <div className="d-flex justify-content-end align-items-center">
                    <img 
                    className="rounded-circle ml-1 mt-0 mr-1"
                    src={commentObject.avatar}
                    link={`/chef/${commentObject.handle}`}
                    alt={"chef"}
                    style={{ width: 30, height: 30 }}
                    />
                    <Link to={`/chef/${commentObject.handle}`}>{commentObject.chef}</Link>
                  </div>
                </div>
            )}})}
          </div>
          )
      }
      //new comment field - "addCommentField"
      if (!this.props.auth.isAuthenticated) {
        addCommentField = (
          <div><i className="fas fa-comment mr-2"/>
            <Link to={`/login`}>
              log in  
            </Link> or <Link to={`/register`}>
              create an account 
            </Link> to comment
          </div>
        )
      } else {
        if (recipe.commentsEnabled) {
          let textErrors
          if (errors) {
            textErrors = errors.text
          }
          
          addCommentField = (
            <div className="container mt-3 col-md-9">
                <div>
                  <div className="mb-0">
                    <TextFieldGroup
                    placeholder={this.state.placeholderName}
                    name="commentText"
                    value={this.state.commentText}
                    onChange={this.onChange}
                    error={textErrors}
                    className="mb-0"
                  />
                  <div className="d-flex justify-content-end align-items-center mt-0">
                    {/* <div className="d-flex justify-content-around"> */}
                      <img 
                        className="rounded-circle ml-1 my-0 mr-1 mt-0"
                        src={user.avatar}
                        alt={"you"}
                        style={{ width: 30, height: 30 }}
                        
                        />
                      {user.name}
                      {commentButton}
                    {/* </div> */}
                    </div>
                  
                    
                  </div>
                </div>
              </div>
          )
        } else {
          addCommentField = (
            <div>
              {recipe.chef} has disabled comments for this recipe
            </div>
          )
        }
      }
      
      //actual recipe content if not loading
      recipeContent = (
        <div className="text-center">
          <div className="">
            <div className="container p-0">
              <div className="row m-2">{recipeTitleBar}</div>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-1" />
                  <div className="col-md-6 col-sm-10">
                    <img src={recipe.picture} alt="recipe" />
                  </div>
                  <div className="col-1" />
                </div>
              </div>

              <div className="container mt-3">
                <div className="row ">
                  <div className="col-4">
                    {recipeYield}
                    {recipePrep}
                    {recipeTotalTime}
                    {/* <div className="">Serves: {recipe.yield}</div>
                    <div className="">Prep: {recipe.prepTime}m</div>
                    <div className="">Total: {recipe.cookTime}m</div> */}
                  </div>
                  <div className="col-8 text-left">{recipe.description}</div>
                </div>
              </div>
              {/* ingredients section */}
              <div className="container mt-3">
                <div className="row">
                  <p className="col-4 col-md-3 text-right">ingredients:</p>
                  <div className="col-8 text-left">{recipeIngredients}</div>
                </div>
              </div>
              {/* procedure section */}
              <div className="container mt-3">
                <div className="row">
                  <p className="col-4 col-md-3 text-right">steps:</p>
                  <div className="col-8 text-left">{recipeSteps}</div>
                </div>
              </div>
              {/* chefnotes section */}
              <div className="container mt-3">
                {recipeChefNotes}
              </div>
              <div className="container mt-3">
                {tags}
              </div>
              {/* social buttons */}
              <div className="container mt-3">
                    {socialButtons}
              </div>
              <div className="container mt-3">
                    {existingComments}
                    {addCommentField}
              </div>
              <div className="container mt-3">
                    <br/>
                    {editButton}
                    {reportContent}
              </div>
            </div>
            <div className="col-md-6" />
          </div>

          <br />
          {/*  */}
        </div>
      );
    }
    return (
      <div className="recipeView">
        {helmet}
        <div className="container">
          <div className="row">
            <div className="col-md-12">{recipeContent}</div>
          </div>
        </div>
      </div>
    );
  }
}

RecipeView.propTypes = {
  recipe: PropTypes.object.isRequired,
  getRecipe: PropTypes.func.isRequired,
  reportRecipe: PropTypes.func.isRequired,
  addLike: PropTypes.func.isRequired,
  removeLike: PropTypes.func.isRequired,
  createBook: PropTypes.func.isRequired,
  editProfile: PropTypes.func.isRequired,
  addRecipeToBook: PropTypes.func.isRequired,
  addComment: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired,
  likeUnlikeComment: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  book: PropTypes.object,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  recipe: state.recipe,
  user: state.user,
  profile: state.profile,
  auth: state.auth,
  book: state.book,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { getRecipe, 
    reportRecipe, 
    createBook, 
    editProfile, 
    addRecipeToBook, 
    addLike, 
    removeLike,
    addComment,
    deleteComment,
    likeUnlikeComment
  }
)(withRouter(RecipeView));
