import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TextFieldGroup from '../common/TextFieldGroup';
import {
  getRecipe,
  createRecipe,
  deleteRecipe,
  clearCurrentRecipe
} from '../../actions/recipeActions';
import { uploadRecipeImage } from '../../actions/s3Actions';
import isEmpty from '../../validation/is-empty';
import imageCompression from 'browser-image-compression';

class RecipeEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      name: '',
      description: '',
      procedure: [{ step: '' }],
      ingredients: [{ ingredient: '' }],
      picture: 'https://potluq-recipe-images.s3-us-west-2.amazonaws.com/images/empty+plate.jpg',
      pictureHeight: '',
      pictureWidth: '',
      prepTime: '',
      cookTime: '',
      yield: '',
      source: '',
      chefNotes: '',
      tags: [],
      tagInput: {},
      privateFlag: false,
      commentsEnabled: true,
      errors: '',
      deleting: false,
      recipeSaving: false,
      photoLoading: false,
      width: 800,
      height: 800,
      border: 50,
      color: [255, 255, 255, 0.6],
      scale: 1,
      rotate: 0,
      borderRadius: 0,
      imageFileName: ''
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.triggerUpload = this.triggerUpload.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleCommentCheck = this.handleCommentCheck.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.getRecipe(this.props.match.params.id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors, recipeSaving: false });
    }

    if (nextProps.recipe.recipe) {
      // console.log('received recipe props')
      const recipe = nextProps.recipe.recipe;
      const { user } = this.props.auth;
      //if recipe field doesn't exist, make an empty string for the respective value
      recipe.procedure = !isEmpty(recipe.procedure)
        ? recipe.procedure
        : [{ step: '' }];
      recipe.ingredients = !isEmpty(recipe.ingredients)
        ? recipe.ingredients
        : [{ ingredient: '' }];
      recipe.picture = !isEmpty(recipe.picture)
        ? recipe.picture
        : 'https://potluq-recipe-images.s3-us-west-2.amazonaws.com/images/empty+plate.jpg';
      recipe.pictureHeight = !isEmpty(recipe.pictureHeight) ? recipe.pictureHeight : '';
      recipe.pictureWidth = !isEmpty(recipe.pictureWidth) ? recipe.pictureWidth : '';
      recipe.prepTime = !isEmpty(recipe.prepTime) ? recipe.prepTime : '';
      recipe.cookTime = !isEmpty(recipe.cookTime) ? recipe.cookTime : '';
      recipe.yield = !isEmpty(recipe.yield) ? recipe.yield : '';
      recipe.source = !isEmpty(recipe.source) ? recipe.source : '';
      recipe.chefNotes = !isEmpty(recipe.chefNotes) ? recipe.chefNotes : '';
      recipe.tags = !isEmpty(recipe.tags) ? recipe.tags : [];
      recipe.privateFlag = !isEmpty(recipe.privateFlag)
        ? recipe.privateFlag
        : false;
      recipe.commentsEnabled = !isEmpty(recipe.commentsEnabled)
        ? recipe.commentsEnabled
        : true;

      //set component fields state
      this.setState({
        id: recipe._id,
        name: recipe.name,
        description: recipe.description,
        procedure: recipe.procedure,
        ingredients: recipe.ingredients,
        picture: recipe.picture,
        pictureHeight: recipe.pictureHeight,
        pictureWidth: recipe.pictureWidth,
        prepTime: recipe.prepTime,
        cookTime: recipe.cookTime,
        yield: recipe.yield,
        source: recipe.source,
        chefNotes: recipe.chefNotes,
        privateFlag: recipe.privateFlag,
        commentsEnabled: recipe.commentsEnabled,
        tags: recipe.tags
      });
      if (user.id !== recipe.user) {
        console.log(`this is not your recipe and I'm redirecting you`);
        this.props.clearCurrentRecipe();
        this.props.history.push('/allRecipes');
      } else {
        // console.log('this is your recipe and you may edit it');
      }
    }
  }

  onSubmit(e) {
    e.preventDefault();
    // console.log('submitted');
    this.setState({recipeSaving: true});
    const { user } = this.props.auth;
      const newRecipe = {
        id: this.state.id,
        chef: user.name,
        avatar: user.avatar,
        handle: this.props.profile.myProfile.handle,
        name: this.state.name,
        description: this.state.description,
        procedure: this.state.procedure,
        ingredients: this.state.ingredients,
        picture: this.state.picture,
        pictureHeight: this.state.pictureHeight,
        pictureWidth: this.state.pictureWidth,
        prepTime: this.state.prepTime,
        cookTime: this.state.cookTime,
        yield: this.state.yield,
        source: this.state.source,
        chefNotes: this.state.chefNotes,
        privateFlag: this.state.privateFlag,
        commentsEnabled: this.state.commentsEnabled,
        tags: this.state.tags
      };
  
      this.props.createRecipe(newRecipe, this.props.history);
  }

  

  onDeleteClick(e) {
    // console.log('delete click triggered');
    this.setState({ deleting: true });
    this.props.deleteRecipe(this.state.id);
    setTimeout(
      function() {
        this.props.history.push('/allRecipes');
      }.bind(this),
      1000
    );

    // console.log('delete click triggered');
  }

  onChange(e) {
    // console.log('onchanged')
    this.setState({ [e.target.name]: e.target.value });
  }

  handleCheck(e) {
    this.setState({
      privateFlag: e.target.checked, commentsEnabled: !e.target.checked
    });
  }
  handleCommentCheck(e) {
    this.setState({
      commentsEnabled: e.target.checked
    });
  }

  //set of functions to handle ingredients array
  handleIngredientArrayChange = idx => e => {
    const newIngredientArray = this.state.ingredients.map(
      (ingredient, sidx) => {
        if (idx !== sidx) return ingredient;
        return { ...ingredient, ingredient: e.target.value };
      }
    );
    this.setState({ ingredients: newIngredientArray });
  };
  addIngredientField = () => {
    this.setState({
      ingredients: this.state.ingredients.concat([' '])
    });
  };
  removeIngredient = idx => () => {
    this.setState({
      ingredients: this.state.ingredients.filter((s, sidx) => idx !== sidx)
    });
  };

  //copied set of functions to handle procedure array
  handleProcedureArrayChange = idx => e => {
    const newProcedureArray = this.state.procedure.map((step, sidx) => {
      if (idx !== sidx) return step;
      return { ...step, step: e.target.value };
    });
    this.setState({ procedure: newProcedureArray });
  };
  addProcedureField = () => {
    this.setState({
      procedure: this.state.procedure.concat([' '])
    });
  };
  removeStep = idx => () => {
    this.setState({
      procedure: this.state.procedure.filter((s, sidx) => idx !== sidx)
    });
  };

  //copied set of functions to handle tag array
  handleTagInputChange = idx => e => {
    const newInput = { name: e.target.value };
    this.setState({ tagInput: newInput });
  };
  addTag = () => {
    if (this.state.tagInput.name !== '') {
      this.setState({
        tags: this.state.tags.concat(this.state.tagInput)
      });
      const blankTag = { name: '' };
      this.setState({ tagInput: blankTag });
    }
  };
  removeTag = idx => () => {
    this.setState({
      tags: this.state.tags.filter((s, sidx) => idx !== sidx)
    });
  };
  addPreTag = preTag => {
    this.setState({
      tags: this.state.tags.concat({ name: preTag })
    });
  };

  getOrientation(file, callback) {
    var reader = new FileReader();
    reader.onload = function(e) {

        var view = new DataView(e.target.result);
        if (view.getUint16(0, false) !== 0xFFD8)
        {
            return callback(-2);
        }
        var length = view.byteLength, offset = 2;
        while (offset < length) 
        {
            if (view.getUint16(offset+2, false) <= 8) return callback(-1);
            var marker = view.getUint16(offset, false);
            offset += 2;
            if (marker === 0xFFE1) 
            {
                if (view.getUint32(offset += 2, false) !== 0x45786966) 
                {
                    return callback(-1);
                }
                var little = view.getUint16(offset += 6, false) === 0x4949;
                offset += view.getUint32(offset + 4, little);
                var tags = view.getUint16(offset, little);
                offset += 2;
                for (var i = 0; i < tags; i++){
                    if (view.getUint16(offset + (i * 12), little) === 0x0112)
                    {
                        return callback(view.getUint16(offset + (i * 12) + 8, little));
                    }
                }
            }
            else if ((marker & 0xFF00) !== 0xFF00){
                break;
            } else { 
                offset += view.getUint16(offset, false);
            }
        }
        return callback(-1);
    };
    reader.readAsArrayBuffer(file);
}

  async promiseParent(e) {
    // console.log('component mounted with ' + e);
    let promise = new Promise((resolve, reject) => {
      // let smallImage = null;
      let width = 1080;
      let height = 1080;
      let scaleFactor;
      let orientationData
      let file = e.target.files[0]
      this.getOrientation(file, function(orientation) {
        console.log('got orientation, is ' + orientation);
        orientationData = orientation;
        if (orientationData < 1 ) {
          const fileName = file.name;
          const reader = new FileReader();
          reader.readAsDataURL(file);
          let resizePromise = new Promise((resolve, reject) => {
            // if (e.target.files[0].type != gif)
            if (file.type === 'image/gif') {
              resolve(file);
            } else {
              // console.log(e.target.files[0].type);
                reader.onload = event => {
                  const img = new Image();
                  img.src = event.target.result;
                  img.onload = () => {
                    scaleFactor = width / img.width;
                    height = img.height * scaleFactor;

                    const elem = document.createElement('canvas');
                    elem.width = width;
                    elem.height = height;
                    this.setState({
                      pictureWidth: width,
                      pictureHeight: height
                    })
                    const ctx = elem.getContext('2d');
                    // img.width and img.height will contain the original dimensions
                    ctx.drawImage(img, 0, 0, width, height);

                    let innerPromise = new Promise((resolve, reject) => {
                      ctx.canvas.toBlob(function(blob) {
                        let semaphore = new File([blob], fileName, {
                          type: 'image/jpeg',
                          lastModified: Date.now()
                        });
                        resolve(semaphore);
                      });
                    });

                    innerPromise.then(semaphore => {
                      resolve(semaphore);
                    });
                  };
                };
              }
            });
            reader.onerror = error => console.log(error);
            resizePromise.then(semaphore => {
              let finalPromise = new Promise((resolve, reject) => {
                // console.log('final promise, upload called with ' + semaphore);
                const { user } = this.props.auth;
                const pictureStateTarget = this;
                this.props.uploadRecipeImage(user, semaphore, pictureStateTarget);
                this.setState({
                  picture:
                    'https://potluq-recipe-images.s3-us-west-2.amazonaws.com/images/831-2.gif',
                    photoLoading: false
                });
                // console.log('should have uploaded');
              });
              finalPromise.then(() => resolve());
            });
        } else {
          // const fileName = file.name;
          const reader = new FileReader();
          reader.readAsDataURL(file);
          let resizePromise = new Promise((resolve, reject) => {
            // if (e.target.files[0].type != gif)
            if (file.type === 'image/gif') {
              resolve(file);
            } else {
              var options = {
                maxSizeMB: 1,
                useWebWorker: true
              }
              imageCompression(file, options).then(compressedImage => {
                // console.log('image should be 1MB now')
                resolve(compressedImage)
              })
              // reader.onload = event => {
              //   const img = new Image();
              //   img.src = event.target.result;
              //   img.onload = () => {
              //     scaleFactor = width / img.width;
              //     height = img.height * scaleFactor;
              //     const elem = document.createElement('canvas');
              //     // flip canvas dimensions if necessary based on orientation
              //     if (4 < orientationData && orientationData < 9) {
              //       elem.width = height;
              //       elem.height = width;
              //     } else {
              //       elem.width = width;
              //       elem.height = height;
              //     }
              //     this.setState({
              //       pictureWidth: elem.width,
              //       pictureHeight: elem.height
              //     })
              //     const ctx = elem.getContext('2d');
              //     // img.width and img.height will contain the original dimensions
              //     switch (orientationData) {
              //       case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
              //       case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
              //       case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
              //       case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
              //       case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
              //       case 7: ctx.transform(0, -1, -1, 0, height, width); break;
              //       case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
              //       default: break;
              //     }
              //     ctx.drawImage(img, 0, 0, elem.width, elem.height);

              //     let innerPromise = new Promise((resolve, reject) => {
              //       ctx.canvas.toBlob(function(blob) {
              //         let semaphore = new File([blob], fileName, {
              //           type: 'image/jpeg',
              //           lastModified: Date.now()
              //         });
              //         resolve(semaphore);
              //       });
              //     });

                  // innerPromise.then(semaphore => {
                  //   // console.log('innerPromise resolved');
                  //   // smallImage = semaphore;
                  //   // console.log(smallImage);
                  //   resolve(semaphore);
                  // });
                };
              });
          //   }
          // });
          reader.onerror = error => console.log(error);
          resizePromise.then(semaphore => {
            let finalPromise = new Promise((resolve, reject) => {
              // console.log('final promise, upload called with ' + semaphore);
              const { user } = this.props.auth;
              const pictureStateTarget = this;
              this.props.uploadRecipeImage(user, semaphore, pictureStateTarget);
              this.setState({
                picture:
                  'https://potluq-recipe-images.s3-us-west-2.amazonaws.com/images/831-2.gif',
                  photoLoading: false
              });
              // console.log('should have uploaded');
            });
            finalPromise.then(() => resolve());
          });
        }
      }.bind(this))
    });
    promise.then(() => console.log(''));
  }

  triggerUpload(e) {
    this.setState({photoLoading: true})
    this.promiseParent(e);
  }

  render() {
    const { errors } = this.state;

    let ingredientsInput = (
      <div>
        {this.state.ingredients.map((ingredient, idx) => (
          <div className="ingredient" key={`Ingredient ${idx + 1}`}>
            <input
              type="text"
              className="col-10"
              placeholder={`Ingredient #${idx + 1}`}
              value={ingredient.ingredient}
              onChange={this.handleIngredientArrayChange(idx)}
            />
            <button
              type="button"
              onClick={this.removeIngredient(idx)}
              className="btn-sm border-0 tertiary-color-bg text-light"
            >
              <i className="fas fa-times" />
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={this.addIngredientField}
          className="btn-sm border-0 tertiary-color-bg text-light rounded-circle mt-1"
        >
          <i className="fas fa-plus" />
        </button>
      </div>
    );

    let procedureInput = (
      <div>
        {this.state.procedure.map((step, idx) => (
          <div className="step" key={`Step ${idx + 1}`}>
            <input
              type="text"
              className="col-10"
              placeholder={`Step #${idx + 1}`}
              value={step.step}
              onChange={this.handleProcedureArrayChange(idx)}
              errors={errors.procedure}
            />
            <button
              type="button"
              onClick={this.removeStep(idx)}
              className="btn-sm border-0 tertiary-color-bg text-light"
            >
              <i className="fas fa-times" />
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={this.addProcedureField}
          className="btn-sm border-0 tertiary-color-bg text-light rounded-circle mt-1"
        >
          <i className="fas fa-plus" />
        </button>
      </div>
    );

    let mainCourseTag
    if (this.state.tags.filter(e=> e.name === `main course`).length > 0) {
      mainCourseTag = (<div></div>)
    } else {
      mainCourseTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`main course`)}>
          {`main course `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let appetizerTag
    if (this.state.tags.filter(e=> e.name === `appetizer`).length > 0) {
      appetizerTag = (<div></div>)
    } else {
      appetizerTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`appetizer`)}>
          {`appetizer `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    
    let sideTag
    if (this.state.tags.filter(e=> e.name === `side dish`).length > 0) {
      sideTag = (<div></div>)
    } else {
      sideTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`side dish`)}>
          {`side dish`} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let dessertTag
    if (this.state.tags.filter(e=> e.name === `dessert`).length > 0) {
      dessertTag = (<div></div>)
    } else {
      dessertTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`dessert`)}>
          {`dessert `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let fingerTag
    if (this.state.tags.filter(e=> e.name === `finger food`).length > 0) {
      fingerTag = (<div></div>)
    } else {
      fingerTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`finger food`)}>
          {`finger food `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let breakfastTag
    if (this.state.tags.filter(e=> e.name === `breakfast`).length > 0) {
      breakfastTag = (<div></div>)
    } else {
      breakfastTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`breakfast`)}>
          {`breakfast `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let snackTag
    if (this.state.tags.filter(e=> e.name === `snack`).length > 0) {
      snackTag = (<div></div>)
    } else {
      snackTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`snack`)}>
          {`snack `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let drinkTag
    if (this.state.tags.filter(e=> e.name === `drink`).length > 0) {
      drinkTag = (<div></div>)
    } else {
      drinkTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`drink`)}>
          {`drink `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let mexicanTag
    if (this.state.tags.filter(e=> e.name === `Mexican`).length > 0) {
      mexicanTag = (<div></div>)
    } else {
      mexicanTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`Mexican`)}>
          {`Mexican `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let healthyTag
    if (this.state.tags.filter(e=> e.name === `healthy`).length > 0) {
      healthyTag = (<div></div>)
    } else {
      healthyTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`healthy`)}>
          {`healthy `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let comfortTag
    if (this.state.tags.filter(e=> e.name === `comfort food`).length > 0) {
      comfortTag = (<div></div>)
    } else {
      comfortTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`comfort food`)}>
          {`comfort food`} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let indianTag
    if (this.state.tags.filter(e=> e.name === `Indian`).length > 0) {
      indianTag = (<div></div>)
    } else {
      indianTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`Indian`)}>
          {`Indian `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let cajunTag
    if (this.state.tags.filter(e=> e.name === `Cajun`).length > 0) {
      cajunTag = (<div></div>)
    } else {
      cajunTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`Cajun`)}>
          {`Cajun `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let chineseTag
    if (this.state.tags.filter(e=> e.name === `Chinese`).length > 0) {
      chineseTag = (<div></div>)
    } else {
      chineseTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`Chinese`)}>
          {`Chinese `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let americanTag
    if (this.state.tags.filter(e=> e.name === `American`).length > 0) {
      americanTag = (<div></div>)
    } else {
      americanTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`American`)}>
          {`American `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let frenchTag
    if (this.state.tags.filter(e=> e.name === `French`).length > 0) {
      frenchTag = (<div></div>)
    } else {
      frenchTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`French`)}>
          {`French `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let japaneseTag
    if (this.state.tags.filter(e=> e.name === `Japanese`).length > 0) {
      japaneseTag = (<div></div>)
    } else {
      japaneseTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`Japanese`)}>
          {`Japanese `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let greekTag
    if (this.state.tags.filter(e=> e.name === `Greek`).length > 0) {
      greekTag = (<div></div>)
    } else {
      greekTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`Greek`)}>
          {`Greek `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let soulTag
    if (this.state.tags.filter(e=> e.name === `soul food`).length > 0) {
      soulTag = (<div></div>)
    } else {
      soulTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`soul food`)}>
          {`soul food `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let seafoodTag
    if (this.state.tags.filter(e=> e.name === `seafood`).length > 0) {
      seafoodTag = (<div></div>)
    } else {
      seafoodTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`seafood`)}>
          {`seafood `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let italianTag
    if (this.state.tags.filter(e=> e.name === `Italian`).length > 0) {
      italianTag = (<div></div>)
    } else {
      italianTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`Italian`)}>
          {`Italian `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let thaiTag
    if (this.state.tags.filter(e=> e.name === `Thai`).length > 0) {
      thaiTag = (<div></div>)
    } else {
      thaiTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`Thai`)}>
          {`Thai `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let mideastTag
    if (this.state.tags.filter(e=> e.name === `middle eastern`).length > 0) {
      mideastTag = (<div></div>)
    } else {
      mideastTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3 suggestedTag" 
        onClick={() => this.addPreTag(`middle eastern`)}>
          {`middle eastern `} <i className="fas fa-plus cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let suggestedTags = (
      <div className="scrolling-wrapper mt-0 mb-0">
        {mainCourseTag}
        {appetizerTag}
        {dessertTag}
        {sideTag}
        {fingerTag}
        {snackTag}
        {drinkTag}
        {breakfastTag}
      </div>
    )
    let suggestedCuisineTags = (
      <div className="scrolling-wrapper mt-0 mb-0">
        {mexicanTag}
        {comfortTag}
        {healthyTag}
        {indianTag}
        {cajunTag}
        {chineseTag}
        {frenchTag}
        {americanTag}
        {japaneseTag}
        {greekTag}
        {soulTag}
        {seafoodTag}
        {italianTag}
        {thaiTag}
        {mideastTag}
      </div>
    )

    let tagsInput = (
      <div className="mt-2">
        <div className="tag" key={`Tag`}>
          <input
            type="text"
            placeholder={'add new tag'}
            value={this.state.tagInput.name}
            onChange={this.handleTagInputChange()}
            errors={errors.tagInput}
          />
          <button type="button" onClick={this.addTag} className="btn-sm border-0 tertiary-color-bg text-light hoverRed">
            <i className="fas fa-plus" />
          </button>
        </div>
      </div>
    );

    let currentTags = (
      <div className="row px-3 pb-1">
        {this.state.tags.map((objectMapped, idx) => (
          <div className="tag ml-1" key={idx}>
            <span className="btn primary-color-bg text-light recipeCardContainer mt-3 mr-3">
              {`${objectMapped.name}`}
              {/* <button
                type="button"
                onClick={this.removeTag(idx)}
                className="btn-sm border-0 tertiary-color-bg text-light ml-1"
              >
                <i className="fas fa-times" />
              </button> */}
              <i 
              className="far fa-times-circle fa-2x cursorPointer tagText hoverRed removeTagButton hoverSpin" 
              onClick={this.removeTag(idx)}
              />
            </span>
          </div>
        ))}
      </div>
    );
    // let photoPreview 
    // if (this.state.picture === this.state.originalPicture) {
    //   photoPreview = (
    //     <div className="">
    //       {'do you have a picture of it?'}
    //       <br />
    //       <AvatarEditor
    //       ref={this.setEditorRef}
    //       image={this.state.picture}
    //       width={this.state.width}
    //       height={this.state.height}
    //       border={this.state.border}
    //       color={this.state.color} // RGBA
    //       scale={this.state.scale}
    //       rotate={this.state.rotate}
    //       borderRadius={this.state.borderRadius}
    //       className="editor-canvas"
    //       style={{width:`${this.state.editorWidth}`, height:`${this.state.editorHeight}`}}
    //     />
    //     <input name="newImage" type="file" onChange={this.handleNewImage} className="mb-3" />
    //   </div>
    //   )
    // } else {
    //   photoPreview = (
    //     <div className="">
    //       {'do you have a picture of it?'}
    //       <br />
    //       <AvatarEditor
    //       ref={this.setEditorRef}
    //       image={this.state.picture}
    //       width={this.state.width}
    //       height={this.state.height}
    //       border={this.state.border}
    //       color={this.state.color} // RGBA
    //       scale={this.state.scale}
    //       rotate={this.state.rotate}
    //       borderRadius={this.state.borderRadius}
    //       className="editor-canvas"
    //       style={{width:`${this.state.editorWidth}`, height:`${this.state.editorHeight}`}}
    //     />
    //     <div>
    //         <button onClick={this.rotateRight} className="btn tertiary-color-bg">rotate</button>
    //       </div>
    //     <input name="newImage" type="file" onChange={this.handleNewImage} className="mb-3" />
    //   </div>
    //   )
    // }
    // let imageLoading
    // if (this.state.picture === this.state.originalPicture) {
    //   imageLoading = (
    //     <div></div>
    //   )
    // } else if (this.state.picture === 'https://potluq-recipe-images.s3-us-west-2.amazonaws.com/images/831-2.gif') {
    //   imageLoading = (
    //     <i className="fas fa-sync fa-spin"></i> 
    //   )
    // } else {
    //   imageLoading = (
    //     <div>
    //       <i className="fas fa-check"/>
    //       <br /> remember to save changes!
    //     </div>
    //     )
    // }
    let photoPreview;
    if ((this.state.photoLoading)|(this.state.picture === 'https://potluq-recipe-images.s3-us-west-2.amazonaws.com/images/831-2.gif')) {
      photoPreview = (
        <div className="ml-6"> 
          <br />
          <br />
          <br />
          <i class="fas fa-sync fa-spin fa-lg ml-3"/>
          <br />
          <br />
          <br />
        </div>
      )
    } else {
      photoPreview = (
          <img src={this.state.picture} alt="Recipe" className=" recipeImage2 mb-1"/>
        )
    }
    let photoUpload = (
      <div className="mb-2 ">
        <div>show us what it should look like?</div>
        {photoPreview}
        <br />
        <input type="file" accept="image/*" onChange={this.triggerUpload} />
      </div>
    );
    let deleteButton;
    if (this.state.deleting) {
      deleteButton = (
        <div className="btn btn-sm btn-danger m-2 mt-1">
          {/* <img
            src="https://potluq-recipe-images.s3-us-west-2.amazonaws.com/images/redDeleting.gif"
            alt="delete"
            style={{ width: 24, height: 24 }}
          /> */}
          <i className="fas fa-sync fa-lg fa-spin" />
        </div>
      );
    } else {
      deleteButton = (
        <div className="row">
          <button
            className="btn btn-sm btn-danger m-2 mt-1"
            onClick={this.onDeleteClick.bind(this)}
          >
            delete{'  '}
            {/* <img
              src="https://potluq-recipe-images.s3-us-west-2.amazonaws.com/images/trash.png"
              alt="delete"
              style={{ width: 24, height: 24 }}
            /> */}
            <i className="fas fa-trash-alt fa-lg ml-1"></i>
          </button>
        </div>
      );
    }
    let privateExplaination
    if (this.state.privateFlag) {
      privateExplaination = (
        <div className="small ml-2 mt-0 mb-0">
          <p>you cannot share private recipes with others</p>
        </div>
      )
    } else {
      privateExplaination = (
        <div>
        </div>
      )
    }
    let saveButton
    if (!this.state.recipeSaving) {
      saveButton = (
        <button type="submit" className="btn secondary-color-bg text-light">
                Save
              </button>
      )
    } else {
      saveButton = (
        <i className="fas fa-sync fa-spin fa-lg"/>
      )
    }
    let commentsCheckbox
    if (!this.state.privateFlag) {
      commentsCheckbox = (
        <div className="row px-3">
          <input
            type="checkbox"
            name="commentsEnabled"
            checked={this.state.commentsEnabled}
            onChange={this.handleCommentCheck}
            
          />
          <p className="ml-2 mb-0">allow chefs to comment on this recipe?</p>

        </div>
      )
    } else {
      commentsCheckbox = (
        <div></div>
      )
    }
    let commentExplanation
    if (!this.state.commentsEnabled) {
      commentExplanation = (
        <div className="small ml-2 mt-0 mb-0">
          <p>comments will be disabled on this recipe</p>
        </div>
      )
    } else {
      commentExplanation = (
        <div></div>
      )
    }
    return (
      <div className="post-form mb-3">
        <div className="card card-info">
          <div className="container">
            <div className=" row align-items-center justify-content-between m-0">
              <div className="">edit your recipe</div>
              <div className="align-right">{deleteButton}</div>
            </div>
          </div>

          <div className="card-body">
            <form onSubmit={this.onSubmit}>
              <div className="form-group">
                <p className="mb-1">what do you call it?</p>
                <TextFieldGroup
                  // placeholder="Black Forrest Chocolate Cake"
                  name="name"
                  value={this.state.name}
                  onChange={this.onChange}
                  error={errors.name}
                />
                <p className="mb-1">what is it?</p>
                <TextFieldGroup
                  placeholder="Our family recipe for a decadent chocolate cake"
                  name="description"
                  value={this.state.description}
                  onChange={this.onChange}
                  error={errors.description}
                />
                <p className="mb-1">want to give anyone a shoutout?</p>
                <TextFieldGroup
                  placeholder="grandma's original recipe"
                  name="source"
                  value={this.state.source}
                  onChange={this.onChange}
                  error={errors.source}
                />
                {/* {photoPreview}
                {imageLoading} */}
                {photoUpload}
                <p className="mb-1">what is in it?</p>
                {ingredientsInput}
                <p className="mb-1">how do you make it?</p>
                {procedureInput}
                <p className="my-1">how much prep does it take to make?</p>
                <div className="row ml-1">
                  <TextFieldGroup
                    placeholder="15"
                    name="prepTime"
                    value={this.state.prepTime}
                    onChange={this.onChange}
                    error={errors.prepTime}
                  />
                  <div className="ml-2 mt-1">minutes</div>
                </div>
                <p className="mt-1 mb-1">how long does it actually take to make?</p>
                <div className="row ml-1">
                  <TextFieldGroup
                    placeholder="15"
                    name="cookTime"
                    value={this.state.cookTime}
                    onChange={this.onChange}
                    error={errors.cookTime}
                  />
                  <div className="ml-2 mt-1">minutes</div>
                </div>
                <p className="mb-1">how much does it make?</p>
                <div className="row ml-1">
                  <TextFieldGroup
                    placeholder="4"
                    name="yield"
                    value={this.state.yield}
                    onChange={this.onChange}
                    error={errors.yield}
                  />
                  {/* <div className="ml-2 mt-1">servings</div> */}
                </div>
                <p className="mb-1">any notes or tips about this recipe?</p>
                <TextFieldGroup
                  placeholder="Mix some red pepper flakes in with the frosting for a surprise KICK"
                  name="chefNotes"
                  value={this.state.chefNotes}
                  onChange={this.onChange}
                  error={errors.chefNotes}
                />
                <p className="mb-1">tag your recipe so people can find it!</p>
                {currentTags}
                {suggestedTags}
                {suggestedCuisineTags}
                {tagsInput}
              </div>
              <div className="row px-3 pb-1">
                <input
                  type="checkbox"
                  name="privateFlag"
                  checked={this.state.privateFlag}
                  onChange={this.handleCheck}
                  className="mr-2"
                />
                <p>make this recipe private?</p>
                <br />
                  {privateExplaination}
              </div>
              <div className="row px-3 pb-0 pt-1 mt-1">
                {commentsCheckbox}
                {commentExplanation}
                </div>
              <div className="d-flex justify-content-center">
              {saveButton}
              </div>
              
            </form>
          </div>
        </div>
      </div>
    );
  }
}

RecipeEdit.propTypes = {
  createRecipe: PropTypes.func.isRequired,
  deleteRecipe: PropTypes.func.isRequired,
  getRecipe: PropTypes.func.isRequired,
  clearCurrentRecipe: PropTypes.func.isRequired,
  recipe: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  uploadRecipeImage: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  recipe: state.recipe,
  profile: state.profile,
  errors: state.errors,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {
    getRecipe,
    createRecipe,
    deleteRecipe,
    uploadRecipeImage,
    clearCurrentRecipe
  }
)(withRouter(RecipeEdit));
