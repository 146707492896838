import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Helmet} from 'react-helmet';

class Privacy extends Component {
//if I need to define any actions, or call anything onload, do it here

  render() {

    //if I decide to make anything on the About page dynamic - put the stuff here

    return (
        <div className="m-4 pl-2 pr-2">
          <Helmet>
            <title>Privacy policy</title>
          </Helmet>
          <h3>{`Privacy policy`}</h3>
          <br/>
          <p>{`Please read the whole policy! But in case you refuse, here's a TL;DR in 4 parts:`}</p>
          <ul>
              <li>{`Potluq is public and recipes are immediately viewable and searchable by anyone. If you have an account, you can also create private recipes that only you can access. You can also use Potluq under a pseudonym or nickname if you prefer not to use your name.`}</li>
              <li>{`When you use Potluq, even if you’re just looking at recipes, we receive some personal information from you like the type of device you’re using and your IP address. You can choose to share additional information with us like your email address, name, and a public profile. We use this information for things like keeping your account secure and showing you more relevant recipes and ads.`}</li>
              <li>{`We share some personal data with service providers to enable third party login, see how people use the site, and deliver relevant ads. We never sell your data to third parties or data brokers and never will.`}</li>
              <li>{`You may access, update, and delete your account, or content you have uploaded, at any time.`}</li>
    <li>{`If you have questions about this policy, how we collect or process your personal data, or anything else related to our privacy practices, we want to hear from you. You may `}<Link to="/help">contact us</Link>{` at any time.`}</li>
          </ul>
          <br />
          <h4>{`Information You Share With Us`}</h4>
            <p>{`We require certain information to provide our services to you. For example, you must have an account in order to upload or share content on Potluq. When you choose to share the information below with us, we collect and use it to operate our services.`}</p>
            <h5>{`Basic Account Information`}</h5>
            <p>{`You don’t have to create an account to use some of our service features, such as searching and viewing public content. If you do choose to create an account, you must provide us with some personal data so that we can provide our services to you. On Potluq this includes a unique handle (or display name, for example, "TheGreatestChef"), a user name (for example, "Jim", or "John Smith"), a password, and an email address. Your display name and user name are always public, but you can use either your real name or a pseudonym. You can also maintain multiple Potluq accounts if you like, for example to express different parts of your identity.`}</p>
            <h5>{`Public Information`}</h5>
            <p>{`Most activity on Potluq is public, including your profile information, when you created your account, and your recipes and certain information about your recipes like the date and time you created them. The lists you create, people you follow and who follow you, and recipes you Like or Share are also public. If you Like, Share , or otherwise publicly engage with an ad on our services, that advertiser might thereby learn information about you associated with the ad with which you engaged such as characteristics of the audience the ad was intended to reach. `}</p>
            <p>{`You are responsible for your profile, recipes, and other information you provide through our services, and you should think carefully about what you make public, especially if it is sensitive information. If you update your public information on Potluq, such as by deleting a recipe or deactivating your account, we will reflect your updated content on the Service. `}</p>
            <h5>{`Contact Information`}</h5>
            <p>{`We use your contact information, such as your email address, to authenticate your account and keep it - and our services - secure, and to help prevent spam, fraud, and abuse. We also use contact information to enable certain account features (for example, for password reset), and to send you information about our services, and to personalize our services, including ads. Although we will always use your contact information to provide support and account information, you will only receive additional information about our services if you elect to do so during signup or in your PROFILE SETTINGS. You may change your mind and this setting at any time. `}</p>
            <p>{`If you email us, we will keep the content of your message, your email address, and your contact information to respond to your request.`}</p>
            <h5>{`Private Recipes and Non-Public Content`}</h5>
            <p>{`We provide certain features that let you store content more privately. For example, you can make a recipe private to remove it from public feeds and search results, and prevent other Potluq users but yourself from viewing it. When you create private content, we will store and process your information related to it. When you create private content, remember that it will effectively disable social activities related to that recipe, such as sharing the recipe with others, or allowing users other than yourself to add the recipe to recipe books.`}</p>
            <p>{`Remember that recipes are public by default, and that if you update a recipe to be private, other Potluq users may have saved or preserved a copy of the recipe off the platform when it was public.`}</p>
            <h5>{`How You Control the Information You Share with Us`}</h5>
            <p>{`You select the privacy for content each time you create a recipe, or update it after the fact by editing the recipe.`}</p>
            <p>{`You may update your contact preferences and profile information at any time `}<Link to="/editprofileandaccount">here.</Link></p>
            <br/>
            <h4>{`Additional Information We Receive About You`}</h4>
            <p>{`We receive certain information when you use our services or other websites or mobile applications that include our content, and from third parties including advertisers. Like the information you share with us, we use the data below to operate our services.`}</p>
            <h5>{`Links`}</h5>
            <p>{`In order to operate our services, we may keep track of how you interact with links across our services. This includes links in emails we send you and links in Potluq content that appear on other websites or mobile applications.`}</p>
            <p>{`If you click on an external link or ad on our services, that advertiser or website operator might figure out that you came from Potluq, along with other information associated with the ad you clicked such as characteristics of the audience it was intended to reach. They may also collect other personal data from you, such as cookie identifiers or your IP address.`}</p>
            <h5>{`Cookies`}</h5>
            <p>{`A cookie is a small piece of data that is stored on your computer or mobile device. Like many websites, we use cookies and similar technologies to collect additional website usage data and to operate our services. Cookies are not required for many parts of our services such as searching and looking at public content. Although most web browsers automatically accept cookies, many browsers’ settings can be set to decline cookies or alert you when a website is attempting to place a cookie on your computer. However, some of our services may not function properly if you disable cookies. When your browser or device allows it, we may use both session cookies and persistent cookies to better understand how you interact with our services, to monitor aggregate usage patterns, and to personalize and otherwise operate our services such as by providing account security, personalizing the content we show you including ads, and remembering your preferences. `}</p>
            <h5>{`Log Data`}</h5>
            <p>{`We receive information when you view content on or otherwise interact with our services, which we refer to as “Log Data,” even if you have not created an account. For example, when you visit our website, sign into our services, interact with our email notifications, or visit a third-party service that includes Potluq content, we may receive information about you. This Log Data includes information such as your IP address, browser type, operating system, the referring web page, pages visited, general location, your mobile carrier, device information (including device and application IDs), search terms (including those not submitted as queries), and cookie information. We also receive Log Data when you click on, view, or interact with links on our services. We use Log Data to operate our services and ensure their secure, reliable, and robust performance. For example, we use Log Data to protect the security of accounts and to determine what content is popular on our services. We also use this data to improve the content we show you, including ads and to improve the effectiveness of our own marketing.`}</p>
            <h5>{`Third Parties and Affiliates`}</h5>
            <p>{`We may receive information about you from third parties, such as others on Potluq, partners who help us evaluate the safety and quality of content on our platform, and other services you link to your Potluq account.`}</p>
            <p>{`You may choose to connect your Potluq account to accounts on another service, and that other service may send us information about your account on that service. We use the information we receive to provide you features like cross-service authentication, and to operate our services. You may revoke this permission at any time through the service you have connected to Potluq.  `}</p>
            <br />
            <h4>{`Information We Share and Disclose`}</h4>
            <h5>{`Sharing That You Control`}</h5>
            <p>{`We share or disclose personal data you choose to share publicly on our Services, for example your profile information or any personal information you choose to include in Content you share. You can remove personal data from your profile at any time by editing it `}<Link to="/editprofileandaccount">here</Link>{`, and edit or make your Content private at any time by updating recipes individually. `}</p>
            <h5>{`Service Providers`}</h5>
            <p>{`We engage service providers to perform functions and provide services to us. For example, we use a variety of third-party services to help operate our services, such as hosting our application and data, and to help us understand the use of our services, such as Facebook Analytics. We may share your private personal data with such service providers subject to obligations consistent with this Privacy Policy and any other appropriate confidentiality and security measures, and on the condition that the third parties use your private personal data only on our behalf and pursuant to our instructions (service providers may use other non-personal data for their own benefit). `}</p>
            <p>{`We share your payment information with payment services providers to process payments; prevent, detect, and investigate fraud or other prohibited activities; facilitate dispute resolution such as chargebacks or refunds; and for other purposes associated with the acceptance of credit and debit cards.`}</p>
            <h5>{`Law, Harm, and the Public Interest`}</h5>
            <p>{`Notwithstanding anything to the contrary in this Privacy Policy or controls we may otherwise offer to you, we may preserve, use, or disclose your personal data or other safety data if we believe that it is reasonably necessary to comply with a law, regulation, legal process, or governmental request; to protect the safety of any person; to protect the safety or integrity of our Services, including to help prevent spam, abuse, or malicious actors on our services, or to explain why we have removed content or accounts from our services; to address fraud, security, or technical issues; or to protect our rights or property or the rights or property of those who use our Services. However, nothing in this Privacy Policy is intended to limit any legal defenses or objections that you may have to a third party’s, including a government’s, request to disclose your personal data.`}</p>
            <h5>{`Affiliates and Change of Ownership`}</h5>
            <p>{`In the event that we are involved in a bankruptcy, merger, acquisition, reorganization, or sale of assets, your personal data may be sold or transferred as part of that transaction. This Privacy Policy will apply to your personal data as transferred to the new entity. We may also disclose personal data about you to our corporate affiliates in order to help operate our services and our affiliates’ services, including the delivery of ads.`}</p>
            <h5>{`Non-Personal Information`}</h5>
            <p>{`We share or disclose non-personal data, such as aggregated information like the total number of times people engaged with a recipe, demographics, the number of people who clicked on a particular link  (even if only one did), the types of content that are trending, some inferred interests, or reports to ad networks and advertisers about how many people saw or clicked on their ads.`}</p>
            <br />
            <h4>{`Managing Your Personal Information With Us`}</h4>
            <p>{`You control the personal data you share with us. You can access or rectify this data at any time. You can also delete your account or content. Please note, to help protect your privacy and maintain security, we take steps to verify your identity before granting you access to your personal information or complying with deletion, portability, or other related requests.`}</p>
            <h5>{`Accessing or Rectifying Your Personal Data`}</h5>
            <p>{`If you have registered an account on Potluq, we provide you with tools and profile settings to access, correct, delete, or modify the personal data you provided to us and associated with your account. `}</p>
            <h5>{`Deletion`}</h5>
            <p>{`We keep your profile and content data at least as long as you maintain an account. When you delete content or your profile, we may maintain backups of the corresponding data for up to 90 days. `}</p>
            <h5>{`Additional Information or Assistance`}</h5>
            <p>{`If you have additional questions about our privacy practices or Services generally you can contact us `}<Link to="/help">here.</Link></p>
            <br />
            <h4>{`Children and Our Services`}</h4>
            <p>{`Our services are not directed to children, and you may not use our services if you are under the age of 13. You must also be old enough to consent to the processing of your personal data in your country (in some countries we may allow your parent or guardian to do so on your behalf). `}</p>
            <br />
            <br />
            <br />
        </div>
    );
  }
}

Privacy.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {  }
)(Privacy);
