import {
    BOOK_LOADING,
  // ADD_BOOK,
  GET_BOOK,
  // SAVE_RECIPE_TO_BOOK,
  // REMOVE_RECIPE_FROM_BOOK,
  // DELETE_BOOK,
  CLEAR_CURRENT_BOOK
  } from '../actions/types';
  
  const initialState = {
    book: null,
    loading: false
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case BOOK_LOADING:
        return {
          ...state,
          loading: true
        };
      case GET_BOOK:
        return {
          ...state,
          book: action.payload,
          loading: false
        };
      case CLEAR_CURRENT_BOOK:
        return {
          ...state,
          book: null
        };
      default:
        return state;
    }
  }
  