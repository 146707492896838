import {
  GET_RECIPE,
  GET_RECIPES,
  RECIPE_LOADING,
  CLEAR_CURRENT_RECIPE,
  CLEAR_RECIPES,
  SEARCH_RECIPES,
  ADD_RECIPEFEED_AD
} from '../actions/types';

const initialState = {
  recipe: null,
  recipes: null,
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case RECIPE_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_RECIPE:
      return {
        ...state,
        recipe: action.payload,
        loading: false
      };
    case GET_RECIPES:
      if (state.recipes !== null) {
        return {
          ...state,
          recipes: state.recipes.concat(action.payload),
          loading: false
        };
      } else {
        return {
          ...state,
          recipes: action.payload,
          loading: false
        };
      }
    
    case ADD_RECIPEFEED_AD:
      if (state.recipes !== null) {
        return {
          ...state,
          recipes: state.recipes.concat({
            _id: null,
            name: 'adsense',
            picture: 'https://potluq-recipe-images.s3-us-west-2.amazonaws.com/bread-2389247_1280.png'
          }),
          loading: false
        };
      } else {
        return {
          ...state
        }
      }

    case CLEAR_RECIPES:
      return {
        ...state,
        recipes: null
      };
    case CLEAR_CURRENT_RECIPE:
      return {
        ...state,
        recipe: null
      };
    case SEARCH_RECIPES:
      if (state.recipes !== null) {
        return {
          ...state,
          recipes: state.recipes.concat(action.payload),
          loading: false
        };
      } else {
        return {
          ...state,
          recipes: action.payload,
          loading: false
        };
      }
    default:
      return state;
  }
}
