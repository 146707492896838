import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import isEmpty from '../../validation/is-empty';

class BookItem extends Component {
  render() {
    const { book } = this.props;

    let recipeNumber
    if (book.recipes.length > 1) {
      recipeNumber = (
        <h3 className="card-title mt-2 h6">{book.recipes.length} recipes</h3>
      )
    } else {
      recipeNumber = (
        <h3 className="card-title mt-2 h6">{book.recipes.length} recipe</h3>
      )
    }
    return (
      <div className="card bg-light mb-2 chefCard">
        <div className="card-body bg-light">
          <div className="column m-2 justify-content-center">
            <div className="row-2 justify-content-center">
              {/* <img
                src={profile.user.avatar}
                alt="Profile avatar"
                className="rounded-circle"
              /> */}
            </div>
            <div className="text-capitalize text-center text-break">
                <Link to={`/book/${book._id}`} className="card-title mt-2 h5">{book.title}</Link>
              {/* <h3 className="card-title mt-2">{book.title}</h3> */}
              <p className="card-title mt-2">{book.description}</p>
              {recipeNumber}
              {/* <h6 className="card-title mt-2">{profile.user.name}</h6> */}
              <p className="card-subtitle mb-2">
                {/* {isEmpty(profile.location) ? null : (
                  <span>{profile.location}</span>
                )} */}
              </p>
              {/* <Link
                to={`/profile/${profile.handle}`}
                className="btn tertiary-color-bg justify-content-center"
              >
                View Profile
              </Link> */}
              {/* <Link
                to={`/recipes/${profile.handle}`}
                className="btn tertiary-color-bg justify-content-center"
              >
                Recipes
              </Link> */}
            </div>
            {/* <div className="col-md-4 d-none d-md-block" /> */}
          </div>
        </div>
      </div>
    );
  }
}

BookItem.propTypes = {
  book: PropTypes.object.isRequired
};

export default BookItem;
