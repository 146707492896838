import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { registerUser, loginFB, authFB, loginG } from '../../actions/authActions';
import { createNewProfile, checkHandle } from '../../actions/profileActions';
import { uploadProfileImage } from '../../actions/s3Actions';
import TextFieldGroup from '../common/TextFieldGroup';
// import TextAreaFieldGroup from '../common/TextAreaFieldGroup';
// import InputGroup from '../common/InputGroup';
import AvatarEditor from 'react-avatar-editor';
import {Helmet} from 'react-helmet';
import ReCAPTCHA from "react-google-recaptcha";
//create an account with Facebook option
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

const recaptchaRef = React.createRef();
class Register extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
      email: '',
      password: '',
      password2: '',
      handle: '',
      location: '',
      bio: '',
      homepage: '',
      twitter: '',
      facebook: '',
      pinterest: '',
      youtube: '',
      instagram: '',
      avatar: 'https://potluq-profile-images.s3-us-west-2.amazonaws.com/chefAvatar.png',
      image: 'https://potluq-profile-images.s3-us-west-2.amazonaws.com/chefAvatar.png',
      errors: {},
      width: 800,
      height: 800,
      border: 50,
      color: [255, 255, 255, 0.6],
      scale: 1,
      rotate: 0,
      borderRadius: 400,
      position: { x: 0.5, y: 0.5 },
      imageFileName: '',
      recaptchaResponse: 'robot',
      thirdPartyCookieError: false,
      submittable: true
    };
    this.profilePicPromise = this.profilePicPromise.bind(this);
    this.triggerUpload = this.triggerUpload.bind(this);
    this.handleCaptchaResponseChange = this.handleCaptchaResponseChange.bind(this);
    this.googleResponse = this.googleResponse.bind(this);
    this.googleFailure = this.googleFailure.bind(this);
  }

  componentDidMount() {
    console.log('window href is ' + window.location.href)
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/yourrecipes');
    }
    window.gapi.load('auth2', () => {
      this.auth2 = window.gapi.auth2.init({
        client_id: '641523247436-b7r52kdjjor49teclaiq2bm44aavucib.apps.googleusercontent.com',
        scope: 'profile email'
      })
      this.attachSignin(document.getElementById('customBtn'));
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push('/yourrecipes');
    }
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors, submittable: true });
    }
  }

  facebookResponse = response =>  {
    // console.log(response);
    // console.log('sending FB data to server for login');
    let token = response.accessToken;
    this.props.authFB(token);
    this.props.loginFB(response);
  };
  attachSignin(element) {
    this.auth2.attachClickHandler(element, {}, this.googleResponse, this.googleFailure);
  }
  googleResponse = response => {
    // console.log('success googleResponse')
    // console.log(response)
    let token = response.uc.access_token;
    // console.log('token is ' + token)
    this.props.authFB(token);
    this.props.loginG(response);
  }
  googleFailure = response => {
    console.log(`google authentication failed`)
    console.log(response.error)
    console.log(response.details)
    if (response.error === 'idpiframe_initialization_failed') {
      console.log('cookies are disabled in this browser')
      this.setState({thirdPartyCookieError: true})
    }
  }
  
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    //check if user started to upload a picture, if so call handleSave before uploading
    this.setState({submittable: false})
    let nameString = this.state.name.substring(0,4);
    let random = Math.random().toString(36).substr(2, 6);
    let newHandle = `${nameString}${random}`

    const newUser = {
      name: this.state.name,
      email: this.state.email,
      avatar: this.state.avatar,
      password: this.state.password,
      password2: this.state.password2,
      recaptcha: this.state.recaptchaResponse
    };
    const newProfile = {
      userid: '',
      userName: this.state.name,
      handle: newHandle,
      bio: this.state.bio,
      homepage: this.state.homepage,
      twitter: this.state.twitter,
      facebook: this.state.facebook,
      pinterest: this.state.pinterest,
      youtube: this.state.youtube,
      instagram: this.state.instagram
    };
    if (this.state.image !== 'https://potluq-profile-images.s3-us-west-2.amazonaws.com/chefAvatar.png') {

      let picPromise = new Promise((resolve, reject) => {
        this
          .profilePicPromise()
          .then(imageURL => {
            // console.log('profile pic should be set to ' + imageURL);
            this.setState({ avatar: imageURL });
            newUser.avatar = imageURL;
            resolve();
          })
          .catch(err => {
            console.log('photo upload broke');
            this.setState({ errors: err, submittable: true });
          });
      });

      picPromise.then(() => {
          let handlePromise = new Promise((resolve, reject) => {
            // console.log('checking handle ' + newProfile.handle)
            this.props
            //first check whether handle is available
              .checkHandle(newProfile.handle)
              .then(res => {
                if (res.ok) {
                  console.log('checkHandle returned ' + res.ok)
                }
                console.log('checkHandle triggered, returned: ' + res);
                this.setState({ errors: {}});
                resolve(res);
              })
              .catch(err => {
                console.log('the handle was taken or invalid');
                this.setState({ errors: err, submittable: true });
              });
            })
      
          handlePromise.then(() => {
            let submitPromise = new Promise((resolve, reject) => {
              this.props
                .registerUser(newUser, this.props.history)
                .then(res => {
                  // console.log('new user registered')
                  // console.log(res)
                  resolve(res);
                })
                .catch(err => {
                  this.setState({ errors: err, submittable: true });
                  //need to reset recaptcha
                  console.log('should be resetting recaptcha')
                  recaptchaRef.current.reset();
                  console.log('error creating user ' + err)
                  reject();
                });
            });
            submitPromise.then(
              //create profile and then push user to login page
              user => {
                console.log('user returned from innerpromise as _id' + user.data._id);
                console.log('user returned from innerpromise as id' + user.data.id);
                newProfile.userid = user.data._id;
                this.props.createNewProfile(newProfile, this.props.history);
              }
            ).catch(err => {
              this.setState({ errors: err, submittable: true });
              console.log('testing catching errors')});;
          }).then();
        
        
      })

      
        
    } else {
      let handlePromise = new Promise((resolve, reject) => {
        console.log('checking handle ' + newProfile.handle)
        this.props
        //first check whether handle is available
          .checkHandle(newProfile.handle)
          .then(res => {
            console.log('checkHandle triggered, returned: ' + res);
            this.setState({ errors: {} });
            resolve();
          })
          .catch(err => {
            console.log('the handle was taken');
            this.setState({ errors: err, submittable: true });
          });
      });
  
      handlePromise.then(() => {
        let submitPromise = new Promise((resolve, reject) => {
          this.props
            .registerUser(newUser, this.props.history)
            .then(res => {
              resolve(res);
            })
            .catch(err => {
              this.setState({ errors: err, submittable: true });
              console.log('should be resetting recaptcha')
              recaptchaRef.current.reset();
              console.log('testing catching errors')});
        });
        submitPromise.then(
          //create profile and then push user to login page
          user => {
            console.log('user returned from innerpromise as ' + user.data._id);
            newProfile.userid = user.data._id;
            this.props.createNewProfile(newProfile, this.props.history);
          }
        ).catch(err => {
          this.setState({ errors: err, submittable: true });
          console.log('testing catching errors')});;
      }).catch(err=> {
        this.setState({ errors: err, submittable: true });
        console.log('testing catching errors');
      });
    }
    // .then(() => console.log('registered user'));
  };

  async uploadPromiseParent(e) {
    // console.log('component mounted with ' + e);
    let promise = new Promise((resolve, reject) => {
      let smallImage = null;
      let width = 1080;
      let height = 1080;
      let scaleFactor;
      const fileName = e.target.files[0].name;
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      let resizePromise = new Promise((resolve, reject) => {
        // if (e.target.files[0].type != gif)
        if (e.target.files[0].type === 'image/gif') {
          resolve(e.target.files[0]);
        } else {
          console.log(e.target.files[0].type);
          reader.onload = event => {
            const img = new Image();
            img.src = event.target.result;
            img.onload = () => {
              scaleFactor = width / img.width;
              height = img.height * scaleFactor;

              const elem = document.createElement('canvas');
              elem.width = width;
              elem.height = height;
              const ctx = elem.getContext('2d');
              // img.width and img.height will contain the original dimensions
              ctx.drawImage(img, 0, 0, width, height);

              let innerPromise = new Promise((resolve, reject) => {
                ctx.canvas.toBlob(function(blob) {
                  let semaphore = new File([blob], fileName, {
                    type: 'image/jpeg',
                    lastModified: Date.now()
                  });
                  resolve(semaphore);
                });
              });

              innerPromise.then(semaphore => {
                console.log('innerPromise resolved');
                smallImage = semaphore;
                console.log(smallImage);
                resolve(smallImage);
              });
            };
          };
        }
      });
      reader.onerror = error => console.log(error);
      resizePromise.then(semaphore => {
        let finalPromise = new Promise((resolve, reject) => {
          // console.log('final promise, upload called with ' + semaphore);
          const { user } = this.props.auth;
          const pictureStateTarget = this;
          this.props.uploadProfileImage(user, semaphore, pictureStateTarget);
          this.setState({
            avatar:
              'https://potluq-recipe-images.s3-us-west-2.amazonaws.com/images/831-2.gif'
          });
          // console.log('should have uploaded');
        });
        finalPromise.then(resolve());
      });
    });
    promise.then(() => console.log('this one resolves last'));
  }

  triggerUpload(e) {
    this.uploadPromiseParent(e);
  }

  //new functions just for cropping functionality
  handleScale(e){
    this.setState({...this.state,
      scale: e.target.value
    })
  }

  rotateRight = e => {
    e.preventDefault()
    this.setState({
      rotate: this.state.rotate + 90,
    })
  }
  handlePositionChange = position => {
      this.setState({ position })
  }
  handleNewImage = e => {
    this.setState({ image: e.target.files[0], 
      imageFileName: e.target.files[0].name })
  }

  setEditorRef = editor => {
    if (editor) this.editor = editor
  }

  async profilePicPromise() {
    return new Promise((resolve, reject) => {
      console.log('called profilePicPromise')
  // profilePicPromise = new Promise((resolve, reject) => {
    let promise = new Promise((resolve, reject) => {
    if (this.state.image !== 'https://potluq-profile-images.s3-us-west-2.amazonaws.com/chefAvatar.png') {
      console.log('detected photo needs uploading')
      let innerPromise = new Promise((resolve, reject) => {
        let fileName = this.state.imageFileName
        //old get photo from file selector
        this.editor.getImageScaledToCanvas().toBlob(function(blob) {
          let semaphore = new File([blob], fileName, {
            type: 'image/jpeg',
            lastModified: Date.now()
          });
          resolve(semaphore);
              })
      });
      innerPromise.then(semaphore => {
        const { user } = this.props.auth;
        const pictureStateTarget = this;
        this.setState({
          avatar:
            'https://potluq-recipe-images.s3-us-west-2.amazonaws.com/images/831-2.gif'
        });
        // console.log('uploading profile pic')
        this.props.uploadProfileImage(user, semaphore, pictureStateTarget)
        .then(imageURL=>{
          console.log('received imageURL from uploadProfileImage: ' + imageURL)
          this.setState({avatar: imageURL})
          resolve(imageURL)})
          .catch(err => {
            console.log('upload image error: ' + err)
          });
      }).catch(err=>{
        console.log('promise error ' + err);
      });

    } else {
      console.log('havent uploaded an image yet')
      resolve();  
    }
    }
    )
    promise.then(imageURL => {
      resolve(imageURL);
    })
  })
  }

  handleSave() {
    if (this.state.image !== 'https://potluq-profile-images.s3-us-west-2.amazonaws.com/chefAvatar.png') {
      let innerPromise = new Promise((resolve, reject) => {
        let fileName = this.state.imageFileName
        this.editor.getImageScaledToCanvas().toBlob(function(blob) {
          let semaphore = new File([blob], fileName, {
            type: 'image/jpeg',
            lastModified: Date.now()
          });
          resolve(semaphore);
        })
      });
      innerPromise.then(semaphore => {
        const { user } = this.props.auth;
        const pictureStateTarget = this;
        this.props.uploadProfileImage(user, semaphore, pictureStateTarget);
        this.setState({
              avatar:
                'https://potluq-recipe-images.s3-us-west-2.amazonaws.com/images/831-2.gif'
            });
      })
    } else {console.log('havent uploaded an image yet')}
  }

  handleCaptchaResponseChange(response) {
    this.setState({
      recaptchaResponse: response,
    });
  }
  
  render() {
    // const { errors, displaySocialInputs } = this.state;
    const { errors } = this.state;
    
    // let socialInputs;
    let imageCropper;
    let imageLoading;
    let inputButton;
    let helmet = (
      <Helmet>
        <title>join potluq</title>
      </Helmet>
    )
    
    let recaptcha = (
      <div className="d-flex justify-content-center">
        <ReCAPTCHA
        ref={recaptchaRef}
        sitekey="6LeBmswUAAAAAIew45QQ-4CbmBD0lMiQfOKecoi8"
        onChange={this.handleCaptchaResponseChange}
        />
      </div>
    )
    
    let facebookLoginButton = (
      <FacebookLogin
        appId={'626800344762698'}
        autoLoad={false}
        fields="name,email,picture.width(720).height(720).redirect(0)"
        callback={this.facebookResponse} 
        redirectUri={window.location.href}
        onclick={this.componentClicked}
        render={renderProps => (
          <button onClick={renderProps.onClick} className="btn btn-block facebook-blue-bg text-light mt-4 mr-2"><i className="fab fa-facebook-square mr-2" />   use your Facebook account</button>
      )}/>
    )
    let googleLoginButton = (
      <div>
        <button id="customBtn" className="btn btn-block google-green-bg text-light mt-2 mr-2"><i className="fab fa-google mr-2" /> use your Google account</button>
        {/* <div id="customBtn" className="btn google-green-bg text-light mt-4 mr-2">
          <span className="icon"></span>
          <span className="buttonText"><i className="fab fa-google" /> use your Google account</span>
        </div> */}
      </div>
    )

    if (this.state.image === 'https://potluq-profile-images.s3-us-west-2.amazonaws.com/chefAvatar.png') {
      imageCropper = (
        <div className="text-center">
          {'upload a profile pic if you like'}  
          <br></br>
          <AvatarEditor
          ref={this.setEditorRef}
          image={this.state.image}
          width={this.state.width}
          height={this.state.height}
          border={this.state.border}
          color={this.state.color} // RGBA
          scale={this.state.scale}
          rotate={this.state.rotate}
          position={this.state.position}
          onPositionChange={this.handlePositionChange}
          borderRadius={this.state.borderRadius}
          className="editor-canvas"
          style={{width:'200px', height:'200px'}}
        />
        <br />
        <input name="newImage" type="file" onChange={this.handleNewImage} className="mb-2 "/>
          
         </div>)
    } else {
      imageCropper = (
        <div className="text-center">
          {'upload a profile pic if you like'}  
          <br />
          <AvatarEditor
          ref={this.setEditorRef}
          image={this.state.image}
          width={this.state.width}
          height={this.state.height}
          border={this.state.border}
          color={this.state.color} // RGBA
          scale={this.state.scale}
          position={this.state.position}
          onPositionChange={this.handlePositionChange}
          rotate={this.state.rotate}
          borderRadius={this.state.borderRadius}
          className="editor-canvas"
          style={{width:'200px', height:'200px'}}
        />
        <br />
        
          <div>
            <div className="my-1">
            zoom
          <input type="range" step="0.01" min="1" max="3" name="scale" value={this.state.scale} onChange={this.onChange}></input>
          </div>
          <div className="my-1">
            <button onClick={this.rotateRight} className="btn tertiary-color-bg">Rotate</button>
          </div>
          <input name="newImage" type="file" onChange={this.handleNewImage} className="mb-2"/>
          
          {/* <input className="my-1" type="button" onClick={this.handleSave} value="Set profile pic" />  */}
          </div>
        
        </div>
      )
    }
    if (this.state.avatar === 'https://potluq-recipe-images.s3-us-west-2.amazonaws.com/images/831-2.gif') {
      imageLoading = (
        <div className="d-flex justify-content-center">
          <i class="fas fa-sync fa-spin"></i>
        </div>
      )
    } else if (this.state.avatar !== 'https://potluq-profile-images.s3-us-west-2.amazonaws.com/chefAvatar.png' ) {
      imageLoading = (
      <div className="d-flex justify-content-center">
          <i className="fas fa-check"></i>
        </div>
      )
    } else {
      imageLoading = (
      <div></div>
      )
    }
    
    if (this.state.recaptchaResponse !== 'robot' && this.state.submittable) {
      inputButton = (
        <input
          type="submit"
          className="btn secondary-color-bg text-light btn-block mt-4 col-md-2"
        // >get started</input>
          value="get started"
        />
      )
    }
    let TOStext = (
      <div className="text-center">
        <p>by creating an account, you agree to our <a href="https://www.potluq.recipes/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a> and <a href="https://www.potluq.recipes/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></p>
      </div>
    )
    return (
      <div className="register">
        {helmet}
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
            {/* <div className="d-flex btn-group btn-group-justified"> */}
            <div className="">
                {facebookLoginButton}
                {googleLoginButton}
              </div>
              <p className="lead text-center">
                or <br/>
                create an account to join the potluq
              </p>
              <form noValidate onSubmit={this.onSubmit}>
                <TextFieldGroup
                  placeholder="name"
                  name="name"
                  value={this.state.name}
                  onChange={this.onChange}
                  error={errors.name}
                />

                <TextFieldGroup
                  placeholder="e-mail"
                  name="email"
                  type="email"
                  value={this.state.email}
                  onChange={this.onChange}
                  error={errors.email}
                />
                <TextFieldGroup
                  placeholder="password"
                  name="password"
                  type="password"
                  value={this.state.password}
                  onChange={this.onChange}
                  error={errors.password}
                />
                <TextFieldGroup
                  placeholder="verify password"
                  name="password2"
                  type="password"
                  value={this.state.password2}
                  onChange={this.onChange}
                  error={errors.password2}
                />
                {imageCropper}
                {recaptcha}
                {imageLoading}
                {inputButton}
                {TOStext}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  loginG: PropTypes.func.isRequired,
  loginFB: PropTypes.func.isRequired,
  authFB: PropTypes.func.isRequired,
  uploadProfileImage: PropTypes.func.isRequired,
  checkHandle: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { registerUser, createNewProfile, checkHandle, uploadProfileImage, loginFB, authFB, loginG }
)(withRouter(Register));
