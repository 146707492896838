import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class RecipeItem extends Component {

  componentDidMount() {
    if (this.props.recipe.name === 'adsense') {
      (window.adsbygoogle = window.adsbygoogle || []).push({})
    }
  }

  render() {
    const { recipe } = this.props;
    let privateRecipeIcon;
    let chefHandle;
    let recipeLikes;
    let allUpContainer;
    if (recipe.privateFlag) {
      privateRecipeIcon = <p>Private</p>;
    } else {
      privateRecipeIcon = <div className=""></div>;
    }
    if (recipe.likes) {
      if (recipe.likes.length > 0) {
        if (recipe.likes.length === 1) {
          recipeLikes = (
            <p  className="m-1"><i className="fas fa-heart fa-sm m-2"/>{recipe.likes.length}</p>
          )
        } else {
          recipeLikes = (
            <p className="m-1"><i className="fas fa-heart fa-sm m-2"/>{recipe.likes.length}</p>
          )
        }
        
      }
      
    }
    if (recipe.chef) {
      if (recipe.avatar) {
        chefHandle = (
          <div className="row m-1 mt-0">
              <Link to={`/chef/${recipe.handle}`}>{recipe.chef}</Link>
              <img 
                className="rounded-circle ml-1 mt-0"
                src={recipe.avatar}
                link={`/chef/${recipe.handle}`}
                alt={"chef"}
                style={{ width: 30, height: 30 }}
                />
          </div>
        )
      } else {
        chefHandle = (
          <div className="m-1 mt-0">
              <Link to={`/chef/${recipe.handle}`}>{recipe.chef}</Link>
          </div>
        )
      }
    }
    if (recipe.name === 'adsense') {
      allUpContainer = (
        // <ins className='adsbygoogle'
        //   style={{ display: 'block' }}
        //   data-ad-client='ca-pub-9458878569734377'
        //   data-ad-slot='7322415386'
        //   data-ad-format="rectangle,horizontal,auto"
        //   data-full-width-responsive="true" />

        <div className="justify-content-center adcontainer">
          <ins className="adsbygoogle"
          style={{display:'inline-block',width:'155px',height:'175px'}}
          data-ad-client="ca-pub-9458878569734377"
          data-ad-slot="9641650489" />
          <p className="m-0 text-center">sponsored</p>
        </div>
          
      )
    } else {
      allUpContainer = (
        <div className="column  justify-content-center">
            <div className="row-2 justify-content-center">
            <Link to={`/recipe/${recipe._id}`}><img
                src={recipe.picture}
                link={`/recipe/${recipe._id}`}
                alt="Recipe result"
              /></Link>
            </div>
            <div className="text-capitalize text-center mb-0 m-1 mb-0">
              <Link to={`/recipe/${recipe._id}`}>{recipe.name}</Link>
              <p className="card-subtitle">
              </p>
              {privateRecipeIcon}
            </div>
            <div className="row justify-content-center">
            {chefHandle}{recipeLikes}
            </div>
            
          </div>
      )
    }
    return (
      <div className="card bg-light mb-2 chefCard">
        <div className=" bg-light m-0">
          {allUpContainer}
        </div>
      </div>
    );
  }
}

RecipeItem.propTypes = {
  recipe: PropTypes.object.isRequired
};

export default RecipeItem;
