import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../common/Spinner';
import { getUserBooks } from '../../actions/bookActions';
import BookItem from './BookItem';
import { Helmet } from 'react-helmet';
import ReactPixel from 'react-facebook-pixel';

class LibraryView extends Component {
  componentDidMount() {
      if (this.props.profile.myProfile) {
          console.log('calling proper getUserBooks')
          this.props.getUserBooks(this.props.profile.myProfile._id);
      } else {
          console.log('calling getUserBooks without userId')
          this.props.getUserBooks()
      }
      ReactPixel.trackCustom('ViewLibrary')
    
  }
  
  render() {
    const { book, loading } = this.props.book;
    let bookItems;
    let helmet = (
      <Helmet>
        <title>recipe books</title>
      </Helmet>
    )
    let libraryContainer
    if (book === null || loading) {
        bookItems = <Spinner />;
        libraryContainer = (
          <div>
            {bookItems}
          </div>
        )
      } else {
        if (book.length > 0) {
            bookItems = book.map(aBook => (
                <BookItem key={aBook._id} book={aBook} />
            ));
            libraryContainer = (
              <div className="chefContainer justify-content-around align-content-center mb-4">
              {bookItems}
            </div>
            )
            } else {
            bookItems = (
            <div>
              <h4 className="text-center">you don't have any recipe books yet!</h4>
              <p className="text-center">when you find a recipe you like, hit the bookmark icon <i className="fas fa-bookmark" /> to add it to favorites or create a new book</p>
            </div>
            );
            libraryContainer = (
              <div className="mt-3">
                {bookItems}
              </div>
            )
            }
      }
    
    return (
      <div className="container mt-2">
        {helmet}
        <div className="row">
          <div className="col-md-12">
            {/* <h1 className="display-10 text-center">your recipe books</h1> */}
            {/* <p className="lead text-center m-1">
              save and organize recipes in books
            </p> */}
            {/* <div className="chefContainer">{bookItems}</div> */}
            {libraryContainer}
          </div>
        </div>
      </div>
    );
  }
}

LibraryView.propTypes = {
  getUserBooks: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile,
  book: state.book
});

export default connect(
  mapStateToProps,
  { getUserBooks }
)(LibraryView);
