import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateUserInfo } from '../../actions/authActions';
import { checkHandle, editProfile, getCurrentProfile, deleteAccount } from '../../actions/profileActions';
import { uploadProfileImage } from '../../actions/s3Actions';
import TextFieldGroup from '../common/TextFieldGroup';
import TextAreaFieldGroup from '../common/TextAreaFieldGroup';
import InputGroup from '../common/InputGroup';
import isEmpty from '../../validation/is-empty';
import AvatarEditor from 'react-avatar-editor';
import {Helmet} from 'react-helmet';

class EditUserAndProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
        name: this.props.auth.user.name,
        userName: this.props.auth.user.name,
        email: this.props.auth.user.email,
        avatar: this.props.auth.user.avatar,
        handle: '',
        location: '',
        bio: '',
        homepage: '',
        twitter: '',
        facebook: '',
        pinterest: '',
        youtube: '',
        instagram: '',
        image: this.props.auth.user.avatar,
        errors: {},
        width: 800,
        height: 800,
        border: 50,
        color: [255, 255, 255, 0.6],
        scale: 1,
        rotate: 0,
        borderRadius: 400,
        position: { x: 0.5, y: 0.5 },
        imageFileName: '',
        originalAvatar: '',
        saveNeeded: false
    };
    this.profilePicPromise = this.profilePicPromise.bind(this);
    // this.triggerUpload = this.triggerUpload.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.props.getCurrentProfile();
    this.setState({originalAvatar: this.props.auth.user.avatar})
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
        this.setState({ errors: nextProps.errors });
      }
  
      if (nextProps.profile.myProfile) {
        const profile = nextProps.profile.myProfile;
  
        //if profile field doesn't exist, make an empty string for the respective value
        profile.location = !isEmpty(profile.location) ? profile.location : '';
        profile.bio = !isEmpty(profile.bio) ? profile.bio : '';
        profile.social = !isEmpty(profile.social) ? profile.social : {};
        profile.homepage = !isEmpty(profile.social.homepage)
          ? profile.social.homepage
          : '';
        profile.twitter = !isEmpty(profile.social.twitter)
          ? profile.social.twitter
          : '';
        profile.facebook = !isEmpty(profile.social.facebook)
          ? profile.social.facebook
          : '';
        profile.pinterest = !isEmpty(profile.social.pinterest)
          ? profile.social.pinterest
          : '';
        profile.youtube = !isEmpty(profile.social.youtube)
          ? profile.social.youtube
          : '';
        profile.instagram = !isEmpty(profile.social.instagram)
          ? profile.social.instagram
          : '';
  
        //set component fields state
        this.setState({
          handle: profile.handle,
          location: profile.location,
          bio: profile.bio,
          homepage: profile.homepage,
          twitter: profile.twitter,
          facebook: profile.facebook,
          pinterest: profile.pinterest,
          youtube: profile.youtube,
          instagram: profile.instagram
        });
      }
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const updatedUserData = {
        name: this.state.name,
        email: this.state.email,
        avatar: this.state.avatar
      };
    // this.props.updateUserInfo(updatedUserData, this.props.history);
    const updatedProfile = {
      userName: this.state.name,
      handle: this.state.handle,
      location: this.state.location,
      bio: this.state.bio,
      homepage: this.state.homepage,
      twitter: this.state.twitter,
      facebook: this.state.facebook,
      pinterest: this.state.pinterest,
      youtube: this.state.youtube,
      instagram: this.state.instagram
    };
    if (this.state.saveNeeded) {
      //need to upload profile pic before saving
      let handlePromise = new Promise((resolve, reject) => {
        if (updatedProfile.handle !== this.props.profile.myProfile.handle) {
            console.log('handle looks changed, checking availability')
            this.props
            //first check whether handle is available
              .checkHandle(updatedProfile.handle)
              .then(res => {
                console.log('checkHandle triggered, returned: ' + res);
                this.setState({ errors: {} });
                resolve();
              })
              .catch(err => {
                console.log('the handle was taken');
                this.setState({ errors: err });
              });
        } else {
            console.log('resolving handlePromise without checking because it shouldnt be new')
            resolve();
        }
    });

    handlePromise.then(() => {
      let photoPromise = new Promise((resolve, reject) => {
        // console.log('calling profilepicpromise')
        this
        //   .updateUserInfo(updatedUserData, this.props.history)
          .profilePicPromise()
          .then(imageURL => {
            this.setState({ avatar: imageURL });
            updatedUserData.avatar = imageURL;
            resolve(imageURL);
          })
          .catch(() => console.log('testing catching errors'));
      });
      photoPromise.then(() => {
        let submitPromise = new Promise((resolve, reject) => {
          // console.log('update user info with ')
          // console.log('update user info with ' + updatedUserData)
          // resolve();
          this.props
          //   .updateUserInfo(updatedUserData, this.props.history)
            .updateUserInfo(updatedUserData)
            .then(res => {
              resolve(res);
            })
            .catch(() => console.log('testing catching errors'));
        });
        submitPromise.then(
          //create profile and then push user to login page
          res => {
              // console.log('now update profile with ' + updatedProfile);
              // console.log(updatedProfile);
            this.props.editProfile(updatedProfile, this.props.history);
          //   this.props.editProfile(updatedProfile);
          }
        );
      }
      );
    })
    } else {
      //upload without saving profile pic first
      let handlePromise = new Promise((resolve, reject) => {
        if (updatedProfile.handle !== this.props.profile.myProfile.handle) {
            console.log('handle looks changed, checking availability')
            this.props
            //first check whether handle is available
              .checkHandle(updatedProfile.handle)
              .then(res => {
                console.log('checkHandle triggered, returned: ' + res);
                this.setState({ errors: {} });
                resolve();
              })
              .catch(err => {
                console.log('the handle was taken');
                this.setState({ errors: err });
              });
        } else {
            console.log('resolving handlePromise without checking because it shouldnt be new')
            resolve();
        }
    });

    handlePromise.then(() => {

        let submitPromise = new Promise((resolve, reject) => {
          // console.log('update user info with ')
          console.log('update user info with ' + updatedUserData)
          // resolve();
          this.props
          //   .updateUserInfo(updatedUserData, this.props.history)
            .updateUserInfo(updatedUserData)
            .then(res => {
              resolve(res);
            })
            .catch(() => console.log('testing catching errors'));
        });
        submitPromise.then(
          //create profile and then push user to login page
          res => {
              console.log('now update profile with ' + updatedProfile);
              // console.log(updatedProfile);
            this.props.editProfile(updatedProfile, this.props.history);
          //   this.props.editProfile(updatedProfile);
          }
        );

    })
    }
    

    

    // handlePromise.then(() => {
    //   let submitPromise = new Promise((resolve, reject) => {
    //     // console.log('update user info with ')
    //     console.log('update user info with ' + updatedUserData)
    //     // resolve();
    //     this.props
    //     //   .updateUserInfo(updatedUserData, this.props.history)
    //       .updateUserInfo(updatedUserData)
    //       .then(res => {
    //         resolve(res);
    //       })
    //       .catch(() => console.log('testing catching errors'));
    //   });
    //   submitPromise.then(
    //     //create profile and then push user to login page
    //     res => {
    //         console.log('now update profile with ' + updatedProfile);
    //         // console.log(updatedProfile);
    //       this.props.editProfile(updatedProfile, this.props.history);
    //     //   this.props.editProfile(updatedProfile);
    //     }
    //   );
    // }).then(console.log('called all the promises'));
  };

  // async uploadPromiseParent(e) {
  //   console.log('component mounted with ' + e);
  //   let promise = new Promise((resolve, reject) => {
  //     let smallImage = null;
  //     let width = 800;
  //     let height = 800;
  //     let scaleFactor;
  //     const fileName = e.target.files[0].name;
  //     const reader = new FileReader();
  //     reader.readAsDataURL(e.target.files[0]);
  //     let resizePromise = new Promise((resolve, reject) => {
  //       // if (e.target.files[0].type != gif)
  //       if (e.target.files[0].type === 'image/gif') {
  //         resolve(e.target.files[0]);
  //       } else {
  //         console.log(e.target.files[0].type);
  //         reader.onload = event => {
  //           const img = new Image();
  //           img.src = event.target.result;
  //           img.onload = () => {
  //             scaleFactor = width / img.width;
  //             height = img.height * scaleFactor;

  //             const elem = document.createElement('canvas');
  //             elem.width = width;
  //             elem.height = height;
  //             const ctx = elem.getContext('2d');
  //             // img.width and img.height will contain the original dimensions
  //             ctx.drawImage(img, 0, 0, width, height);

  //             let innerPromise = new Promise((resolve, reject) => {
  //               ctx.canvas.toBlob(function(blob) {
  //                 let semaphore = new File([blob], fileName, {
  //                   type: 'image/jpeg',
  //                   lastModified: Date.now()
  //                 });
  //                 resolve(semaphore);
  //               });
  //             });

  //             innerPromise.then(semaphore => {
  //               console.log('innerPromise resolved');
  //               smallImage = semaphore;
  //               console.log(smallImage);
  //               resolve(smallImage);
  //             });
  //           };
  //         };
  //       }
  //     });
  //     reader.onerror = error => console.log(error);
  //     resizePromise.then(semaphore => {
  //       let finalPromise = new Promise((resolve, reject) => {
  //         // console.log('final promise, upload called with ' + semaphore);
  //         const { user } = this.props.auth;
  //         const pictureStateTarget = this;
  //         this.props.uploadProfileImage(user, semaphore, pictureStateTarget);
  //         this.setState({
  //           avatar:
  //             'https://potluq-recipe-images.s3-us-west-2.amazonaws.com/images/831-2.gif'
  //         });
  //         // console.log('should have uploaded');
  //       });
  //       finalPromise.then(resolve());
  //     });
  //   });
  //   promise.then(() => console.log('this one resolves last'));
  // }

  onDeleteClick(e) {
    this.props.deleteAccount();
  }

  // triggerUpload(e) {
  //   this.uploadPromiseParent(e);
  // }

  //new functions just for cropping functionality
  handleScale(e){
    this.setState({...this.state,
      scale: e.target.value,
      saveNeeded: true
    })
  }

  rotateRight = e => {
    e.preventDefault()
    this.setState({
      rotate: this.state.rotate + 90,
      saveNeeded: true
    })
  }

  handleNewImage = e => {
    this.setState({ image: e.target.files[0], 
      imageFileName: e.target.files[0].name,
      saveNeeded: true})
  }

  setEditorRef = editor => {
    if (editor) this.editor = editor
  }
  handlePositionChange = position => {
    // if (this.state.rotate === 90) {
    //   // console.log ('handling position like rotation 90')
    //   console.log('x:' + position.x + ' y: ' + position.y)
    //   this.setState({ position: {x: position.y, y: -position.x} })
    //   // position: { x: 0.5, y: 0.5 },
    // } else if (this.state.rotate === 180) {
    //   // console.log ('handling position like rotation 180')
    //   this.setState({ position: {x: -position.x, y: -position.y} })
    // } else if (this.state.rotate === 270) {
    //   // console.log ('handling position like rotation 270')
    //   this.setState({ position: {x: -position.y, y: position.x} })
    // } else {
      // console.log ('handling position like no')
      this.setState({ position })
    // }
  }

  async profilePicPromise() {
    return new Promise((resolve, reject) => {
      // console.log('called profilepicpromise')
  // profilePicPromise = new Promise((resolve, reject) => {
    let promise = new Promise((resolve, reject) => {
      const { user } = this.props.auth;
      console.log('user is ' + user);
      console.log('user ID is ' + user.id);
      console.log('user name is ' + user.name);
    if (this.state.image !== this.props.auth.user.avatar) {
      // old innerPromise - lacks resizing //
      let innerPromise = new Promise((resolve, reject) => {
        let fileName = this.state.imageFileName
        this.editor.getImageScaledToCanvas().toBlob(function(blob) {
          let semaphore = new File([blob], fileName, {
            type: 'image/jpeg',
            lastModified: Date.now()
          });
          resolve(semaphore);
        })
      })
      // let innerPromise = new Promise((resolve, reject) => {
      //   let fileName = this.state.imageFileName
      //   //adding logic to resize image before upload
      //   let width = 1080;
      //   let height = 1080;
      //   let scaleFactor;
      //   const img = this.editor.getImage();
      //   console.log('img should be loaded');
      //   console.log('imgage variable is ' + img)
      //   scaleFactor = width / img.width;
      //   height = img.height * scaleFactor;
      //   const elem = document.createElement('canvas');
      //   elem.width = width;
      //   elem.height = height;
      //   const ctx = elem.getContext('2d');
      //   // img.width and img.height will contain the original dimensions
      //   ctx.drawImage(img, 0, 0, width, height);
      //   console.log('drew image')
      //   ctx.canvas.toBlob(function(blob) {
      //       let semaphore = new File([blob], fileName, {
      //         type: 'image/jpeg',
      //         lastModified: Date.now()
      //       });
      //       console.log('image should be resized now')
      //       resolve(semaphore);
      //     });
      // });
      innerPromise.then(semaphore => {
        // const { user } = this.props.auth;
        // console.log('user is ' + user);
        // console.log('user ID is ' + user.id);
        // console.log('user name is ' + user.name);
        const pictureStateTarget = this;
        this.setState({
          avatar:
            'https://potluq-recipe-images.s3-us-west-2.amazonaws.com/images/831-2.gif'
        });
        // console.log('uploading profile pic')
        this.props.uploadProfileImage(user, semaphore, pictureStateTarget)
        .then(imageURL=>{
          console.log('received imageURL from uploadProfileImage: ' + imageURL)
          this.setState({avatar: imageURL})
          resolve(imageURL)});
      })
    } else {
      console.log('havent uploaded an image yet')
      resolve();  
    }
    }
    )
    promise.then(imageURL => {
      resolve(imageURL);
    })
  })
  }

  handleSave = data => {
    if (this.state.image !== this.props.auth.user.avatar) {
      let innerPromise = new Promise((resolve, reject) => {
        let fileName = this.state.imageFileName
        this.editor.getImageScaledToCanvas().toBlob(function(blob) {
          let semaphore = new File([blob], fileName, {
            type: 'image/jpeg',
            lastModified: Date.now()
          });
          resolve(semaphore);
        })
      });
      innerPromise.then(semaphore => {
        const { user } = this.props.auth;
        const pictureStateTarget = this;
        this.props.uploadProfileImage(user, semaphore, pictureStateTarget);
        this.setState({
              avatar:
                'https://potluq-recipe-images.s3-us-west-2.amazonaws.com/images/831-2.gif'
            });
      })
    } else {console.log('havent uploaded an image yet')}
    
  }

  render() {
    const { errors, displaySocialInputs } = this.state;
    // console.log('user is ' + this.props.auth.user.id)
    let socialInputs;
    let imageCropper;
    let imageLoading;
    let backButton;
    let profileLoading;

    // let photoUpload = (
    //   <div className="recipeImage">
    //     <img src={this.state.avatar} alt="user avatar" />
    //     <input type="file" onChange={this.triggerUpload} />
    //   </div>
    // );

    if (this.props.profile.myProfile !== null) {
      profileLoading=null
    } else {
      profileLoading = (
        <i class="fas fa-sync fa-spin"></i>
      )
    }

    if (this.state.image === this.props.auth.user.avatar) {
      imageCropper = (
        <div className="">
          <AvatarEditor
          ref={this.setEditorRef}
          image={this.state.image}
          width={this.state.width}
          height={this.state.height}
          border={this.state.border}
          color={this.state.color} // RGBA
          scale={this.state.scale}
          rotate={this.state.rotate}
          position={this.state.position}
          onPositionChange={this.handlePositionChange}
          borderRadius={this.state.borderRadius}
          className="editor-canvas"
          style={{width:'200px', height:'200px'}}
        />
         <br />
        {'upload a new profile pic '}
        <input name="newImage" type="file" onChange={this.handleNewImage} className="mb-3" />
         </div>
         )
    } else {
      imageCropper = (
        <div className="">
          <AvatarEditor
          ref={this.setEditorRef}
          image={this.state.image}
          width={this.state.width}
          height={this.state.height}
          border={this.state.border}
          color={this.state.color} // RGBA
          scale={this.state.scale}
          position={this.state.position}
          onPositionChange={this.handlePositionChange}
          rotate={this.state.rotate}
          borderRadius={this.state.borderRadius}
          className="editor-canvas"
          style={{width:'200px', height:'200px'}}
        />
        
          <div>
            <div>
            zoom
          <input type="range" step="0.01" min="1" max="3" name="scale" value={this.state.scale} onChange={this.onChange}></input>
          </div>
          <div>
            <button onClick={this.rotateRight} className="btn tertiary-color-bg">rotate</button>
          </div>
          {/* <button onClick={this.handleSave} className="btn btn-secondary">finalize new pic</button> */}
          {/* <input type="button" onClick={this.handleSave} value="Set profile pic"/>  */}
          </div>
          {'upload a new profile pic '}  
          <input name="newImage" type="file" onChange={this.handleNewImage} />
        
        </div>
      )
    }
     if (this.state.avatar === this.state.originalAvatar) {
      imageLoading = (
        <div>
          {/* <i class="fas fa-sync fa-spin"></i> */}
        </div>
      )
    } else if (this.state.avatar === 'https://potluq-recipe-images.s3-us-west-2.amazonaws.com/images/831-2.gif') {
      imageLoading = (
        <div>
            <i className="fas fa-sync fa-spin"></i> 
          </div>
        )
    } else {
      imageLoading = (
      <div>
        <i className="fas fa-check"> </i>
        <br/> some changes may not stick until you log out and log in again
      </div>
      )
    }

    if (displaySocialInputs) {
      socialInputs = (
        <div>
          <InputGroup
            placeholder="Homepage or blog URL"
            name="homepage"
            icon="fas fa-globe"
            value={this.state.homepage}
            onChange={this.onChange}
            error={errors.homepage}
          />
          <InputGroup
            placeholder="Facebook profile or page URL"
            name="facebook"
            icon="fab fa-facebook"
            value={this.state.facebook}
            onChange={this.onChange}
            error={errors.facebook}
          />
          <InputGroup
            placeholder="pinterest profile URL"
            name="pinterest"
            icon="fab fa-pinterest"
            value={this.state.pinterest}
            onChange={this.onChange}
            error={errors.pinterest}
          />

          <InputGroup
            placeholder="Youtube profile URL"
            name="youtube"
            icon="fab fa-youtube"
            value={this.state.youtube}
            onChange={this.onChange}
            error={errors.youtube}
          />

          <InputGroup
            placeholder="Instagram profile URL"
            name="instagram"
            icon="fab fa-instagram"
            value={this.state.instagram}
            onChange={this.onChange}
            error={errors.instagram}
          />

          <InputGroup
            placeholder="Twitter profile URL"
            name="twitter"
            icon="fab fa-twitter"
            value={this.state.twitter}
            onChange={this.onChange}
            error={errors.twitter}
          />
        </div>
      );
    }
    if (this.props.profile.myProfile !== null) {
      backButton= (
        <div>
            <Link to={`/profile/${this.props.profile.myProfile.handle}`} className="btn tertiary-color-bg text-light float-left">
              <i className="fas fa-angle-double-left fa-lg" />
            </Link>
        </div>
      )
    } else {
      backButton = (
        <div>
          <Link to={`/profiles/`} className="btn tertiary-color-bg text-light float-left">
              <i className="fas fa-angle-double-left fa-lg" />
            </Link>
            
        </div>
      )
    }
    let emailField
    if (this.props.auth.user.socialLogin) {
      if (this.props.auth.user.socialLogin === 'facebook') {
        emailField = (
          <div></div>
        )
      }
    } else {
      emailField = (
        <TextFieldGroup
          placeholder="e-mail"
          name="email"
          type="email"
          value={this.state.email}
          onChange={this.onChange}
          error={errors.email}
        />
      )
    }
    return (
      <div className="register">
      <Helmet>
        <title>update your details</title>
      </Helmet>
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              {/* <h3 className="display-8 text-center">your deets</h3> */}
              <p className="lead text-center">
                update your details
              </p>
              <div className="row d-flex justify-content-between align-items-center mb-3 mx-0">
                {backButton}
                {profileLoading}
                <div>don't forget to save any changes</div>
              </div>
              
              <form noValidate onSubmit={this.onSubmit}>
                <TextFieldGroup
                  placeholder="name"
                  name="name"
                  value={this.state.name}
                  onChange={this.onChange}
                  error={errors.name}
                />

                {emailField}
                {/* <TextFieldGroup
                  className="md-auto"
                  placholder="* Profile Handle"
                  name="handle"
                  value={this.state.handle}
                  onChange={this.onChange}
                  error={errors.handle}
                  info="* a unique handle for your profile URL - your name or nickname"
                /> */}
                {imageCropper}
                {imageLoading}
                <TextAreaFieldGroup
                  placholder="short bio"
                  name="bio"
                  value={this.state.bio}
                  onChange={this.onChange}
                  error={errors.bio}
                  info="tell us a little about yourself"
                />
                <div className="mb-3">
                  <button
                    type="button"
                    onClick={() => {
                      this.setState(prevState => ({
                        displaySocialInputs: !prevState.displaySocialInputs
                      }));
                    }}
                    className="btn tertiary-color-bg text-light"
                  >
                    add social network links
                  </button>
                  <span className="text-muted ml-2">optional</span>
                </div>
                {socialInputs}

                <input
                  type="submit"
                  className="btn secondary-color-bg text-light btn-block mt-4 col-md-2"
                  value="save"
                />
              </form>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div className="d-flex justify-content-center">
        <button
              onClick={this.onDeleteClick.bind(this)}
              className="btn btn-danger mb-1"
            >
              delete my account
            </button>
        </div>
        
      </div>
    );
  }
}

EditUserAndProfile.propTypes = {
  updateUserInfo: PropTypes.func.isRequired,
  uploadProfileImage: PropTypes.func.isRequired,
  checkHandle: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  editProfile: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { updateUserInfo, checkHandle, editProfile, uploadProfileImage, getCurrentProfile, deleteAccount }
)(withRouter(EditUserAndProfile));
