import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { resetPassword } from '../../actions/authActions';
import TextFieldGroup from '../common/TextFieldGroup';
import {Helmet} from 'react-helmet';

class PwdReset extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      newPassword: '',
      newPassword2: '',
      resetToken: '',
      loading: false,
      success: false,
      badToken: false,
      errors: {}
    };
  }

    componentDidMount() {
    if (this.props.match.params.token) {
        this.setState({
            resetToken: this.props.match.params.token
        })
    }
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/allRecipes');
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push('/allRecipes');
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const userData = {
      email: this.state.email,
      newPassword: this.state.newPassword,
      newPassword2: this.state.newPassword2,
      resetToken: this.state.resetToken 
    };
    this.setState({loading: true})
    this.props.resetPassword(userData)
    .then(res=> {
        console.log('got a response ' + res.token)
        if(res.token) {
            this.setState({loading: false})
        }
        
        if (res.status === 200 ) {
            console.log('success! ' + res)
            this.setState({loading: false, success: true})
            setTimeout(this.props.history.push('/login'), 10000)
        }})
    .catch(err=>{
        console.log('got an error')
        this.setState({loading: false})
        if (err.token) {console.log(err.token)}
        console.log('something went wrong ' + err.message)});
  };

  render() {
    const { errors } = this.state;
    let helmet = (
      <Helmet>
        <title>reset your potluq password</title>
      </Helmet>
  )
    let loadingSpinner;
    if (this.state.loading) {
        loadingSpinner = (
          <div className="text-center">
            <i className="fas fa-sync fa-spin"></i>
          </div>
        )
      } else {
        loadingSpinner = (
            <div></div>
          )
      }
     let submitButton
     if (errors.token === 'invalid token') {
         submitButton = (
            <div className="text-center">invalid token, request a new reset link</div>
         )
     } else if (errors.token === 'token expired') {
        submitButton = (
            <div className="text-center">token expired, request a new reset link</div>
         )
     } else {
        submitButton = (
            <input type="submit" className="btn tertiary-color-bg btn-block mt-4" />
         )
     }
    let problemText;
    if (this.state.badToken) {
        problemText = (
            <div className="text-center">token expired, request a new one</div>
        )
    }
    if (errors === 'token expired') {
        problemText = (
            <div>token expired, request a new one</div>
        ) 
    }
    let successText;
    if (this.state.success) {
        successText= (
          <div className="text-center">
            <div>password reset!</div>
            <a href={`/login`} className="btn tertiary-color-bg btn-block mt-4" >sign in</a>
          </div>
        )
      } else {
          successText = (
            <a href={`/requestpasswordreset`} className="btn tertiary-color-bg btn-block mt-4" >request new reset link</a>
          )
      }
    return (
      <div className="login">
        {helmet}
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <h1 className="display-4 text-center">sign in</h1>
              <p className="lead text-center">time for a new password</p>
              <form onSubmit={this.onSubmit}>
                <TextFieldGroup
                  placeholder="Email Address"
                  name="email"
                  type="email"
                  value={this.state.email}
                  onChange={this.onChange}
                  error={errors.email}
                />
                <TextFieldGroup
                  placeholder="new password"
                  name="newPassword"
                  type="password"
                  value={this.state.newPassword}
                  onChange={this.onChange}
                  error={errors.newPassword}
                />
                <TextFieldGroup
                  placeholder="matching new password"
                  name="newPassword2"
                  type="password"
                  value={this.state.newPassword2}
                  onChange={this.onChange}
                  error={errors.newPassword2}
                />
                {submitButton}
              </form>
              {loadingSpinner}
              {problemText}
              {successText}
              
              
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PwdReset.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { resetPassword }
)(PwdReset);
