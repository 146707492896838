import axios from 'axios';

import {
  GET_ERRORS,
  CLEAR_ERRORS
} from './types';

//create outside request
export const createOutsideRequest = (requestData, history) => dispatch => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/requests/opennew', requestData)
        .then(res => 
          {if (history) {history.push('/about')}
          resolve(res)
        }
          )
        .catch(err =>
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data
          })
        );
    })
  };

//create outside request
export const createRequest = (requestData, history) => dispatch => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/requests/new', requestData)
        .then(res => 
          {if (history) {history.push('/about')}
          resolve(res)
        }
          )
        .catch(err =>
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data
          })
        );
    })
  };

//clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};
