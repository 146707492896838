import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Spinner from '../common/Spinner';
import { getProfiles, getCurrentProfile, getFollowingProfiles, searchProfiles, searchFollowingProfiles, clearProfiles } from '../../actions/profileActions';
import ProfileItem from './ProfileItem';
import { Helmet } from 'react-helmet';
import Masonry from 'react-masonry-css';
import { Waypoint } from 'react-waypoint';

class FollowingProfiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchState: false,
      searchQuery: '',
      waitingForProfile: false,
      seen: [],
      canLoadMore: false
    };
  }
  componentDidMount() {
    this.props.clearProfiles();
    //   console.log('FollowingProfiles did mount')
      if (this.props.profile) {
          if (this.props.profile.myProfile) {
              if (this.props.profile.myProfile.following) {
                  let request = {}
                  request.user = this.props.profile.myProfile.following
                  this.props.getFollowingProfiles(request)
              } else {
                  this.props.getCurrentProfile();
              }
          } else {
              this.setState({waitingForProfile: true})
              this.props.getCurrentProfile();
          }
      } else {
          this.setState({waitingForProfile: true});
          this.props.getCurrentProfile();
      }
  }
  handleQueryInputChange = idx => e => {
    this.setState({ searchQuery: e.target.value });
  };
  onSearchSubmit = e => {
    e.preventDefault();
    this.props.clearProfiles();
    if (this.state.searchQuery) {
      let userAndSeen = {}
      userAndSeen.user = this.props.profile.myProfile.following
      this.props.searchFollowingProfiles(this.state.searchQuery, userAndSeen);
      this.setState({ searchState: true });
    }
  };

  resetSearch = () => {
    this.props.clearProfiles();
    this.setState({ searchQuery: '', searchState: false });
    let userAndSeen = {}
    userAndSeen.user = this.props.profile.myProfile.following
    this.props.getFollowingProfiles(userAndSeen);
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (nextProps.profile) {
      if (nextProps.profile.profiles) {
        if (nextProps.profile.profiles.length !== this.state.seen.length) {
          //
        // } else {
          this.setState({canLoadMore: true})
        }
      } 
    }
    if (nextProps.profile && this.state.waitingForProfile) {
        // console.log('FP received a new profile prop')
      if (this.props.profile) {
        if (nextProps.profile !== this.props.profile) {
          if (nextProps.profile.myProfile) {
              this.setState({waitingForProfile: false})
            // console.log('retrieving profiles for ' + followData.following);
            let userAndSeen = {}
            userAndSeen.user = nextProps.profile.myProfile.following
            this.props.getFollowingProfiles(userAndSeen);
          }
        } 
          } else {
            if (nextProps.profile.myProfile) {
              this.setState({waitingForProfile: false})
              // console.log('retrieving profiles for ' + nextProps.profile.myProfile.following);
              let userAndSeen = {}
              userAndSeen.user = nextProps.profile.myProfile.following
              this.props.getFollowingProfiles(userAndSeen);
            }
        }
      }
  };

  loadMore = () => {
    if (this.state.canLoadMore && !this.state.searchState) {
      let userAndSeen = {}
      userAndSeen.user = this.props.profile.myProfile.following
      if (this.props.profile.profiles) {
        userAndSeen.seen = this.props.profile.profiles.map(profile=>
          {return profile._id}
        )
        this.setState({seen: userAndSeen.seen})
      }
      this.setState({canLoadMore: false})
      console.log('loading more')
      
      this.props.getFollowingProfiles(userAndSeen);
    } 
  }

  loadMoreSearch = () => {
    if (this.state.canLoadMore && this.state.searchState) {
      let userAndSeen = {}
      userAndSeen.user = this.props.profile.myProfile.following
      if (this.props.profile.profiles) {
        userAndSeen.seen = this.props.profile.profiles.map(profile=>
          {return profile._id}
        )
        this.setState({seen: userAndSeen.seen})
      }
      this.setState({canLoadMore: false})
      this.props.searchFollowingProfiles(this.state.searchQuery,userAndSeen);
    } 
  }

  render() {
    // console.log('FP render started')
    const { profiles, loading } = this.props.profile;
    let profileItems;
    let loadingSpinner;
    let searchBar;
    let searchCancel;
    let allChefsButton;
    let chefsContainer;
    let moreButton;
    let breakpointColumnsObj = {
      default: 6,
      992: 4,
      768: 3,
      576: 2
    };

    if (profiles === null || loading) {
      loadingSpinner = <Spinner />;
    } else {
      loadingSpinner = null;
    }
    
    if (profiles === null ) {
      profileItems = null;
    } else {
      // console.log(this.props.profile);
      if (profiles.length > 0) {
        if (this.props.profile.myProfile) {
          profileItems = profiles.map(profile => (
            <div className="mb-2" key={profile._id}>
              <ProfileItem key={'profile' + profile._id} profile={profile} viewProfile={this.props.profile.myProfile.user._id} />
            </div>
          ))
          
          chefsContainer = (
            // <div className="chefContainer">{profileItems}</div>
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid mt-2"
              columnClassName="my-masonry-grid_column">
              {profileItems}
            </Masonry> 
          )
        } else {
          profileItems = profiles.map(profile => (
          <div key={profile._id}> 
            <ProfileItem key={'profile ' + profile._id} profile={profile} />
          </div>
        ))
            chefsContainer = (
              // <div className="chefContainer">{profileItems}</div>
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid mt-2"
                columnClassName="my-masonry-grid_column">
                {profileItems}
              </Masonry> 
            )
      }

        ;
      } else {
        profileItems = <h6 className="text-center">make friends at the potluq by tapping another chef's name to see their profile, then tapping 'follow'</h6>;
        chefsContainer = (
          <div className="">{profileItems}</div>
        )
      }
    }

    if (this.state.searchState) {
      searchCancel = (
        <div className="">
          <button
            className="btn tertiary-color-bg text-light m-1 ml-3"
            type="submit"
            onClick={this.resetSearch}
          >
            <i className="fas fa-times fa-lg"/>
          </button>
        </div>
      );
    } else {
      searchCancel = null;
    }
    allChefsButton = (
        <Link to="/profiles" className="btn btn-sm secondary-color-bg text-light mx-0">
          {/* <i className="fas fa-users fa-lg"></i> */}
          <i class="fas fa-globe-americas fa-lg"/>
        </Link>
      );
    // if (this.props.profile) {
    //     if (this.props.profile.myProfile) {
    //         if (this.props.profile.myProfile.following.length === 0) {
                
    //         }
    //     }
    // }
    searchBar = (
      <div className="form mr-2">
        <div className="input-group m-1">
          <form className="row" onSubmit={this.onSearchSubmit}>
            <div className="">
              <input
                type="text"
                className="form-control"
                placeholder="find someone"
                aria-label="search query"
                aria-describedby="basic-addon2"
                value={this.state.searchQuery}
                onChange={this.handleQueryInputChange()}
              />
            </div>
            <div className="">
              <div className="input-group-append">
                <button
                  className="btn tertiary-color-bg text-light"
                  type="submit"
                  onSubmit={this.onSearchSubmit}
                >
                  {/* <img
                    src="https://potluq-recipe-images.s3-us-west-2.amazonaws.com/images/search_2.png"
                    alt="search"
                    style={{ width: 20, height: 20 }}
                  /> */}
                  <i className="fas fa-search fa-lg" />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );

    if (this.state.canLoadMore) {
      if (this.state.searchState && profiles) {
        moreButton = (
          <Waypoint
            onEnter={this.loadMoreSearch}
          />
        )
      } else if (profiles){
        moreButton = (
          <Waypoint
          onEnter={this.loadMore}
        />
          // <button
          //   className="btn tertiary-color-bg text-light mr-0"
          //   onClick={()=>this.loadMore()}
          // >
          //   show more
          // </button>
        )
      }
    }  else {
      moreButton = (<div className="empty moreButton"></div>)
    }

    return (
      <div className="container">
        <Helmet>
          <title>chefs you follow</title>
        </Helmet>
        <div className="row">
          <div className="col-md-12">
            {/* <h1 className="display-4 text-center">friend chefs</h1> */}
            <p className="lead text-center m-0">
              just chefs you follow
            </p>
            <div className="row justify-content-between align-items-center m-0">
              {allChefsButton}
              <div className="row mx-0">
                {searchBar}
                {searchCancel}
              </div>
            </div>
            
              {chefsContainer}
              {loadingSpinner}
              {moreButton}
            {/* <div className="row justify-content-around align-content-center">
              {profileItems}
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

FollowingProfiles.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  getProfiles: PropTypes.func.isRequired,
  getFollowingProfiles: PropTypes.func.isRequired,
  searchProfiles: PropTypes.func.isRequired,
  clearProfiles: PropTypes.func.isRequired,
  searchFollowingProfiles: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getProfiles, searchProfiles, getFollowingProfiles, searchFollowingProfiles, getCurrentProfile, clearProfiles }
)(FollowingProfiles);
