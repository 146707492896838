import React from 'react';
import spinner from './spinner.gif';

export default function Spinner() {
  return (
    <img
      src={spinner}
      alt="Loading..."
      style={{ width: '100px' }}
      className="spinner my-5"
      // style={{ width: '100px', margin: 'auto', display: 'block' }}
    />
  );
}
