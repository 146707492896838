import React from 'react';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';

export default function NotFound() {
  return (
    <div className="notFoundContainer">
      <Helmet>
            <title>oops</title>
          </Helmet>
      <div className="notFoundOverlay">
        <div className="mx-auto pl-5">
          <h1 className="display-10 mt-3">Page not found</h1>
          <br />
          <p>Sorry, this page does not exist</p>
          <br />
          <p>{`Go back and see more `}<Link to="/allRecipes">recipes?</Link></p>
        </div>
        
      </div>
    </div>
  );
}
