import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer className="mt-auto primary-color-bg text-white p-1 text-center flex row justify-content-between align-items-center m-0 footerStyle" >
        <Link className="text-white ml-1 pl-3 small" to="/privacy">
        privacy
        </Link>
      <div><small>Copyright 
        <br/>&copy; {new Date().getFullYear()} potluq
        </small>
        </div>
    <div>
      <Link className="text-white mr-1 pr-3 small" to="/about">
      what
    </Link>
      
    
    </div>
    </footer>
  );
}
