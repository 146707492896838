import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Spinner from '../common/Spinner';
import {Helmet} from 'react-helmet';
import {
  getRecipes,
  authGetRecipes,
  getPriRecipes,
  searchRecipes,
  searchPublicRecipes,
  clearRecipes,
  addRecipefeedAd
} from '../../actions/recipeActions';
import { getCurrentProfile } from '../../actions/profileActions';
import RecipeItem from './RecipeItem';
import Masonry from 'react-masonry-css';
import { Waypoint } from 'react-waypoint';
import ReactPixel from 'react-facebook-pixel';

class RecipeFeed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchState: false,
      searchQuery: '',
      seen: [],
      canLoadMore: false
    };
  }

  componentDidMount() {
    this.props.getCurrentProfile();
    this.props.clearRecipes();
    if (this.props.auth.isAuthenticated) {
      this.props.authGetRecipes();
    } else {
      this.props.getRecipes();
    }
    // ReactPixel.pageView();
    ReactPixel.trackCustom('ViewFeed')
  }

  handleQueryInputChange = idx => e => {
    this.setState({ searchQuery: e.target.value });
  };

  onSearchSubmit = e => {
    e.preventDefault();
    if (this.state.searchQuery) {
      this.props.clearRecipes();
      this.setState({ searchState: true });
      if (this.props.auth.isAuthenticated) {
        this.props.searchRecipes(this.state.searchQuery);
      } else {
        this.props.searchPublicRecipes(this.state.searchQuery); 
      }
      ReactPixel.track('Search', {query:`${this.state.searchQuery}`})
    }
  };


  submitPreSearch = query => {
    this.setState({searchState: true, searchQuery: query});
    this.props.clearRecipes();
    if (this.props.auth.isAuthenticated) {
      this.props.searchRecipes(query);
    } else {
      this.props.searchPublicRecipes(query); 
    }
  }

  UNSAFE_componentWillReceiveProps = nextProps => {
    // if (nextProps.recipes) {
    //   this.setState({ recipe: nextProps.recipes });
    //   console.log('got new recipes')
    // }   
    if (nextProps.recipe) {
      if (nextProps.recipe.recipes) {
        if (nextProps.recipe.recipes.length === this.state.seen.length) {
          // console.log('got recipe')
          // console.log('got ' + nextProps.recipe.recipes.length)
          // console.log('seen ' + this.state.seen.length)
          // console.log('no new recipes')
        } else {
          this.setState({canLoadMore: true})
        }
      } 
    }
  };

  resetSearch = () => {
    this.setState({ searchQuery: '', searchState: false, canLoadMore: true });
    this.props.clearRecipes();
    if (this.props.auth.isAuthenticated) {
      this.props.authGetRecipes();
    } else {
      this.props.getRecipes();
    }
    
  };

  loadMore = () => {
    this.props.addRecipefeedAd();
    if (this.state.canLoadMore && !this.state.searchState) {
      let currentRecipes = {}
      if (this.props.recipe.recipes) {
        currentRecipes.seen = this.props.recipe.recipes.map(recipe=>
          {return recipe._id}
        )
        this.setState({seen: currentRecipes.seen})
      }
      this.setState({canLoadMore: false})
      if (this.props.auth.isAuthenticated) {
        this.props.authGetRecipes(currentRecipes);
      } else {
        this.props.getRecipes(currentRecipes);
      }
    } 
  }

  loadMoreSearch = () => {
    if (this.props.recipe.recipes.length > 0) {
      this.props.addRecipefeedAd();
    }
    if (this.state.canLoadMore && this.state.searchState) {
      let currentRecipes = {}
      if (this.props.recipe.recipes) {
        currentRecipes.seen = this.props.recipe.recipes.map(recipe=>
          {return recipe._id}
        )
        this.setState({seen: currentRecipes.seen})
      }
      this.setState({canLoadMore: false})
      if (this.props.auth.isAuthenticated) {
        this.props.searchRecipes(this.state.searchQuery, currentRecipes);
      } else {
        this.props.searchPublicRecipes(this.state.searchQuery, currentRecipes);
      }
    } 
  }

  render() {
    let { recipes, loading } = this.props.recipe;
    let recipeItems;
    let recipeFeedDisplay;
    let loadingSpinner;
    let searchBar;
    let searchCancel;
    let addRecipeButton;
    let friendsRecipesButton;
    let moreButton;

    let helmet = (
      <Helmet>
        <title>potluq recipes</title>
      </Helmet>
     )

    if (recipes === null || loading) {
      loadingSpinner = <Spinner />;
    } else {
      loadingSpinner = null;
    }
    if (recipes === null) {
      recipeItems = null;
    } else {
      if (recipes.length > 0) {
        recipeItems = recipes.map(recipe => (
          <RecipeItem key={recipe._id} recipe={recipe} />
        ));
      } else {
        recipeItems = <h4>No Recipes Found...</h4>;
      }
    }

    if (this.state.searchState) {
      searchCancel = (
        <div className="">
          <button
            className="btn tertiary-color-bg text-light m-1 ml-0"
            type="submit"
            onClick={this.resetSearch}
          >
            <i className="fas fa-times fa-lg"/>
          </button>
        </div>
      );
    } else {
      searchCancel = null;
    }

    if (this.props.auth.isAuthenticated) {
      friendsRecipesButton = (
        <Link to="/followingRecipes" className="btn btn-sm secondary-color-bg text-light">
        {/* friends' recipes */}
        <i className="fas fa-user-friends fa-lg"/>
      </Link>
      )
    } 

    searchBar = (
      <div className="form text-center">
        <div className="input-group m-1 mr-0">
          <form className="row m-auto" onSubmit={this.onSearchSubmit}>
            <div className="">
              <input
                type="text"
                className="form-control"
                placeholder="find something"
                aria-label="search query"
                aria-describedby="basic-addon2"
                value={this.state.searchQuery}
                onChange={this.handleQueryInputChange()}
              />
            </div>
            <div className="">
              <div className="input-group-append">
                <button
                  className="btn tertiary-color-bg text-light mr-0"
                  type="submit"
                  onSubmit={this.onSearchSubmit}
                >
                  {/* <img
                    src="https://potluq-recipe-images.s3-us-west-2.amazonaws.com/images/search_2.png"
                    alt="search"
                    style={{ width: 20, height: 20 }}
                  /> */}
                  <i className="fas fa-search fa-lg" />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
    let mainCourseTag
    if (this.state.searchState) {
      mainCourseTag = (<div></div>)
    } else {
      mainCourseTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-1 mr-3 suggestedTag firstTag" 
        onClick={() => this.submitPreSearch(`main course`)}>
          {`main course `} <i className="fas fa-search cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let appetizerTag
    if (this.state.searchState) {
      appetizerTag = (<div></div>)
    } else {
      appetizerTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-1 mr-3 suggestedTag" 
        onClick={() => this.submitPreSearch(`appetizer`)}>
          {`appetizer `} <i className="fas fa-search cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    
    let sideTag
    if (this.state.searchState) {
      sideTag = (<div></div>)
    } else {
      sideTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-1 mr-3 suggestedTag" 
        onClick={() => this.submitPreSearch(`side`)}>
          {`side`} <i className="fas fa-search cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let dessertTag
    if (this.state.searchState) {
      dessertTag = (<div></div>)
    } else {
      dessertTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-1 mr-3 suggestedTag" 
        onClick={() => this.submitPreSearch(`dessert`)}>
          {`dessert `} <i className="fas fa-search cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let fingerTag
    if (this.state.searchState) {
      fingerTag = (<div></div>)
    } else {
      fingerTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-1 mr-3 suggestedTag" 
        onClick={() => this.submitPreSearch(`finger food`)}>
          {`finger food `} <i className="fas fa-search cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let breakfastTag
    if (this.state.searchState) {
      breakfastTag = (<div></div>)
    } else {
      breakfastTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-1 mr-3 suggestedTag" 
        onClick={() => this.submitPreSearch(`breakfast`)}>
          {`breakfast `} <i className="fas fa-search cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let snackTag
    if (this.state.searchState) {
      snackTag = (<div></div>)
    } else {
      snackTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-1 mr-3 suggestedTag" 
        onClick={() => this.submitPreSearch(`snack`)}>
          {`snack `} <i className="fas fa-search cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let drinkTag
    if (this.state.searchState) {
      drinkTag = (<div></div>)
    } else {
      drinkTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-1 mr-3 suggestedTag" 
        onClick={() => this.submitPreSearch(`drink`)}>
          {`drink `} <i className="fas fa-search cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let mexicanTag
    if (this.state.searchState) {
      mexicanTag = (<div></div>)
    } else {
      mexicanTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-1 mr-3 suggestedTag" 
        onClick={() => this.submitPreSearch(`Mexican`)}>
          {`Mexican `} <i className="fas fa-search cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let healthyTag
    if (this.state.searchState) { 
      healthyTag = (<div></div>)
    } else {
      healthyTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-1 mr-3 suggestedTag" 
        onClick={() => this.submitPreSearch(`healthy`)}>
          {`healthy `} <i className="fas fa-search cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let comfortTag
    if (this.state.searchState) {
      comfortTag = (<div></div>)
    } else {
      comfortTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-1 mr-3 suggestedTag" 
        onClick={() => this.submitPreSearch(`comfort food`)}>
          {`comfort food`} <i className="fas fa-search cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let indianTag
    if (this.state.searchState) {
      indianTag = (<div></div>)
    } else {
      indianTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-1 mr-3 suggestedTag" 
        onClick={() => this.submitPreSearch(`Indian`)}>
          {`Indian `} <i className="fas fa-search cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let cajunTag
    if (this.state.searchState) {
      cajunTag = (<div></div>)
    } else {
      cajunTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-1 mr-3 suggestedTag" 
        onClick={() => this.submitPreSearch(`Cajun`)}>
          {`Cajun `} <i className="fas fa-search cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let chineseTag
    if (this.state.searchState) { 
      chineseTag = (<div></div>)
    } else {
      chineseTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-1 mr-3 suggestedTag" 
        onClick={() => this.submitPreSearch(`Chinese`)}>
          {`Chinese `} <i className="fas fa-search cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let americanTag
    if (this.state.searchState) {
      americanTag = (<div></div>)
    } else {
      americanTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-1 mr-3 suggestedTag" 
        onClick={() => this.submitPreSearch(`American`)}>
          {`American `} <i className="fas fa-search cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let frenchTag
    if (this.state.searchState) {
      frenchTag = (<div></div>)
    } else {
      frenchTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-1 mr-3 suggestedTag" 
        onClick={() => this.submitPreSearch(`French`)}>
          {`French `} <i className="fas fa-search cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let japaneseTag
    if (this.state.searchState) {
      japaneseTag = (<div></div>)
    } else {
      japaneseTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-1 mr-3 suggestedTag" 
        onClick={() => this.submitPreSearch(`Japanese`)}>
          {`Japanese `} <i className="fas fa-search cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let greekTag
    if (this.state.searchState) {
      greekTag = (<div></div>)
    } else {
      greekTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-1 mr-3 suggestedTag" 
        onClick={() => this.submitPreSearch(`Greek`)}>
          {`Greek `} <i className="fas fa-search cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let soulTag
    if (this.state.searchState) {
      soulTag = (<div></div>)
    } else {
      soulTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-1 mr-3 suggestedTag" 
        onClick={() => this.submitPreSearch(`soul food`)}>
          {`soul food `} <i className="fas fa-search cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let seafoodTag
    if (this.state.searchState) { 
      seafoodTag = (<div></div>)
    } else {
      seafoodTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-1 mr-3 suggestedTag" 
        onClick={() => this.submitPreSearch(`seafood`)}>
          {`seafood `} <i className="fas fa-search cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let italianTag
    if (this.state.searchState) { 
      italianTag = (<div></div>)
    } else {
      italianTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-1 mr-3 suggestedTag" 
        onClick={() => this.submitPreSearch(`Italian`)}>
          {`Italian `} <i className="fas fa-search cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let thaiTag
    if (this.state.searchState) {     
       thaiTag = (<div></div>)
    } else {
      thaiTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-1 mr-3 suggestedTag" 
        onClick={() => this.submitPreSearch(`Thai`)}>
          {`Thai `} <i className="fas fa-search cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let mideastTag
    if (this.state.searchState) {
      mideastTag = (<div></div>)
    } else {
      mideastTag = (
        <span 
        className="btn primary-color-bg text-light recipeCardContainer mt-1 mr-3 suggestedTag" 
        onClick={() => this.submitPreSearch(`middle eastern`)}>
          {`middle eastern `} <i className="fas fa-search cursorPointer tagText hoverSpin text-white" />
        </span>
      )
    }
    let suggestedSearch = (
      <div className="scrolling-feedwrapper mt-0 mb-0">
        {mainCourseTag}
        {healthyTag}
        {mexicanTag}
        {appetizerTag}
        {seafoodTag}
        {dessertTag}
        {indianTag}
        {sideTag}
        {fingerTag}
        {snackTag}
        {drinkTag}
        {breakfastTag}
        {comfortTag}
        {cajunTag}
        {chineseTag}
        {frenchTag}
        {americanTag}
        {japaneseTag}
        {greekTag}
        {soulTag}
        {italianTag}
        {thaiTag}
        {mideastTag}
      </div>
    )
    addRecipeButton = (
      <Link to="/create-recipe" className="btn secondary-color-bg text-light d-flex align-items-center align-middle py-0 rounded">
        <p className="m-0 mr-2 py-0 my-0 align-middle align-content-center">add recipe</p>
        <i className="fas fa-plus-circle fa-2x "/>
      </Link>
    );
    if (this.state.canLoadMore) {
      if (this.state.searchState && recipes) {
        moreButton = (
          <Waypoint
            onEnter={this.loadMoreSearch}
          />
        )
      } else if (recipes){
        moreButton = (
          <Waypoint
          onEnter={this.loadMore}
        />
          // <button
          //   className="btn tertiary-color-bg text-light mr-0"
          //   onClick={()=>this.loadMore()}
          // >
          //   show more
          // </button>
        )
      }
    }  else {
      moreButton = (<div className="empty moreButton"></div>)
    }
    let breakpointColumnsObj = {
      default: 6,
      992: 4,
      768: 3,
      576: 2
    };
    if (this.props.auth.isAuthenticated) {
      recipeFeedDisplay = (
      <div className="">
        {/* <p className="lead text-center m-1">the latest recipes</p> */}
        <div className="container mt-2">
          <div className="row justify-content-between align-items-center d-flex flex-wrap mx-0">
            <div className="justify-content-between align-items-center order-1 order-sm-1">{friendsRecipesButton}</div>
            <div className="row mx-auto justify-content-between align-items-center order-3 order-sm-2">
              {searchBar}
              {searchCancel}
            </div>
            <div className="ml-2 justify-content-between align-items-center order-2 order-sm-3">{addRecipeButton}</div>
          </div>
          {suggestedSearch}
        </div>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column">
          {recipeItems}
        </Masonry>
        <div>{loadingSpinner}</div>
        {/* <div className="chefContainer col-12">{recipeItems}</div> */}
      </div>
      )
    } else {
      recipeFeedDisplay = (
      <div className="">
        {/* <p className="lead text-center m-1">the latest recipes at the potluq</p> */}
        <div className="container mt-2">
          <div className="row justify-content-between align-items-center d-flex flex-wrap">
            <div className="row mx-auto justify-content-between align-items-center order-3 order-sm-2">
              {searchBar}
              {searchCancel}
            </div>
            <div className="mx-2 justify-content-between align-items-center order-2 order-sm-3">{addRecipeButton}</div>
          </div>
          {suggestedSearch}
        </div>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column">
          {recipeItems}
        </Masonry>
        <div>{loadingSpinner}</div>
        {/* <div className="chefContainer col-12">{recipeItems}</div> */}
      </div>
      )
    }
    
      
    return (
      <div className="recipeView">
        {helmet}
        <div className="container">
          <div className="rows">
            <div className=" mb-4">{recipeFeedDisplay}</div>
            {moreButton}
          </div>
        </div>
      </div>
    );
  }
}

RecipeFeed.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  getRecipes: PropTypes.func.isRequired,
  clearRecipes: PropTypes.func.isRequired,
  authGetRecipes: PropTypes.func.isRequired,
  getPriRecipes: PropTypes.func.isRequired,
  auth: PropTypes.object,
  searchRecipes: PropTypes.func.isRequired,
  searchPublicRecipes: PropTypes.func.isRequired,
  addRecipefeedAd: PropTypes.func.isRequired,
  recipe: PropTypes.object.isRequired,
  profile: PropTypes.object
};

const mapStateToProps = state => ({
  recipe: state.recipe,
  profile: state.profile,
  searchState: state.searchState,
  auth: state.auth
});

export default connect(
  mapStateToProps, { 
    getRecipes, 
    authGetRecipes, 
    getPriRecipes, 
    searchRecipes, 
    getCurrentProfile, 
    searchPublicRecipes, 
    clearRecipes,
    addRecipefeedAd
   }
)(RecipeFeed);
