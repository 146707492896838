import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TextFieldGroup from '../common/TextFieldGroup';
import {Helmet} from 'react-helmet';
import { createRequest, createOutsideRequest } from '../../actions/helpActions';
import ReCAPTCHA from "react-google-recaptcha";

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            body: '',
            category: 'cry for help',
            errors: {},
            requestDone: false,
            recaptchaResponse: 'robot'
        };
        this.handleCaptchaResponseChange = this.handleCaptchaResponseChange.bind(this);
 
    }
    //add actions here
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
          this.setState({ errors: nextProps.errors });
        }
    }
    
      onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleCaptchaResponseChange(response) {
        this.setState({
          recaptchaResponse: response,
        });
    }
    onAuthSubmit = e => {
        e.preventDefault();
    
        const newRequest = {
          category: this.state.category,
          body: this.state.body
        };
        let handlePromise = new Promise((resolve, reject) => {
          this.props
          //first check whether handle is available
            .createRequest(newRequest)
            .then(res => {
              console.log('created request, returned ' + res.data);
              if (res.data === 'success') {
                  console.log('detected success')
                  this.setState({requestDone: true})
              }
              this.setState({ errors: {} });
              resolve();
            })
            .catch(err => {
              console.log('the handle was taken');
              this.setState({ errors: err });
            });
        });
        handlePromise.then();
    }
    onOpenSubmit = e => {
        e.preventDefault();
    
        const newRequest = {
          name: this.state.name,
          email: this.state.email,
          category: this.state.category,
          body: this.state.body
        };
        let handlePromise = new Promise((resolve, reject) => {
          this.props
          //first check whether handle is available
            .createOutsideRequest(newRequest)
            .then(res => {
              console.log('created request, returned ' + res.data);
              if (res.data === 'success') {
                  console.log('detected success')
                  this.setState({requestDone: true})
              }
              this.setState({ errors: {} });
              resolve();
            })
            .catch(err => {
              console.log('the handle was taken');
              this.setState({ errors: err });
            });
        });
        handlePromise.then();
    }

    
    render() {
        const {errors} = this.state;
        let header
        let contactForm
        let inputButton

        let recaptcha = (
            <div className="d-flex justify-content-center">
    <           ReCAPTCHA
                ref={(el) => { this.recaptcha = el; }}
                sitekey="6LeBmswUAAAAAIew45QQ-4CbmBD0lMiQfOKecoi8"
                onChange={this.handleCaptchaResponseChange}
                />
            </div>
            
        )
        if (this.state.recaptchaResponse !== 'robot') {
            inputButton = (
            <input
                type="submit"
                className="btn tertiary-color-bg text-light btn-block mt-4 col-md-2"
                value="send"
            />
            )
        }
        if (!this.state.requestDone) {
            header = (
                <div>
                    <h4 className="display-10 text-center">do you need something?</h4>
                </div>
            )
        } else {
            header = (
                <div></div>
            )
        }
        
        if (this.state.requestDone) {
            contactForm = (
                <div>
                    <h1>request submitted</h1>
                    <Link to="/allRecipes" className="my-3">relax and browse some recipes</Link>
                </div>
            )
        } else if (this.props.auth.isAuthenticated) {
            contactForm = (
                <div>
                    <div className="text-center">
                        <Link to="/about" className="btn secondary-color-bg btn-block mt-3 text-light">questions generally</Link>
                        <Link to="/requestpasswordreset" className="btn tertiary-color-bg btn-block my-3 text-light">forgot password</Link>
                    </div>
                <form onSubmit={this.onAuthSubmit}>
                    <select className="form-control" 
                    name="category" 
                    value={this.state.category} 
                    onChange={this.onChange}
                    error={errors.category}>
                        <option>cry for help</option>
                        <option>new feature request</option>
                        <option>bug report</option>
                        <option>complaint</option>
                    </select>
                    <TextFieldGroup
                    placeholder="what do you need"
                    name="body"
                    value={this.state.body}
                    onChange={this.onChange}
                    error={errors.body}
                    />
                    <input
                    type="submit"
                    className="btn tertiary-color-bg btn-block text-light mt-4 col-md-2"
                    value="send"
                    />
                </form>
                </div>
            )
        } else {
            contactForm = (
                <div>
                    <div className="text-center">
                        <Link to="/about" className="btn secondary-color-bg btn-block mt-3 text-light">questions generally</Link>
                        <Link to="/requestpasswordreset" className="btn tertiary-color-bg btn-block mt-3 text-light">forgot password</Link>
                    </div>
                    <form onSubmit={this.onOpenSubmit} className="mt-3 text-center">
                    <p>everything else:</p>
                    <TextFieldGroup
                    placeholder="name"
                    name="name"
                    value={this.state.name}
                    onChange={this.onChange}
                    error={errors.name}
                    />

                    <TextFieldGroup
                    placeholder="name@email.com"
                    name="email"
                    type="email"
                    value={this.state.email}
                    onChange={this.onChange}
                    error={errors.email}
                    />
                    <select className="form-control mb-3" 
                    name="category" 
                    value={this.state.category} 
                    onChange={this.onChange}
                    error={errors.category}>
                    <option>cry for help</option>
                    <option>new feature request</option>
                    <option>bug report</option>
                    <option>complaint</option>
                    </select>
                    <TextFieldGroup
                    placeholder="what do you need"
                    name="body"
                    value={this.state.body}
                    onChange={this.onChange}
                    error={errors.body}
                    className="mt-2"
                    />
                    {recaptcha}
                    {inputButton}
                    </form>
                </div>
            )
        }
        return (
            <div className="m-4 pl-2 pr-2">
            <Helmet>
                <title>contact</title>
            </Helmet>
                <div>
                    {header}
                    {contactForm}
                </div>
                
            </div>
        );
  }
}

Contact.propTypes = {
  auth: PropTypes.object.isRequired,
  createRequest: PropTypes.func.isRequired,
  createOutsideRequest: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  {createRequest, createOutsideRequest}
)(Contact);
