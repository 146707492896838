import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { setCurrentUser, logoutUser } from './actions/authActions';
import { clearCurrentProfile } from './actions/profileActions';
import { Provider } from 'react-redux';
import store from './store';
import PrivateRoute from './components/common/PrivateRoute';

import About from './components/common/About';
import Contact from './components/common/Contact';
import TOS from './components/common/TOS';
import Privacy from './components/common/Privacy';
import NavBar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import Landing from './components/layout/Landing';
import Register from './components/auth/Register';
import Login from './components/auth/Login';
import ReqPwdReset from './components/auth/ReqPwdReset';
import PwdReset from './components/auth/PwdReset';
// import CreateProfile from './components/create-profile/CreateProfile';
// import EditProfile from './components/edit-profile/EditProfile';
// import EditUser from './components/edit-profile/EditUser';
import EditUserAndProfile from './components/edit-profile/EditUserAndProfile';
import Profiles from './components/profiles/Profiles';
import FollowingProfiles from './components/profiles/FollowingProfiles';
import NotFound from './components/not-found/NotFound';
import RecipeForm from './components/recipes/RecipeForm';
import RecipeFeed from './components/recipes/RecipeFeed';
import FollowingRecipes from './components/recipes/FollowingRecipes';
import RecipeView from './components/recipes/RecipeView';
import RecipeEdit from './components/recipes/RecipeEdit';
import UserRecipes from './components/recipes/UserRecipes';
import BookView from './components/books/BookView';
import LibraryView from './components/books/LibraryView';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

//check for token
if (localStorage.jwtToken) {
  //set auth token header auth
  setAuthToken(localStorage.jwtToken);
  //decode the token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  //set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  //check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    //logout user
    store.dispatch(logoutUser());
    //clear current profile
    store.dispatch(clearCurrentProfile());
    //redirect to login
    window.location.href = '/login';
  }
}

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App min-vh-100 d-flex flex-column h-100 m-0">
            <NavBar />
            <div className="container  p-0">
              <Switch>
              <Route exact path="/" component={Landing} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/requestpasswordreset" component={ReqPwdReset} />
              <Route exact path="/passwordreset/:token" component={PwdReset} />
              <Route exact path="/profiles" component={Profiles} />
              <Route exact path="/chefs" component={Profiles} />
              <Route exact path="/profile/:handle" component={UserRecipes} />
              <Route exact path="/chef/:handle" component={UserRecipes} />
              <Route exact path="/allRecipes" component={RecipeFeed} />
              <Route exact path="/recipe/:id" component={RecipeView} />
              <Route exact path="/editRecipe/:id" component={RecipeEdit} />
              <Route exact path="/recipes/:handle" component={UserRecipes} />
                <PrivateRoute 
                exact path="/followingrecipes" component={FollowingRecipes} />
                <PrivateRoute 
                exact path="/friendchefs" component={FollowingProfiles} />
                <PrivateRoute 
                exact path="/yourrecipes" component={UserRecipes} />
                {/* <PrivateRoute
                  exact
                  path="/create-profile"
                  component={CreateProfile}
                />
                <PrivateRoute
                  exact
                  path="/edit-profile"
                  component={EditProfile}
                /> */}
                {/* <PrivateRoute exact path="/edit-account" component={EditUser} /> */}
                <PrivateRoute exact path="/editprofileandaccount" component={EditUserAndProfile} />
                <PrivateRoute
                  exact
                  path="/create-recipe"
                  component={RecipeForm}
                />
                <PrivateRoute
                  exact
                  path="/book/:id"
                  component={BookView}
                />
                <PrivateRoute
                  exact
                  path="/mybooks"
                  component={LibraryView}
                />
              <Route exact path="/about" component={About} />
              <Route exact path="/terms" component={TOS} />
              <Route exact path="/privacy" component={Privacy} />
              <Route exact path="/help" component={Contact} />
              <Route exact path="/not-found" component={NotFound} />
              <Route component={NotFound} />
              </Switch>
            </div>
            <Footer />
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
