import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Spinner from '../common/Spinner';
import {
  getRecipes,
  getPriRecipes,
  searchRecipes,
  searchPublicRecipes,
  searchFriendsRecipes,
  clearRecipes,
  addRecipefeedAd
} from '../../actions/recipeActions';
import { getCurrentProfile } from '../../actions/profileActions';
import RecipeItem from './RecipeItem';
import { Helmet } from 'react-helmet';
import Masonry from 'react-masonry-css';
import { Waypoint } from 'react-waypoint';
import ReactPixel from 'react-facebook-pixel';

class FollowingRecipes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchState: false,
      searchQuery: '',
      seen: [],
      canLoadMore: false,
      initialCall: true
    };
  }

  componentDidMount() {
    this.props.getCurrentProfile();
    this.props.clearRecipes();
    if (!this.props.auth.isAuthenticated) {
      //fallback behavior
      console.log('no one authd, getting recipes')
      this.props.getRecipes();
    }
    ReactPixel.trackCustom('ViewFollowingRecipes');
  }

  handleQueryInputChange = idx => e => {
    this.setState({ searchQuery: e.target.value });
  };

  onSearchSubmit = e => {
    e.preventDefault();
    if (this.state.searchQuery) {
      this.setState({ searchState: true });
      this.props.clearRecipes();
      let followingRequest = {}
      followingRequest.following = this.props.profile.myProfile.following
      if (this.props.auth.isAuthenticated) {
        this.props.searchFriendsRecipes(this.state.searchQuery, followingRequest);    
      } else {
        this.props.searchPublicRecipes(this.state.searchQuery);
      }
    }
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    // if (nextProps.recipes) {
    //   this.setState({ recipe: nextProps.recipes });
    // }
    if (nextProps.recipe) {
      if (nextProps.recipe.recipes) {
        if (nextProps.recipe.recipes.length === this.state.seen.length) {
          //
        } else {
          this.setState({canLoadMore: true})
        }
      } 
    }
    //initial call for recipes after receiving profile
    if (nextProps.profile) {
      if (this.props.profile) {
        if (nextProps.profile !== this.props.profile && this.state.initialCall) {
          if (nextProps.profile.myProfile) {
            this.setState({initialCall: false})
            let followingRequest = {}
            followingRequest.following = nextProps.profile.myProfile.following
            // console.log('following ' + followingRequest.following);
            // this.props.clearRecipes();
            this.props.getPriRecipes(followingRequest);
          }
        } 
          // } else {
          //   if (nextProps.profile.myProfile) {
          //     let followingRequest = {}
          //     followingRequest.following = nextProps.profile.myProfile.following
          //     // console.log('following ' + nextProps.profile.myProfile.following);
          //     this.props.getPriRecipes(followingRequest);
          //   }
        }
      }
      
  };

  resetSearch = () => {
    this.setState({ searchQuery: '', searchState: false });
    this.props.clearRecipes();
    let followingRequest = {}
    followingRequest.following = this.props.profile.myProfile.following
    this.props.getPriRecipes(followingRequest)
  };

  loadMore = () => {
    this.props.addRecipefeedAd();
    if (this.state.canLoadMore && !this.state.searchState) {
    let followingRequest = {}
    followingRequest.following = this.props.profile.myProfile.following
    if (this.props.recipe.recipes) {
      followingRequest.seen = this.props.recipe.recipes.map(recipe=>
        {return recipe._id}
      )
      this.setState({seen: followingRequest.seen})
    }
      this.setState({canLoadMore: false})
      // console.log('loading more recipes')
      this.props.getPriRecipes(followingRequest);
    } 
  }

  loadMoreSearch = () => {
    if (this.state.canLoadMore && this.state.searchState) {
    let followingRequest = {}
    followingRequest.following = this.props.profile.myProfile.following
    if (this.props.recipe.recipes) {
      followingRequest.seen = this.props.recipe.recipes.map(recipe=>
        {return recipe._id}
      )
      this.setState({seen: followingRequest.seen})
    }
    this.setState({canLoadMore: false})
    this.props.searchFriendsRecipes(this.state.searchQuery, followingRequest);
    } 
  }

  render() {
    let { recipes, loading } = this.props.recipe;
    let recipeItems;
    let recipeFeedDisplay;
    let loadingSpinner;
    let searchBar;
    let searchCancel;
    let addRecipeButton;
    let recipeFeedLink;
    let recipeItemsContainer;
    let moreButton;
    let helmet = (
      <Helmet>
        <title>potluq recipes of chefs you follow</title>
      </Helmet>
    )

    if (recipes === null || loading) {
      loadingSpinner = <Spinner />;
    } else {
      loadingSpinner = null;
    }
    let breakpointColumnsObj = {
      default: 6,
      992: 4,
      768: 3,
      576: 2
    };
    if (recipes === null) {
      recipeItems = null;
    } else {
      if (recipes.length > 0) {
        recipeItems = recipes.map(recipe => (
          <RecipeItem key={recipe._id} recipe={recipe} />
        ));
        recipeItemsContainer = (
          // <div className="chefContainer col-12">{recipeItems}</div>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid mt-2"
            columnClassName="my-masonry-grid_column">
            {recipeItems}
          </Masonry> 
        )
      } else {
        recipeItems = (
          <div className="text-center">
            {/* <h4 className="text-center">No Recipes Found...</h4> */}
            <p className="mt-3">follow some of the <Link to="/chefs">chefs</Link> responsible for your favorite <Link to="/allRecipes">recipes</Link></p>
            <p>or inspire your friends by <Link to="/create-recipe">adding recipes of your own!</Link></p>
          </div>
        
        );
        recipeItemsContainer = (
          <div className="col-12 mx-auto">{recipeItems}</div>
        )
       
      }
    }
    

    if (this.state.searchState) {
      searchCancel = (
        <div className="">
          <button
            className="btn tertiary-color-bg text-light m-1 ml-3"
            type="submit"
            onClick={this.resetSearch}
          >
            <i className="fas fa-times fa-lg"/>
          </button>
        </div>
      );
    } else {
      searchCancel = null;
    }
    searchBar = (
      <div className="form">
        <div className="input-group m-1">
          <form className="row" onSubmit={this.onSearchSubmit}>
            <div className="">
              <input
                type="text"
                className="form-control"
                placeholder="find something"
                aria-label="search query"
                aria-describedby="basic-addon2"
                value={this.state.searchQuery}
                onChange={this.handleQueryInputChange()}
              />
            </div>
            <div className="">
              <div className="input-group-append">
                <button
                  className="btn tertiary-color-bg text-light"
                  type="submit"
                  onSubmit={this.onSearchSubmit}
                >
                  {/* <img
                    src="https://potluq-recipe-images.s3-us-west-2.amazonaws.com/images/search_2.png"
                    alt="search"
                    style={{ width: 20, height: 20 }}
                  /> */}
                  <i className="fas fa-search fa-lg" />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
    addRecipeButton = (
      <Link to="/create-recipe" className="btn secondary-color-bg text-light d-flex align-items-center align-middle py-0 rounded">
        <p className="m-0 mr-2 py-0 my-0 align-middle align-content-center">add recipe</p>
        <i className="fas fa-plus-circle fa-2x "/>
      </Link>
    );
    recipeFeedLink = (
      <Link to="/allRecipes" className="btn btn-sm secondary-color-bg text-light">
        {/* all recipes */}
        <i class="fas fa-globe-americas fa-lg"/>
      </Link>
    );
    recipeFeedDisplay = (
      <div className="">
        <p className="lead text-center m-1">here's what your friends brought</p>
        <div className="container ">
          <div className="row justify-content-between align-items-center d-flex flex-wrap m-0">
            <div className="justify-content-between align-items-center order-1 order-sm-1">{recipeFeedLink}</div>
            <div className="row mx-auto justify-content-between align-items-center order-3 order-sm-2">
              {searchBar}
              {searchCancel}
            </div>
            <div className="mx-2 justify-content-between align-items-center order-2 order-sm-3">{addRecipeButton}</div>
          </div>
        </div>
        {recipeItemsContainer}
        <div>{loadingSpinner}</div>
      </div>
    );

    if (this.state.canLoadMore) {
      if (this.state.searchState && recipes) {
        moreButton = (
          <Waypoint
            onEnter={this.loadMoreSearch}
          />
        )
      } else if (recipes){
        moreButton = (
          <div><Waypoint
          onEnter={this.loadMore}
        />
        <p>waypoint</p>
            </div>
          // <button
          //   className="btn tertiary-color-bg text-light mr-0"
          //   onClick={()=>this.loadMore()}
          // >
          //   show more
          // </button>
        )
      }
    }  else {
      moreButton = (<div className="empty moreButton"></div>)
    }

    return (
      <div className="recipeView">
        {helmet}
        <div className="container">
          <div className="row">
            <div className="col-md-12">{recipeFeedDisplay}</div>
            {moreButton}
          </div>
        </div>
      </div>
    );
  }
}

FollowingRecipes.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  getRecipes: PropTypes.func.isRequired,
  getPriRecipes: PropTypes.func.isRequired,
  auth: PropTypes.object,
  searchRecipes: PropTypes.func.isRequired,
  searchPublicRecipes: PropTypes.func.isRequired,
  searchFriendsRecipes: PropTypes.func.isRequired,
  clearRecipes: PropTypes.func.isRequired,
  addRecipefeedAd: PropTypes.func.isRequired,
  recipe: PropTypes.object.isRequired,
  profile: PropTypes.object
};

const mapStateToProps = state => ({
  recipe: state.recipe,
  profile: state.profile,
  searchState: state.searchState,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getRecipes, 
    getPriRecipes, 
    searchRecipes, 
    getCurrentProfile, 
    searchPublicRecipes, 
    searchFriendsRecipes, 
    clearRecipes,
    addRecipefeedAd }
)(FollowingRecipes);
