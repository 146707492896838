export const GET_ERRORS = 'GET_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILE_BY_HANDLE = 'GET_PROFILE_BY_HANDLE';
export const PROFILE_LOADING = 'PROFILE_LOADING';
export const PROFILE_NOT_FOUND = 'PROFILE_NOT_FOUND';
export const CLEAR_CURRENT_PROFILE = 'CLEAR_CURRENT_PROFILE';
export const CLEAR_PROFILES = 'CLEAR_PROFILES';
export const GET_PROFILES = 'GET_PROFILES';
export const HANDLE_AVAILABLE = 'HANDLE_AVAILABLE';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const GET_RECIPES = 'GET_RECIPES';
export const GET_RECIPE = 'GET_RECIPE';
export const ADD_RECIPE = 'ADD_RECIPE';
export const ADD_RECIPEFEED_AD = 'ADD_RECIPEFEED_AD';
export const DELETE_RECIPE = 'DELETE_RECIPE';
export const RECIPE_LOADING = 'RECIPE_LOADING';
export const RECIPE_NOT_FOUND = 'RECIPE_NOT_FOUND';
export const CLEAR_CURRENT_RECIPE = 'CLEAR_CURRENT_RECIPE';
export const CLEAR_RECIPES = 'CLEAR_RECIPES';
export const SEARCH_RECIPES = 'SEARCH_RECIPES';
export const CLEAR_CURRENT_BOOK = 'CLEAR_CURRENT_BOOK';
export const BOOK_LOADING = 'BOOK_LOADING';
export const ADD_BOOK = 'ADD_BOOK';
export const GET_BOOK = 'GET_BOOK';
export const SAVE_RECIPE_TO_BOOK = 'SAVE_RECIPE_TO_BOOK';
export const REMOVE_RECIPE_FROM_BOOK = 'REMOVE_RECIPE_FROM_BOOK';
export const DELETE_BOOK = 'DELETE_BOOK';
