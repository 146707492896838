import axios from 'axios';

import {
  GET_PROFILE,
  GET_PROFILE_BY_HANDLE,
  PROFILE_LOADING,
  CLEAR_CURRENT_PROFILE,
  CLEAR_PROFILES,
  GET_ERRORS,
  GET_PROFILES,
  SET_CURRENT_USER,
  CLEAR_ERRORS
} from './types';

//check handle availability
export const checkHandle = handle => dispatch => {
  dispatch(clearErrors());
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/profile/available/${handle}`)
      .then(res => {
        resolve(res);
      })
      .catch(err =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
      );
  });
};

//get current profile
export const getCurrentProfile = () => dispatch => {
  dispatch(setProfileLoading());
  axios
    .get('/api/profile')
    .then(res =>
      dispatch({
        type: GET_PROFILE,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_PROFILE,
        payload: {}
      })
    );
};

//get profile by handle
export const getProfileByHandle = handle => dispatch => {
  dispatch(setProfileLoading());
  axios
    .get(`/api/profile/handle/${handle}`)
    .then(res =>
      dispatch({
        type: GET_PROFILE_BY_HANDLE,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_PROFILE_BY_HANDLE,
        payload: null
      })
    );
};

//create profile
export const createNewProfile = (profileData, history) => dispatch => {
  dispatch(clearErrors());
  axios
    .post('/api/profile/new', profileData)
    .then(res => history.push('/login'))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

//edit profile
export const editProfile = (profileData, history) => dispatch => {
  return new Promise((resolve, reject) => {
    axios
      .post('/api/profile', profileData)
      .then(res => 
        {if (history) {history.push('/profiles')}
        resolve(res)
      }
        )
      .catch(err =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
      );
  })
};

//follow someone
export const follow = (followingId) => dispatch => {
  axios
    .post(`/api/profile/follow/${followingId}`)
    .then(res => dispatch(getCurrentProfile()))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

//unfollow someone
export const unfollow = (followingId) => dispatch => {
  axios
    .post(`/api/profile/unfollow/${followingId}`)
    .then(res => dispatch(getCurrentProfile()))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

//get all profiles
export const getProfiles = seen => dispatch => {
  dispatch(setProfileLoading());
  axios
    .post('/api/profile/all', seen)
    .then(res =>
      dispatch({
        type: GET_PROFILES,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_PROFILES,
        payload: null
      })
    );
};

//get all profiles of people I'm following
export const getFollowingProfiles = userAndSeen => dispatch => {
  dispatch(setProfileLoading());
  axios
    .post('/api/profile/allFollowing', userAndSeen)
    .then(res =>
      dispatch({
        type: GET_PROFILES,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_PROFILES,
        payload: null
      })
    );
};

//search profiles
export const searchProfiles = (query, seen) => dispatch => {
  dispatch(setProfileLoading());
  axios
    .post(`/api/profile/search/${query}`, seen)
    .then(res =>
      dispatch({
        type: GET_PROFILES,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_PROFILES,
        payload: null
      })
    );
};

//search profiles that you follow
export const searchFollowingProfiles = (query, userAndSeen) => dispatch => {
  dispatch(setProfileLoading());
  axios
    .post(`/api/profile/followSearch/${query}`, userAndSeen)
    .then(res =>
      dispatch({
        type: GET_PROFILES,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_PROFILES,
        payload: null
      })
    );
};

//delete account & profile
export const deleteAccount = profileId => dispatch => {
  if (window.confirm('Are you sure? This can NOT be undone, and you will lose access to any private recipes!')) {
    axios
      .delete('/api/profile')
      .then(res =>
        dispatch({
          type: SET_CURRENT_USER,
          payload: {}
        })
      )
      .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
  }
};

//profile loading
export const setProfileLoading = () => {
  return {
    type: PROFILE_LOADING
  };
};

//clear profiles
export const clearProfiles = () => {
  return {
    type: CLEAR_PROFILES
  };
};

//clear profile
export const clearCurrentProfile = () => {
  return {
    type: CLEAR_CURRENT_PROFILE
  };
};

//clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};
