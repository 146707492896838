import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Spinner from '../common/Spinner';
import { getProfiles, searchProfiles, clearProfiles } from '../../actions/profileActions';
import ProfileItem from './ProfileItem';
import { Helmet } from 'react-helmet';
import Masonry from 'react-masonry-css';
import { Waypoint } from 'react-waypoint';

class Profiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchState: false,
      searchQuery: '',
      seen: [],
      canLoadMore: false
    };
  }
  componentDidMount() {
    this.props.clearProfiles();
    this.props.getProfiles();
  }

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (nextProps.profile) {
      if (nextProps.profile.profiles) {
        if (nextProps.profile.profiles.length !== this.state.seen.length) {
          //
        // } else {
          this.setState({canLoadMore: true})
        }
      } 
    }
  };

  handleQueryInputChange = idx => e => {
    this.setState({ searchQuery: e.target.value });
  };
  onSearchSubmit = e => {
    e.preventDefault();
    this.props.clearProfiles();
    if (this.state.searchQuery) {
      this.props.searchProfiles(this.state.searchQuery);
      this.setState({ searchState: true });
    }
  };

  resetSearch = () => {
    this.props.clearProfiles();
    this.setState({ searchQuery: '', searchState: false });
    this.props.getProfiles();
  };

  loadMore = () => {
    if (this.state.canLoadMore && !this.state.searchState) {
      let currentProfiles = {}
      if (this.props.profile.profiles) {
        currentProfiles.seen = this.props.profile.profiles.map(profile=>
          {return profile._id}
        )
        this.setState({seen: currentProfiles.seen})
      }
      this.setState({canLoadMore: false})
      console.log('loading more')
      this.props.getProfiles(currentProfiles);
    } 
  }

  loadMoreSearch = () => {
    if (this.state.canLoadMore && this.state.searchState) {
      let currentProfiles = {}
      if (this.props.profile.profiles) {
        currentProfiles.seen = this.props.profile.profiles.map(profile=>
          {return profile._id}
        )
        this.setState({seen: currentProfiles.seen})
      }
      this.setState({canLoadMore: false})
      this.props.searchProfiles(this.state.searchQuery, currentProfiles);
    } 
  }

  render() {
    const { profiles, loading } = this.props.profile;
    let profileItems;
    let loadingSpinner;
    let searchBar;
    let searchCancel;
    let friendChefsButton;
    let chefsView;
    let profileBoolean = false;
    let chefContainer;
    let moreButton;
    let helmet = (
      <Helmet>
        <title>potluq chefs</title>
      </Helmet>
     )

    if (profiles === null || loading) {
      loadingSpinner = <Spinner />;
    } else {
      loadingSpinner = null;
    }
    if (profiles === null ) {
      profileItems = null;
    } else {
      // console.log(this.props.profile);
      if (profiles.length > 0) {
        if (this.props.profile.myProfile !== null && this.props.profile.myProfile !== {}) {
          if (this.props.profile.myProfile.user) {
            profileBoolean = true;
            profileItems = profiles
            .filter(function(profile) {
              if (profile.user && profile.user.name !== 'removed') {
                return profile
              } else {
                return null
              }
            })
            .map(profile => (
              <div  className="mb-2" key={profile._id}>
                <ProfileItem key={'profile' + profile._id} profile={profile} viewProfile={this.props.profile.myProfile.user._id} />
              </div>
              ))
            // profileItems = profiles.map(profile => (
            //   <div  className="mb-2" key={profile._id}>
            //     <ProfileItem key={'profile' + profile._id} profile={profile} viewProfile={this.props.profile.myProfile.user._id} />
            //   </div>
            //   ))
          } else {
            profileBoolean = true;
          profileItems = profiles.map(profile => (
          <div key={profile._id}> 
            <ProfileItem key={'profile ' + profile._id} profile={profile} />
          </div>
        ))} 
          }
          
         else {
          profileBoolean = true;
          profileItems = profiles
          .filter(function(profile) {
            if (profile.user && profile.user.name !== 'removed') {
              return profile
            } else {
              return null
            }
          })
          .map(profile => (
          <div key={profile._id}> 
            <ProfileItem key={'profile ' + profile._id} profile={profile} />
          </div>
        ))} 
        ;
      } else {
        profileItems = <h4>No Profiles Found...</h4>;
      }
    }

    if (this.state.canLoadMore) {
      if (this.state.searchState && profiles) {
        moreButton = (
          <Waypoint
            onEnter={this.loadMoreSearch}
          />
        )
      } else if (profiles){
        moreButton = (
          <Waypoint
          onEnter={this.loadMore}
        />
          // <button
          //   className="btn tertiary-color-bg text-light mr-0"
          //   onClick={()=>this.loadMore()}
          // >
          //   show more
          // </button>
        )
      }
    }  else {
      moreButton = (<div className="empty moreButton"></div>)
    }

    if (profileBoolean) {
      let breakpointColumnsObj = {
          default: 6,
          992: 4,
          768: 3,
          576: 2
        };
      chefContainer = (
        // <div className="chefContainer">{profileItems}</div>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid mt-2"
          columnClassName="my-masonry-grid_column">
          {profileItems}
        </Masonry> 
      )
    } else {
      chefContainer = (
        <div className="">{profileItems}</div>
      )
    }
    if (this.state.searchState) {
      searchCancel = (
        <div className="">
          <button
            className="btn tertiary-color-bg text-light m-1 ml-3"
            type="submit"
            onClick={this.resetSearch}
          >
            <i className="fas fa-times fa-lg"/>
          </button>
        </div>
      );
    } else {
      searchCancel = null;
    }
    friendChefsButton = (
      <Link to="/friendchefs" className="btn btn-sm secondary-color-bg text-light mx-0">
        <i className="fas fa-user-friends fa-lg"></i>
      </Link>
    );
    searchBar = (
      <div className="form mr-2">
        <div className="input-group m-1">
          <form className="row" onSubmit={this.onSearchSubmit}>
            <div className="">
              <input
                type="text"
                className="form-control"
                placeholder="find someone"
                aria-label="search query"
                aria-describedby="basic-addon2"
                value={this.state.searchQuery}
                onChange={this.handleQueryInputChange()}
              />
            </div>
            <div className="">
              <div className="input-group-append">
                <button
                  className="btn tertiary-color-bg text-light"
                  type="submit"
                  onSubmit={this.onSearchSubmit}
                >
                  {/* <img
                    src="https://potluq-recipe-images.s3-us-west-2.amazonaws.com/images/search_2.png"
                    alt="search"
                    style={{ width: 20, height: 20 }}
                  /> */}
                  <i className="fas fa-search fa-lg" />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
    let welcomeText = (
      <p className="lead text-center m-0">
              thank these folks for bringing stuff to the potluq!
            </p>
    )
    if (this.props.auth.isAuthenticated) {
      chefsView =(
        <div className="row">
          <div className="col-md-12">
            {welcomeText}
            <div className="row justify-content-between align-items-center m-0">
              {friendChefsButton}
              <div className="row mx-0">
                {searchBar}
                {searchCancel}
              </div>
            </div>
            {chefContainer}
            {loadingSpinner}
          </div>
        </div>
      )
    } else {
      chefsView =(
        <div className="row">
          <div className="col-md-12">
          {welcomeText}
            <div className=" d-flex justify-content-center ">
              <div className="row mr-2">
                {searchBar}
                {searchCancel}
              </div>
            </div>
            {chefContainer}
            {loadingSpinner}
            {/* <div className="row justify-content-around align-content-center">
              {profileItems}
            </div> */}
          </div>
        </div>
      )
    }
    return (
      <div className="container">
        {helmet}
        {chefsView}
        {moreButton}
      </div>
    );
  }
}

Profiles.propTypes = {
  getProfiles: PropTypes.func.isRequired,
  searchProfiles: PropTypes.func.isRequired,
  clearProfiles: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getProfiles, searchProfiles, clearProfiles }
)(Profiles);
