import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import {Helmet} from 'react-helmet';
import { loginFB, authFB, loginG } from '../../actions/authActions';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

//testing for if Invalid Hook error 321 happens again
// require('react-dom');
// window.React2 = require('react');
// console.log(window.React1 === window.React2);

class Landing extends Component {
  constructor() {
    super();
    this.state = {
      thirdPartyCookieError: false,
      gooogleUser: {},
      errors: {}
    };
    this.googleResponse = this.googleResponse.bind(this);
    this.googleFailure = this.googleFailure.bind(this);
    this.attachSignin = this.attachSignin.bind(this);
  }

  componentDidMount() {
    console.log('window href is ' + window.location.href)
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/allRecipes');
    }
    window.gapi.load('auth2', () => {
      this.auth2 = window.gapi.auth2.init({
        client_id: '641523247436-b7r52kdjjor49teclaiq2bm44aavucib.apps.googleusercontent.com',
        scope: 'profile email'
      })
      this.attachSignin(document.getElementById('customBtn'));
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push('/allRecipes');
    }
  }

  facebookResponse = response =>  {
    // console.log(response);
    // console.log('sending FB data to server for login');
    let token = response.accessToken;
    this.props.authFB(token);
    this.props.loginFB(response);
  };

  attachSignin(element) {
    this.auth2.attachClickHandler(element, {}, this.googleResponse, this.googleFailure);
  }
  googleResponse = response => {
    console.log('success googleResponse')
    console.log(response)
    let token = response.uc.access_token;
    console.log('token is ' + token)
    this.props.authFB(token);
    this.props.loginG(response);
  }
  googleFailure = response => {
    console.log(`google authentication failed`)
    console.log(response.error)
    console.log(response.details)
    if (response.error === 'idpiframe_initialization_failed') {
      console.log('cookies are disabled in this browser')
      this.setState({thirdPartyCookieError: true})
    }
  }
  
  render() {
    let helmet = (
      <Helmet>
        <title>potluq</title>
      </Helmet>
  )
  let logo =(
    <img
      className="logo"
      src='https://potluq-profile-images.s3-us-west-2.amazonaws.com/potluq_logo_white_lg.png'
      alt="potluq"
      style={{maxWidth: '690px'}}
      // style={{ height: '25px', width: '75px', marginRight: '1px' }}
    />
  )
  let subTitle = (
    <h3 className="text-light "> your community recipe book</h3>
  )
  let facebookLoginButton = (
    <FacebookLogin
      appId={'626800344762698'}
      autoLoad={false}
      fields="name,email,picture.width(720).height(720).redirect(0)"
      callback={this.facebookResponse} 
      redirectUri={window.location.href}
      onclick={this.componentClicked}
      render={renderProps => (
        <button onClick={renderProps.onClick} className="btn btn-lg facebook-blue-bg text-light mt-2">sign in with <i className="fab fa-facebook-square" /></button>
    )}/>
  )
  let googleLoginButton = (
    <div>
      <div id="customBtn" className="btn btn-lg google-green-bg text-light mt-2">
        <span className="icon"></span>
        <span className="buttonText">sign in with <i className="fab fa-google" /></span>
      </div>
    </div>
  )
  let buttons = (
    <div className="">
    <Link to="/register" className="btn btn-lg secondary-color-bg mr-2 text-light">
      join
    </Link>
    <Link to="/login" className="btn btn-lg tertiary-color-bg text-light">
      sign in
    </Link>
    <br/>
    {facebookLoginButton}
    <br/>
    {googleLoginButton}
    <br/>
    <br/>
    {/* <br/>
    <br/> */}
    <Link to="/allRecipes" className="text-light">
      or just see some recipes
    </Link>
    </div>
                
  )
  let landingBody
  var randomnumber=Math.floor(Math.random()*4)
  
  let landingBackground
  if (randomnumber === 0) {
    landingBackground = (
      <div className="landing land0">
        <div className="landingBlur"></div>
      </div>
    )
  } else if (randomnumber === 1) {
    landingBackground = (
      <div className="landing land1">
        <div className="landingBlur"></div>
      </div>
    )
  } else if (randomnumber === 2) {
    landingBackground = (
      <div className="landing land2">
        <div className="landingBlur"></div>
      </div>
    )
  } else if (randomnumber === 3) {
    landingBackground = (
      <div className="landing land3">
        <div className="landingBlur"></div>
      </div>
    )
  }
  landingBody = (
    <div className="m-0">
        {helmet}
        {landingBackground}
        <div className="landingOverlay"></div>
        <div className="dark-overlay landing-inner text-light">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="mx-auto col-md-12 text-center">
                {logo}
                {subTitle}
                <br />
                <br />
                {buttons}
              </div>
            </div>
          </div>
        </div>
      </div>
  )
    return (
      <div>
        {landingBody}
      </div>
      
    );
  }
}

Landing.propTypes = {
  loginFB: PropTypes.func.isRequired,
  loginG: PropTypes.func.isRequired,
  authFB: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps, 
  {loginFB, authFB, loginG}
)(Landing);
