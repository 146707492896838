import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Spinner from '../common/Spinner';
import {
  retrieveBookRecipes,
  searchRecipes,
  searchPublicRecipes,
  clearRecipes
} from '../../actions/recipeActions';
import {getBook, removeRecipeFromBook, deleteBook} from '../../actions/bookActions';
import { getCurrentProfile, editProfile } from '../../actions/profileActions';
import RecipeItem from '../recipes/RecipeItem';
import {Helmet} from 'react-helmet';
import ReactPixel from 'react-facebook-pixel';

class RecipeFeed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      helmetTitle: 'recipe'
    }
    this.removeRecipe = this.removeRecipe.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
  }

//Book is either already set, or needs to be set upon navigating to this component
//call getBook(book_id) to retrieve list of recipes
//loop through recipes

  componentDidMount() {
    this.props.clearRecipes();
    this.props.getBook(this.props.match.params.id);
    // this.props.retrieveBookRecipes(this.props.match.params.id);
    ReactPixel.trackCustom('ViewBook')
  }

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (nextProps.book) {
      if (nextProps.book.book) {
        this.setState({
          helmetTitle: nextProps.book.book.title
        })
        if (this.props.book.book) {
          if (nextProps.book.book !== this.props.book.book) {
            if (nextProps.book.book.recipes) {
              this.props.retrieveBookRecipes(this.props.match.params.id);
            }  
          }
        }
      }
    }
  }

  handleQueryInputChange = idx => e => {
    this.setState({ searchQuery: e.target.value });
  };

  removeRecipe = recipeId => {
    this.props.clearRecipes();
    this.props.removeRecipeFromBook(this.props.match.params.id,recipeId);
  }

  onDeleteClick() {
    console.log('called onDeleteClick');
    const profileData = this.props.profile.myProfile;
    profileData.bookNames = this.props.profile.myProfile.bookNames.filter(item => item !== this.props.book.book.title);
    profileData.bookIds = this.props.profile.myProfile.bookIds.filter(item => item !== this.props.match.params.id);
    console.log(profileData);
    this.props.editProfile(profileData);
    this.props.deleteBook(this.props.match.params.id);
    this.props.clearRecipes();
    let editProfilePromise = new Promise((resolve, reject) => {
      this.props.editProfile(profileData)
        .then(res => 
          {console.log('doing first push to mybooks');
          this.props.history.push('/mybooks');
          resolve();}
          )
        .catch(err=> {
          console.log('something awful happened');
          this.setState({ errors: err });
    });})
    editProfilePromise.then(() => {
      console.log('doing second push to mybooks');
          this.props.history.push('/mybooks');
    }

    )
    // editProfilePromise.then(()=> {
    //   console.log('doing second push to mybooks')
    //       this.props.history.push('/mybooks');
    // }).catch(err=> {
    //   console.log('something awful happened');
    //   this.setState({ errors: err });
    // })
  }

  render() {
    let { recipes, loading } = this.props.recipe;
    let recipeItems;
    let recipeFeedDisplay;
    let loadingSpinner;
    let deleteBookButton;
    let title;
    let description;

    let helmet = (
      <Helmet>
        <title>{this.state.helmetTitle}</title>
      </Helmet>
  )

    if (recipes === null || loading) {
      loadingSpinner = <Spinner />;
    } else {
      loadingSpinner = null;
    }
    if (recipes === null || loading) {
      recipeItems = null;
    } else {
      if (recipes.length > 0) {
        recipeItems = recipes.map(recipe => (
          <div className="recipeCardContainer" key={recipe._id}>
            <RecipeItem key={recipe._id} recipe={recipe} />
            <div className="">
              <i 
              className="far fa-times-circle fa-2x cursorPointer hoverRed removeButton hoverSpin" 
              onClick={() => this.removeRecipe(recipe._id)}
              />
              </div>
          </div>
          
        ));
      } else {
        recipeItems = 
        (
          <div>
              <h4 className="text-center">no recipes in this book!</h4>
              <p className="text-center">when you find a recipe you like, hit the bookmark icon <i className="fas fa-bookmark" /> and select {this.props.book.book.title} to find it here later</p>
            </div>
        )
      }
    }

    if (this.props.book.book) {
      title = (<h1 className="display-10 text-center text-break">{this.props.book.book.title}</h1>)
      description = (<p className="lead text-center">{this.props.book.book.description}</p>)
      if (this.props.book.book.user === this.props.auth.user.id && this.props.book.book.title !== 'favorites') {
        deleteBookButton = (
          <div>
            <button
              className="btn btn-sm btn-danger m-2 mt-1"
              onClick={() => this.onDeleteClick()}
            >
              delete book{' '}
              <img
                src="https://potluq-recipe-images.s3-us-west-2.amazonaws.com/images/trash.png"
                alt="delete"
                style={{ width: 24, height: 24 }}
              />
            </button>
        </div>
        ) 
      } else {
        deleteBookButton = (<div></div>)
      }
    } else {
      title = (<h1 className="display-10 text-center text-break">recipes</h1>)
      description = (<p className="lead text-center">stuff saved in this book</p>)
      deleteBookButton = (<div></div>)
    }
    let recipeItemContainer
    if (recipes === null || loading) {
      recipeItemContainer = null;
    } else {
      if (recipes.length > 0) {
        recipeItemContainer = (
          <div className="chefContainer col-12">{recipeItems}</div>
        )
      } else {
        recipeItemContainer = (
          <div className="col-12">{recipeItems}</div>
        )
      }
    }
    recipeFeedDisplay = (
      <div className="">
        {title}
        <h5 className="lead text-center">{description}</h5>
        <div>{loadingSpinner}</div>
        {recipeItemContainer}
        <br />
        <br />
        <br />
        <br />
        <div className="d-flex justify-content-center">{deleteBookButton}</div>
        
      </div>
    );

    return (
      <div className="recipeView">
        {helmet}
        <div className="container">
          <div className="row">
            <div className="col-md-12">{recipeFeedDisplay}</div>
          </div>
        </div>
      </div>
    );
  }
}

RecipeFeed.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  retrieveBookRecipes: PropTypes.func.isRequired,
  editProfile: PropTypes.func.isRequired,
  removeRecipeFromBook: PropTypes.func.isRequired,
  getBook: PropTypes.func.isRequired,
  deleteBook: PropTypes.func.isRequired,
  auth: PropTypes.object,
  searchRecipes: PropTypes.func.isRequired,
  searchPublicRecipes: PropTypes.func.isRequired,
  clearRecipes: PropTypes.func.isRequired,
  recipe: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  recipe: state.recipe,
  book: state.book,
  searchState: state.searchState,
  auth: state.auth,
  profile: state.profile
});

export default connect(
  mapStateToProps,
  { getBook, deleteBook, editProfile, retrieveBookRecipes, removeRecipeFromBook, searchRecipes, getCurrentProfile, searchPublicRecipes, clearRecipes }
)(RecipeFeed);
