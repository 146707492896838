import axios from 'axios';

import {
  BOOK_LOADING,
  // ADD_BOOK,
  GET_BOOK,
  // SAVE_RECIPE_TO_BOOK,
  // REMOVE_RECIPE_FROM_BOOK,
  DELETE_BOOK,
  CLEAR_CURRENT_BOOK,
  GET_ERRORS,
  // GET_RECIPES,
  CLEAR_ERRORS
} from './types';

//create book
export const createBook = (bookDetails) => dispatch => {
  return new Promise((resolve, reject) => {
  axios
    .post('/api/books/details', bookDetails)
    //API should return newly created book - call getBook with new bookId
    .then(res => {
        dispatch(getBook(res.data._id));
        // console.log(res);
        resolve(res);
    }
      )
    .catch(err =>
      {
        if (err.response) {
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data
          })
        }
      }
    );
  });
};

//edit book - sssshhh it's almost the same as createBook but has its own name and action
export const editBookDetails = (bookDetails, history) => dispatch => {
  axios
    .post('/api/books/details', bookDetails)
    //API should return updatetd book - call getBook with original request id
    .then(res => dispatch(getBook(bookDetails.book_id)))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

//delete book
export const deleteBook = book_id => dispatch => {
axios
  .delete(`/api/books/${book_id}`)
  .then(res =>
    dispatch({
      type: DELETE_BOOK, 
      payload: res.data
    })
  )
  .catch(err =>
    dispatch({
      type: DELETE_BOOK,
      payload: null
    })
  );
};

//get book by id
export const getBook = book_id => dispatch => {
  dispatch(setBookLoading());
  axios
    .get(`/api/books/${book_id}`)
    .then(res =>
      dispatch({
        type: GET_BOOK,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_BOOK,
        payload: null
      })
    );
};

//get books by user
export const getUserBooks = user_id => dispatch => {
  dispatch(setBookLoading());
  axios
    .get(`/api/books/userbooks/${user_id}`)
    .then(res =>
      dispatch({
        type: GET_BOOK,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_BOOK,
        payload: null
      })
    );
};

// export const createBook = (bookDetails) => dispatch => {
//   return new Promise((resolve, reject) => {
//   axios
//     .post('/api/books/details', bookDetails)
//     //API should return newly created book - call getBook with new bookId
//     .then(res => {
//         dispatch(getBook(res.data._id));
//         // console.log(res);
//         resolve(res);
//     }
//       )
//     .catch(err =>
//       dispatch({
//         type: GET_ERRORS,
//         payload: err.response.data
//       })
//     );
//   });
// };

//add recipe to book
export const addRecipeToBook = (book_id, recipe_id) => dispatch => {
  return new Promise((resolve, reject) => {
  axios
    .post(`/api/books/add/${book_id}/${recipe_id}`)
    .then(res => {
      dispatch(getBook(book_id));
      resolve(res);
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
  })
};

//remove recipe from book
export const removeRecipeFromBook = (book_id, recipe_id) => dispatch => {
  axios
    .post(`/api/books/remove/${book_id}/${recipe_id}`)
    .then(res => dispatch(getBook(book_id)))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.data
      })
    );
};

//book loading
export const setBookLoading = () => {
  return {
    type: BOOK_LOADING
  };
};

//clear book
export const clearCurrentBook = () => {
  return {
    type: CLEAR_CURRENT_BOOK
  };
};

//clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};
