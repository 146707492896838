import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Helmet} from 'react-helmet';

class About extends Component {
//if I need to define any actions, or call anything onload, do it here

  render() {

    //if I decide to make anything on the About page dynamic - put the stuff here

    return (
        <div className="m-4 pl-2 pr-2">
          <Helmet>
            <title>what is this</title>
          </Helmet>
            <h5>{`What's a potluq?`}</h5>
            <p>{`Potluq is a free web app, or web-based application, for your recipes. Much like an offline `}<a href="https://en.wikipedia.org/wiki/Potluck">potluck</a>{`, it is what you make of it! 
            Bring your recipes, share them with your friends and family, and enjoy theirs if and when they add them to the potluq.`}</p>
            <h5>{`Aren't there enough recipes online already?`}</h5>
            <p>{`There are lots of places to find a recipe online, but most of them are not fun to use. Some are overrun with sponsored recipes 
            and plugs for a sponsored brand of cheese, others make you wade through their life story before you learn the recipe calls for paprika and isn't 
            what you were looking for.`}</p>
            <p>{`Hopefully potluq will escape that fate, and give you a place to organize your own recipes, and find ones tested by those you trust.`}</p>
            <h5>{`But I'm not a professional chef, my recipes don't belong online`}</h5>
            <p>{`Sure they do. Most families have favorite recipes. Even if it's just a recipe you've had good luck with, potluq is a place for you to 
            file it away so you don't have to keep a cupboard full of index cards or half-legible paper recipes anymore.`}</p>
            <p>{`If you don't have a single recipe, that's fine too. Find recipes on potluq that look good, save them to try later!`}</p>
            <h5>{`What's the catch?`}</h5>
            <p>{`There are some ads, hopefully you'll agree it's not too many. Gotta keep the lights on somehow.  
            There are no plans to charge for access to potluq, so your recipes shouldn't disappear or be lost to the ether.`}</p>
            <h5>{`Do I have to make an account?`}</h5>
            <p>{`Not to look at recipes! You're welcome to peruse the recipes others have shared, but you'll need an account in order to upload your own or 
            save the ones you find in recipebooks.`}</p>
            <h5>{`Can I share these recipes on other websites?`}</h5>
            <p>{`Totally. Buttons are included to share recipes on Facebook, Twitter, via email, whathaveyou.`}
            <i className="far fa-envelope ml-2 fa-sm inline"/>
            <i className="far fa-copy ml-2 fa-sm inline"/>
            <i className="fab fa-facebook fa-sm ml-2 inline"/>
            <i className="fab fa-pinterest fa-sm ml-2 inline"/>
            <i className="fab fa-reddit fa-sm ml-2 inline"/>
            <i className="fab fa-twitter ml-2 fa-sm inline"/>
            <i className="fab fa-whatsapp ml-2 fa-sm inline"/>
            </p>
            <h5>{`What about the recipe for my secret sauce? I can't have people finding that.`}</h5>
            <p>{`By default, recipes on potluq are publicly available. When you create a recipe, you have the option of making that recipe private. 
            If you do so, only you will have access to it. 
            It won't show up in searches, but you also won't be able to share it with friends or family.`}</p>
            <h5>{`I don't have pictures to upload for any of my recipes...`}</h5>
            <p>{`That's okay! A placeholder will show up in its place, and you can always add a photo later. 
            There are lots of resources online to help take great food pictures -`}<br/>
            <a href="https://www.shopify.com.sg/blog/food-photography-tips">like this one</a><br/>
            <a href="https://iphonephotographyschool.com/food/">or this one</a><br/>
            <a href="https://cookieandkate.com/food-photography-tips-for-food-bloggers/">or this one</a><br/>
            <a href="https://www.google.com/">or this one</a>
            </p>
            <h5>{`The recipe form doesn't give me a place to tell my life story.`}</h5>
            <p>{`Sorry! There's a time and a place for stories, and this is the place for recipes. Feel free to include a link to your blog or 
            social media presence in the relevant fields of your chef profile.`}</p>
            <h5>{`I saw a recipe that offends me.`}</h5>
            <p>{`As long as you're logged into your account, you can flag any inappropriate content on potluq with the alert icon. `}<i className="fas fa-exclamation-triangle m-2 fa-sm inline"/></p>
            <h5>{`Okay sign me up!`}</h5>
            <p><Link className="" to="/register">
              Create a free account here!
            </Link></p>
            <h5>{`That's not what I meant, I just want to see the sweet, sweet recipes.`}</h5>
            <p><Link className="" to="/allRecipes">
              Oh. Well then go here!
            </Link></p>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <h5>{`Wait. I need more help.`}</h5>
            <p>{`If you still need help, want to file a bug report, or request new functionality, `}<Link className="" to="/help">
              please come this way.
            </Link></p>
            <br />
            <br />
            {/* <h5>{``}</h5>
            <p>{``}</p> */}
        </div>
    );
  }
}

About.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {  }
)(About);
