import axios from 'axios';

import {
  // ADD_RECIPE,
  GET_RECIPE,
  RECIPE_LOADING,
  CLEAR_CURRENT_RECIPE,
  CLEAR_RECIPES,
  DELETE_RECIPE,
  GET_ERRORS,
  GET_RECIPES,
  SEARCH_RECIPES,
  CLEAR_ERRORS,
  ADD_RECIPEFEED_AD
} from './types';

//get current recipe
export const getCurrentRecipe = () => dispatch => {
  dispatch(setRecipeLoading());
  axios
    .get('/api/recipes')
    .then(res =>
      dispatch({
        type: GET_RECIPE,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_RECIPE,
        payload: {}
      })
    );
};

//get all recipes
export const getRecipes = seen => dispatch => {
  dispatch(setRecipeLoading());
  axios
    .post('/api/recipes/all', seen)
    .then(res =>
      dispatch({
        type: GET_RECIPES,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_RECIPES,
        payload: null
      })
    );
};

//get all recipes if you're logged in
export const authGetRecipes = seen => dispatch => {
  dispatch(setRecipeLoading());
  axios
    .post('/api/recipes/all', seen)
    .then(res =>
      dispatch({
        type: GET_RECIPES,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_RECIPES,
        payload: null
      })
    );
};

//get all recipes, prioritizing those of people you follow
export const getPriRecipes = followingAndSeen => dispatch => {
  dispatch(setRecipeLoading());
  // const followStrings = {'following': following};
  // console.log('action sending data ' + following)
  axios
    .post('/api/recipes/following', followingAndSeen)
    .then(res =>
      dispatch({
        type: GET_RECIPES,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_RECIPES,
        payload: null
      })
    );
};

//get recipe by id
export const getRecipe = id => dispatch => {
  dispatch(setRecipeLoading());
  axios
    .get(`/api/recipes/${id}`)
    .then(res =>
      dispatch({
        type: GET_RECIPE,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_RECIPE,
        payload: null
      })
    );
};

//search recipes
export const searchRecipes = (query, seen) => dispatch => {
  dispatch(setRecipeLoading());
  axios
    .post(`/api/recipes/search/${query}`, seen)
    .then(res =>
      dispatch({
        type: SEARCH_RECIPES,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: SEARCH_RECIPES,
        payload: null
      })
    );
};

//public search recipes
export const searchPublicRecipes = (query, seen) => dispatch => {
  dispatch(setRecipeLoading());
  axios
    .post(`/api/recipes/publicSearch/${query}`, seen)
    .then(res =>
      dispatch({
        type: SEARCH_RECIPES,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: SEARCH_RECIPES,
        payload: null
      })
    );
};

//private search friends recipes
//need to modify so that 'following' and 'seen' are both parts of the body of the API call
export const searchFriendsRecipes = (query, followingAndSeen) => dispatch => {
  dispatch(setRecipeLoading());
  axios
    .post(`/api/recipes/allFriendSearch/${query}`, followingAndSeen)
    .then(res =>
      dispatch({
        type: SEARCH_RECIPES,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: SEARCH_RECIPES,
        payload: null
      })
    );
};

//search user public recipes
export const searchUserPublicRecipes = (userid, query, seen) => dispatch => {
  dispatch(setRecipeLoading());
  axios
    .post(`/api/recipes/unauth/search/${userid}/${query}`, seen)
    .then(res =>
      dispatch({
        type: SEARCH_RECIPES,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: SEARCH_RECIPES,
        payload: null
      })
    );
};

//search user recipes
export const searchUserRecipes = (userid, query, seen) => dispatch => {
  dispatch(setRecipeLoading());
  axios
    .post(`/api/recipes/search/${userid}/${query}`, seen)
    .then(res =>
      dispatch({
        type: SEARCH_RECIPES,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: SEARCH_RECIPES,
        payload: null
      })
    );
};

//retrieve a specific user's recipes
export const userRecipes = (id, seen) => dispatch => {
  dispatch(setRecipeLoading());
  axios
    .post(`/api/recipes/user/${id}`, seen)
    .then(res =>
      dispatch({
        type: GET_RECIPES,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_RECIPES,
        payload: null
      })
    );
};

//retrieve a specific user's public recipes
export const userPublicRecipes = (id, seen ) => dispatch => {
  dispatch(setRecipeLoading());
  axios
    .post(`/api/recipes/unauth/user/${id}`, seen)
    .then(res =>
      dispatch({
        type: GET_RECIPES,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_RECIPES,
        payload: null
      })
    );
};

//retrieve a specific book's recipes
export const retrieveBookRecipes = book_id => dispatch => {
  dispatch(setRecipeLoading());
  axios
    .get(`/api/recipes/book/${book_id}`)
    .then(res =>
      dispatch({
        type: GET_RECIPES,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_RECIPES,
        payload: null
      })
    );
};

//create recipe
export const createRecipe = (recipeData, history) => dispatch => {
  // console.log('creating recipe with handle ' + recipeData.handle)
  axios
    .post('/api/recipes', recipeData)
    .then(res => history.push('/allRecipes'))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

//delete recipe
export const deleteRecipe = id => dispatch => {
  if (window.confirm('Are you sure? This can NOT be undone!')) {
    axios
    .delete(`/api/recipes/${id}`)
    .then(res =>
      dispatch({
        type: DELETE_RECIPE,
        payload: id
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
  }
};

//report recipe
export const reportRecipe = (id, history) => dispatch => {
  return new Promise((resolve, reject) => {
  axios
    .post(`/api/recipes/report/${id}`)
    .then(res => {
      dispatch(getRecipes());
      history.push('/allRecipes');
      resolve(res);
    })
    .catch(err =>
      {dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
      history.push('/allRecipes');
    resolve(err)
    });
  })
};

//add like
export const addLike = id => dispatch => {
  axios
    .post(`/api/recipes/like/${id}`)
    .then(res =>
      {
      dispatch({
        type: GET_RECIPE,
        payload: res.data
      })}
    )
    .catch(err =>
      {
        dispatch({
        type: GET_ERRORS,
        payload: err.data
        });
    });
};

//remove like
export const removeLike = id => dispatch => {
  axios
    .post(`/api/recipes/unlike/${id}`)
    .then(res =>
      {
      dispatch({
        type: GET_RECIPE,
        payload: res.data
      })}
    )
    .catch(err =>
      {
        dispatch({
        type: GET_ERRORS,
        payload: err.data
        });
    });
};

//add comment
export const addComment = (recipeId, commentData) => dispatch => {
  dispatch(clearErrors());
  // console.log(`adding comment to ${recipeId}`)
  // console.log(`comment should be ${commentData.text}`)
  axios
    .post(`/api/recipes/comment/${recipeId}`, commentData)
    .then(res =>
      dispatch({
        type: GET_RECIPE,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

//delete comment
export const deleteComment = (recipeId, commentId) => dispatch => {
  if (window.confirm('delete this comment?')) {
  axios
    .delete(`/api/recipes/comment/${recipeId}/${commentId}`)
    .then(res =>
      dispatch({
        type: GET_RECIPE,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
  }
};

//like or unlike comment
export const likeUnlikeComment = (recipeId, commentId) => dispatch => {
  console.log('sending request for recipe ' + recipeId + ' comment ' + commentId)
  axios
    .post(`/api/recipes/commentlike/${recipeId}/${commentId}`)
    .then(res =>
      dispatch({
        type: GET_RECIPE,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

//add recipeitem ad
export const addRecipefeedAd = () => {
  return {
    type: ADD_RECIPEFEED_AD
  };
};

//recipe loading
export const setRecipeLoading = () => {
  return {
    type: RECIPE_LOADING
  };
};

//clear recipe
export const clearCurrentRecipe = () => {
  return {
    type: CLEAR_CURRENT_RECIPE
  };
};
//clear recipes
export const clearRecipes = () => {
  return {
    type: CLEAR_RECIPES
  };
};

//clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};
