import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import isEmpty from '../../validation/is-empty';

class ProfileItem extends Component {


  render() {
    const { profile } = this.props;
    const {viewProfile} = this.props;
    let profileImage
    let profileLink
    let chefCard
    if (profile.user) {
      profileImage = (
        <Link
                to={`/chef/${profile.handle}`}
                
              >
                <img
                src={profile.user.avatar}
                alt="Profile avatar"
                className="rounded-circle"
              />
              </Link>
        
      )
    }
    if (profile.user) {
      profileLink = (
        <Link
                to={`/chef/${profile.handle}`}
                className="card-title mt-2 text-break"
              >
                {profile.user.name}
              </Link>
      )
    }
    if (profile.user) {
      if (profile.user.name === "removed") {
        chefCard = null
      } else {
        chefCard = (
        <div className="card bg-light chefCard">
          <div className="card-body bg-light p-0">
            <div className="column m-1 justify-content-center">
              <div className="row-2 justify-content-center">
                {profileImage}
              </div>
              <div className="text-capitalize text-center mt-2">
                {profileLink}
                {
                  (profile.followers.filter(id => id === viewProfile).length === 0) ? null : 
                  <i className="fas fa-user-friends fa-2x primary-color follow" />
                }
              </div>
              {/* <div className="col-md-4 d-none d-md-block" /> */}
            </div>
          </div>
        </div>
        )
            }
    }
    if (profile.user) {
      if (profile.user.name !== 'removed') {
        return (
          <div>
            {chefCard}
          </div>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
    
  }
}

ProfileItem.propTypes = {
  profile: PropTypes.object.isRequired,
  viewProfile: PropTypes.string
};

export default ProfileItem;
