import axios from 'axios';

//upload recipe image to s3
export const uploadRecipeImage = (user, file, target) => dispatch => {
  // console.log('s3Action: file name is:', file.name);
  // console.log('s3Action: file type is:', file.type);
  const token = axios.defaults.headers.common['Authorization'];
  delete axios.defaults.headers.common['Authorization'];

  axios
    .get('/api/s3/recipeImage', {
      params: {
        userName: user.name,
        userID: user.id,
        filename: file.name,
        filetype: file.type
      }
    })
    .then(function(result) {
      // console.log('immediate result from get request:', result);
      var signedUrl = result.data;
      // console.log('userName is: ', user.name);
      // console.log('userID is: ', user.id);

      // console.log('signedURL is:', signedUrl);
      // console.log('content name:', file.name);
      // console.log('content type:', file.type);
      var options = {
        headers: {
          'Content-Type': file.type
        }
      };
      return axios.put(signedUrl, file, options);
    })
    .then(function(result) {
      var userID = user.id;
      var fileName = file.name;
      var imageURL = `https://s3-us-west-2.amazonaws.com/potluq-recipe-images/images/${userID}.${fileName}`;
      // console.log('successful upload');
      // console.log(result);
      axios.defaults.headers.common['Authorization'] = token;
      target.setState({ picture: imageURL });
    })
    .catch(function(err) {
      console.log(err);
      axios.defaults.headers.common['Authorization'] = token;
    });

  //
};

//upload profile image to s3
export const uploadProfileImage = (user, file, target) => dispatch => {
  return new Promise((resolve, reject) => {
  const token = axios.defaults.headers.common['Authorization'];
  delete axios.defaults.headers.common['Authorization'];
  let propUserId;
  let randomDigits =Math.floor(Math.random()*1000)
  if (user.id) {
    // console.log('found user.id to be ' + user.id);
    propUserId = user.id
  } else {
    // console.log('no user.id so using _id  ' + user._id);
    propUserId = user._id
  }
  axios
    .get('/api/s3/profileImage', {
      params: {
        userName: user.name,
        userID: propUserId,
        filename: file.name,
        filetype: file.type,
        random: randomDigits
      }
    })
    .then(function(result) {
      // console.log('immediate result from get request:', result);
      var signedUrl = result.data;
      // console.log('userName is: ', user.name);
      // console.log('userID is: ', user.id);

      // console.log('signedURL is:', signedUrl);
      // console.log('content name:', file.name);
      // console.log('content type:', file.type);
      var options = {
        headers: {
          'Content-Type': file.type
        }
      };
      return axios.put(signedUrl, file, options);
    })
    .then(function(result) {
      var userID = propUserId;
      var fileName = file.name;
      var imageURL = `https://s3-us-west-2.amazonaws.com/potluq-profile-images/images/${userID}.${fileName}.${randomDigits}`;
      // console.log('successful upload');
      // console.log(result);
      axios.defaults.headers.common['Authorization'] = token;
      target.setState({ avatar: imageURL });
      resolve(imageURL);
    })
    .catch(function(err) {
      console.log(err);
      axios.defaults.headers.common['Authorization'] = token;
      resolve(err)
    });
  })
  //
};
