import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { requestPasswordReset } from '../../actions/authActions';
import TextFieldGroup from '../common/TextFieldGroup';
import {Helmet} from 'react-helmet';

class ReqPwdReset extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      newPassword: '',
      newPassword2: '',
      resetToken: '',
      loading: false,
      success: false,
      errors: {}
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
        this.setState({
            resetToken: this.props.match.params.id
        })
    }
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/allRecipes');
    }
    
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push('/allRecipes');
    }

    if (nextProps.errors) {
      this.setState({ 
        errors: nextProps.errors,
        loading: false
      });
    }
    console.log(nextProps)
    //need to add check for success response - set loading true and success true
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const userData = {
      email: this.state.email
    };
    this.setState({loading: true})
    this.props.requestPasswordReset(userData).then(res=>{
      this.setState({loading: false});
      console.log('completed request ' + res.status);
      if(res.status === 200) {
        this.setState({success: true})
      }
    });
    
  };

  render() {
    const { errors } = this.state;
    let helmet = (
      <Helmet>
        <title>reset your potluq password</title>
      </Helmet>
  )
    let loadingSpinner;
    let successText;
    let submitButton;

    if (this.state.loading) {
      loadingSpinner = (
        <div className="text-center">
          <i className="fas fa-sync fa-spin"></i>
        </div>
      )
    } else {
      loadingSpinner = (
        <div></div>
      )
    }
    if (this.state.success) {
      successText= (
        <div className="text-center">
          <div>email sent with pasword reset link
            </div>
          
        </div>
      )
    } else {
      submitButton = (
        <input type="submit" className="btn tertiary-color-bg btn-block mt-4" />
      )
    }
    
    return (
      <div className="login">
        {helmet}
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <h1 className="display-4 text-center">sign in</h1>
              <p className="lead text-center">need to reset your password?</p>
              <form onSubmit={this.onSubmit}>
                <TextFieldGroup
                  placeholder="Email Address"
                  name="email"
                  type="email"
                  value={this.state.email}
                  onChange={this.onChange}
                  error={errors.email}
                />
                
                {submitButton}
              </form>
              {loadingSpinner}
              {successText}
              <a href={`/login`} className="btn tertiary-color-bg btn-block mt-4" >return to sign in</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ReqPwdReset.propTypes = {
  requestPasswordReset: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { requestPasswordReset }
)(ReqPwdReset);
