import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Spinner from '../common/Spinner';
import {
  userRecipes,
  searchUserRecipes,
  searchUserPublicRecipes,
  userPublicRecipes,
  clearRecipes
} from '../../actions/recipeActions';
import { 
  getCurrentProfile,
  getProfileByHandle,
  follow,
  unfollow} from '../../actions/profileActions';
import RecipeItem from './RecipeItem';
import { Helmet } from 'react-helmet';
// import { isNull } from 'util';
import Masonry from 'react-masonry-css';
import { Waypoint } from 'react-waypoint';
import ReactPixel from 'react-facebook-pixel';

class UserRecipes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchState: false,
      searchQuery: '',
      profile: null,
      recipe: null,
      helmetTitle: `one chef's recipes`,
      seen: [],
      canLoadMore: false,
      firstCall: true,
      needProfile: true
    };
  }

  componentDidMount() {
    this.props.clearRecipes();
    if (this.props.match.params.handle) {
      // console.log(this.props.match.params.handle)
      this.props.getProfileByHandle(this.props.match.params.handle);
      ReactPixel.trackCustom('ViewChef', {chef: `${this.props.match.params.handle}`})
    } else if (this.props.auth.isAuthenticated) {
      //check if their authprofile is loaded -if yes, call get profile by handle
      if (this.props.profile.myProfile && this.props.profile.myProfile.handle) {
        console.log('authprofile was loaded, getting profile by handle')
        this.props.getProfileByHandle(this.props.profile.myProfile.handle)
      } else {
        //else get their profile
        console.log('getting profile because')
        console.log(this.props.profile)
        this.props.getCurrentProfile();
      }
    } 
  }

//   UNSAFE_componentWillReceiveProps(nextProps) {
//     // console.log(nextProps)
    
// }
  handleQueryInputChange = idx => e => {
    this.setState({ searchQuery: e.target.value });
  };

  onSearchSubmit = e => {
    e.preventDefault();
    this.props.clearRecipes();
    if (this.state.searchQuery) {
      if (this.props.auth.isAuthenticated) {
        this.props.searchUserRecipes(
          this.props.profile.profile.user._id,
          this.state.searchQuery
        );
        this.setState({ searchState: true });
      } else {
        this.props.searchUserPublicRecipes(
          this.props.profile.profile.user._id,
          this.state.searchQuery
        );
        this.setState({ searchState: true });
      }
    }
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (this.props.profile.profile !== nextProps.profile.profile) {
      if (nextProps.profile.profile && this.props.auth.isAuthenticated && this.state.firstCall) {
        this.props.clearRecipes();
        // console.log('getting first recipes')
        this.props.userRecipes(nextProps.profile.profile.user._id);
        this.setState({
          helmetTitle: `${nextProps.profile.profile.user.name} recipes`, firstCall: false
        })
      } else if (nextProps.profile.profile && this.state.firstCall) {
        this.props.clearRecipes();
        this.props.userPublicRecipes(nextProps.profile.profile.user._id);
        this.setState({
          helmetTitle: `${nextProps.profile.profile.user.name} recipes`, firstCall: false
        })
      }
    }
    // if (nextProps.recipes) {
    //   this.setState({ recipe: nextProps.recipes });
    // }
    if (nextProps.recipe) {
      if (nextProps.recipe.recipes) {
        // console.log(nextProps.recipe.recipes)
        if (nextProps.recipe.recipes.length === this.state.seen.length) {
          //
        } else {
          setTimeout(()=>this.allowLoadMore(),1000)
          
        }
      } 
    }
    if (nextProps.match) {
      if (nextProps.match.params) {
        if (nextProps.match.params.handle) {
          if (nextProps.match.params.handle !== this.props.match.params.handle) {
            this.props.getProfileByHandle(nextProps.match.params.handle)
          }
        }
      }
    }
    if (!this.props.match.params.handle && this.state.needProfile) {
      if (nextProps.profile && nextProps.profile.myProfile && nextProps.profile.myProfile.handle) {
        this.props.getProfileByHandle(nextProps.profile.myProfile.handle)
        this.setState(
          {helmetTitle: `${nextProps.profile.myProfile.user.name} recipes`, needProfile: false}
        )
      }
    }
  };
  allowLoadMore = () => {
    this.setState({canLoadMore: true})
  }
  resetSearch = () => {
    this.setState({ searchQuery: '', searchState: false });
    this.props.clearRecipes();
    if (this.props.auth.isAuthenticated) {
      this.props.userRecipes(this.props.profile.profile.user._id);
    } else {
      this.props.userPublicRecipes(this.props.profile.profile.user._id);
    }
  };

  loadMore = () => {
    if (this.state.canLoadMore && !this.state.searchState) {
      this.setState({canLoadMore: false})
      let currentRecipes = {}
      if (this.props.recipe.recipes) {
        currentRecipes.seen = this.props.recipe.recipes.map(recipe=>
          {return recipe._id}
        )
        this.setState({seen: currentRecipes.seen})
      }
      // console.log(currentRecipes.seen)
      // console.log('loading more')
      if (this.props.auth.isAuthenticated) {
        this.props.userRecipes(this.props.profile.profile.user._id, currentRecipes);
      } else {
        this.props.userPublicRecipes(this.props.profile.profile.user._id, currentRecipes);
      }
    } 
  }

  loadMoreSearch = () => {
    if (this.state.canLoadMore && this.state.searchState) {
      let currentRecipes = {}
      if (this.props.recipe.recipes) {
        currentRecipes.seen = this.props.recipe.recipes.map(recipe=>
          {return recipe._id}
        )
        this.setState({seen: currentRecipes.seen})
      }
      this.setState({canLoadMore: false})
      if (this.props.auth.isAuthenticated) {
        this.props.searchUserRecipes(
          this.props.profile.profile.user._id,
          this.state.searchQuery,
          currentRecipes
        );
      } else {
        this.props.searchUserPublicRecipes(
          this.props.profile.profile.user._id,
          this.state.searchQuery,
          currentRecipes
        );
      }
    } 
  }

  render() {
    let { recipes, loading } = this.props.recipe;
    let profile = this.props.profile.profile;
    let myProfile = this.props.profile.myProfile;
    let recipeItems;
    let recipeFeedDisplay;
    let loadingSpinner;
    let searchBar;
    let searchCancel;
    let welcomeText;
    let noRecipesText;
    let followButton;
    let editProfileButton;
    let addRecipeButton;
    let recipeMetric;
    let followMetric;
    let followerMetric;
    let bookMetric;
    let recipeItemsContainer;
    let socialLinks;
    let moreButton;
    // let addRecipeButton;
    let helmet = (
      <Helmet>
        <title>{this.state.helmetTitle}</title>
        <meta property="og:title" content={this.state.helmetTitle} />
      </Helmet>
    )

    if (this.props.profile.profile && this.state.searchState) {
      noRecipesText = (
        <div className="">
          <h6 className="text-center">
            {this.props.profile.profile.user.name} hasn't brought anything like that!
          </h6>
        </div>
      );
    } else if (!this.props.match.params.handle) {
      noRecipesText = (
        <div className="">
          <h5 className="text-center">nothing here yet!</h5>
          <h4 className="text-center"><Link
              to="/create-recipe"
              // className="btn btn-light mb-3 float-right"
              className="align-middle m-1 text-center"
            > 
              <i className="fas fa-plus-circle  secondary-color"/> add your first recipe <i className="fas fa-plus-circle secondary-color"/>
            </Link></h4>
          
        </div>
      );
    } else {
      if (this.props.profile.profile && this.props.profile.myProfile && this.props.profile.profile.handle === this.props.profile.myProfile.handle) {
        noRecipesText = (
          <div className="">
            <h5 className="text-center">nothing here yet!</h5>
            <h4 className="text-center"><Link
                to="/create-recipe"
                // className="btn btn-light mb-3 float-right"
                className="align-middle m-1 text-center"
              > 
                <i className="fas fa-plus-circle  secondary-color"/> add your first recipe <i className="fas fa-plus-circle secondary-color"/>
              </Link></h4>
            
          </div>
        );
      } else {
        noRecipesText = (
          <div className="">
            <h5 className="text-center">nothing here yet!</h5>
          </div>
        );
      }
    }

    if (recipes === null || loading) {
      loadingSpinner = <Spinner />;
    } else {
      loadingSpinner = null;
    }
    if (this.state.canLoadMore) {
      if (this.state.searchState && recipes) {
        moreButton = (
          <Waypoint
            onEnter={this.loadMoreSearch}
          />
        )
      } else if (recipes){
        moreButton = (
          <Waypoint
          onEnter={this.loadMore}
        />
          // <button
          //   className="btn tertiary-color-bg text-light mr-0"
          //   onClick={()=>this.loadMore()}
          // >
          //   show more
          // </button>
        )
      }
    }  else {
      moreButton = (<div className="empty moreButton"></div>)
    }
    let breakpointColumnsObj = {
      default: 6,
      992: 4,
      768: 3,
      576: 2
    };

    if (recipes === null ) {
      recipeItems = null;
    } else {
      
      if (recipes.length > 0) {
        recipeItems = recipes.map(recipe => (
          <RecipeItem key={recipe._id} recipe={recipe} />
        ));
        recipeItemsContainer = (
          // <div className="chefContainer col-12 mb-2">{recipeItems}</div>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid mt-2"
            columnClassName="my-masonry-grid_column">
            {recipeItems}
          </Masonry> 
        )
      } else {
        recipeItems = (
          
          <div className="card bg-light mb-2 chefCard">
            <div className=" bg-light m-0">
              <div className="column m-2 justify-content-center">
                <h6>{noRecipesText}</h6>
              </div>
            </div>
          </div>
        );
        recipeItemsContainer = (
          <div className="col-12 mb-2">{recipeItems}</div>
        )
      }
    }

    if (this.state.searchState) {
      searchCancel = (
        <div className="">
          <button
            className="btn tertiary-color-bg text-light m-1 ml-0"
            type="submit"
            onClick={this.resetSearch}
          >
            <i className="fas fa-times fa-lg"/>
          </button>
        </div>
      );
    } else {
      searchCancel = null;
    }
    searchBar = (
      <div className="form">
        <div className="input-group m-1">
          <form className="row" onSubmit={this.onSearchSubmit}>
            <div className="">
              <input
                type="text"
                className="form-control"
                placeholder="find something"
                aria-label="search query"
                aria-describedby="basic-addon2"
                value={this.state.searchQuery}
                onChange={this.handleQueryInputChange()}
              />
            </div>
            <div className="">
              <div className="input-group-append">
                <button
                  className="btn tertiary-color-bg text-light mr-0"
                  type="submit"
                  onSubmit={this.onSearchSubmit}
                >
                  {/* <img
                    src="https://potluq-recipe-images.s3-us-west-2.amazonaws.com/images/search_2.png"
                    alt="search"
                    style={{ width: 20, height: 20 }}
                  /> */}
                  <i className="fas fa-search fa-lg" />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
    if (profile) {
      if (recipes) {
        let recipeNumber = recipes.length
        // console.log('you have ' + recipeNumber + ' recipes')
        if (recipeNumber === 1) {
          recipeMetric = (
            <p>{recipeNumber} recipe</p>
            )
        } else {
          recipeMetric = (
            <p>{recipeNumber} recipes</p>
            )
        }
        
      }
      if (profile.bookIds) {
        let bookNumber = profile.bookIds.length
        // console.log('has ' + bookNumber + ' books')
        if (bookNumber === 1) {
          bookMetric = (
            <p>{bookNumber} book</p>
            )
        } else {
          bookMetric = (
            <p>{bookNumber} books</p>
            )
        }
        
      }
      if (profile.following) {
        let followNumber = profile.following.length
        // console.log('following ' + followNumber)
        followMetric = (
          <p>{followNumber} following</p>
          )
      }
      if (profile.followers) {
        let followerNumber = profile.followers.length
        // console.log('followed by ' + followerNumber)
        followerMetric = (
          <p>{followerNumber} followers</p>
          )
      }
      if (profile.social) {
        let homepageLink;
        if (profile.social.homepage) {
          homepageLink = (
            <a href={`${profile.social.homepage}`} className="text-dark"><i className="fas fa-2x fa-globe"/></a>
          )
        } 
        let youtubeLink;
        if (profile.social.youtube) {
          youtubeLink = (
            <a href={`${profile.social.youtube}`} className="text-dark"><i className="fab fa-2x fa-youtube"/></a>
          )
        }
        let twitterLink;
        if (profile.social.twitter) {
          twitterLink = (
            <a href={`${profile.social.twitter}`} className="text-dark"><i className="fab fa-2x fa-twitter-square"/></a>
          )
        }
        let facebookLink;
        if (profile.social.facebook) {
          facebookLink = (
            <a href={`${profile.social.facebook}`} className="text-dark"><i className="fab fa-2x fa-facebook"/></a>
          )
        }
        let instaLink;
        if (profile.social.instagram) {
          instaLink = (
            <a href={`${profile.social.instagram}`} className="text-dark"><i className="fab fa-2x fa-instagram"/></a>
          )
        }
        let pinLink;
        if (profile.social.pinterest) {
          pinLink = (
            <a href={`${profile.social.pinterest}`} className="text-dark"><i className="fab fa-2x fa-pinterest-square"/></a>
          )
        }
        socialLinks = (
          <div className="row justify-content-around align-items-center">
            {homepageLink}
            {facebookLink}
            {instaLink}
            {pinLink}
            {youtubeLink}
            {twitterLink}
          </div>
        )
      } else {
        socialLinks = null
      }
      welcomeText = (
        <div className="">
          <h3 className="display-8 text-center">
            {profile.user.name}
          </h3>
          <div className="row justify-content-center align-items-center">
                <div className="col-6 col-md-4 col-lg-3">
                  <img
                    className="rounded-circle"
                    src={profile.user.avatar}
                    alt=""
                  />
                </div>
                <div className="m-2 ml-4">
                  {recipeMetric}
                  {bookMetric}
                  {followMetric}
                  {followerMetric}
                </div>
          </div>
          
          
          <p className="lead text-center">
            {profile.bio}
          </p>
          {socialLinks}
        </div>
      );
      
    }
    if (this.props.auth.isAuthenticated) {
      if (profile != null && myProfile !=null) {
        if (profile.user && myProfile.user) {
          if (profile.user._id === myProfile.user._id) {
            followButton = null
            editProfileButton = (
              <Link
                    to="/editprofileandaccount"
                    // className="btn btn-light mb-3 float-right"
                    className="align-middle m-1"
                  >
                    <i className="fas fa-user-edit secondary-color fa-lg align-middle"></i>
                  </Link>
            )
            addRecipeButton = (
              <Link
                    to="/create-recipe"
                    className="align-middle m-1"
                  >
                    <i className="fas fa-plus-circle fa-2x secondary-color"/>
                  </Link>
            )
            
          
          } else {
              if(myProfile.following) {
                let profileCheck = profile.user._id
                if (this.props.profile.myProfile.following.filter(id => id === profileCheck).length === 0 ) {
                  followButton = (
                    <button 
                    className="btn secondary-color-bg text-light m-1 ml-3"
                    onClick={() => {this.props.follow(profile.user._id);}
                    }
                    >follow</button>
                  )
                } else {
                  followButton = (
                    <button 
                    className="btn tertiary-color-bg text-light m-1 ml-3"
                    onClick={() => {
                      // console.log(myProfile._id + ' unfollowing ' + profile._id)
                      this.props.unfollow(profile.user._id);
                    }
                    }
                    >unfollow</button>
                  )
                }
              
            } else {
              followButton = (<div className=""></div>)
            }
          }
      }
      }
        
    } 
    // else {
    //   welcomeText = (
    //     <div className="">
    //       {/* <h1 className="display-4 text-center">recipes</h1> */}
    //       <p className="lead text-center">recipes loading</p>
    //     </div>
    //   );
    // }
    
    recipeFeedDisplay = (
      <div className="">
        <div>{welcomeText}</div>
        <div className="">
          <div className="row d-flex align-items-center justify-content-around">
            {editProfileButton}
            <div className=" row m-1 mx-2">
              {searchBar}
              {searchCancel}
            </div>
            {followButton}
            {addRecipeButton}
          </div>
        </div>
        {recipeItemsContainer}
        {loadingSpinner}
        {moreButton}
      </div>
    );

    return (
      <div className="recipeView">
        {helmet}
        <div className="container">
          <div className="row">
            <div className="col-md-12 mb-4">{recipeFeedDisplay}</div>
            
          </div>
        </div>
      </div>
    );
  }
}

UserRecipes.propTypes = {
  userRecipes: PropTypes.func.isRequired,
  userPublicRecipes: PropTypes.func.isRequired,
  searchUserRecipes: PropTypes.func.isRequired,
  searchUserPublicRecipes: PropTypes.func.isRequired,
  follow: PropTypes.func.isRequired,
  unfollow: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  getProfileByHandle: PropTypes.func.isRequired,
  clearRecipes: PropTypes.func.isRequired,
  recipe: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object
};

const mapStateToProps = state => ({
  recipe: state.recipe,
  searchState: state.searchState,
  auth: state.auth,
  profile: state.profile
});

export default connect(
  mapStateToProps,
  {
    getCurrentProfile,
    getProfileByHandle,
    userRecipes,
    searchUserRecipes,
    searchUserPublicRecipes,
    userPublicRecipes,
    clearRecipes,
    follow,
    unfollow
  }
)(UserRecipes);
